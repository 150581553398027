import { MatomoSettings } from "../matomo-settings";
import { MatomoInterface } from "./api.interface";


export class MatomoTagManager implements MatomoInterface {

	private settings: MatomoSettings;
	private currentUrl: string;

	/**
	 * Get reference to the _mtm variable on the document
	 */
	public get mtm(): Array<{}> {
		return ( window as any )._mtm = ( window as any )._mtm || [];
	}

	public setOptions( options: MatomoSettings ): void {
		this.settings = options;
	}

	public initialize(): void {

		this.currentUrl = document.location.href;

		this.mtm.push( {
			'customUrl': this.currentUrl,
			'customTitle': document.title,
			'matomoId': this.settings.siteId,
			'alphaRegion': this.settings.regionIdentifier,
			'nao': this.settings.naoIdentifier,
			'siteType': 'My_Alpha',
			'event': 'customPageview'
		} );

		this.mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
		this.loadDeps();
	}

	/**
	 * Create and attach script element for Matomo client js
	 */
	private loadDeps(): void {

		const d = document, g = d.createElement( "script" ), s = d.getElementsByTagName( "script" )[ 0 ];
		g.type = "text/javascript";
		g.async = true;
		g.src = this.settings.trackerJSHost;
		s.parentNode.insertBefore( g, s );
	}

	/**
	 * Track a page view
	 */
	public trackPageView( url: string, title: string = null ): void {
		this.mtm.push({'event': 'mtm.PageView'});

		// set the current url for next referrer
		this.currentUrl = document.location.href;

	}

	public trackEvent(event: string, eventAction: string, eventName: string, value: any = null, customDimension?: any): void {

		this.mtm.push( { event: event, customDimension: customDimension});
	}

	public trackForm( formElement: HTMLElement ): void {
	}

	public trackFormSubmit( formElement: HTMLElement ): void {
	}

	public trackFormConversion( formElement: HTMLElement ): void {
	}
}