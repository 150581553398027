import { Directive, Input, HostListener } from '@angular/core';
import { TrackingService } from './tracking-service';

@Directive( {
	selector: '[track-event]'
} )
export class TrackEventDirective {

	@Input( 'track-event' ) protected trackEvent;

	@HostListener( 'click' )
	protected onClick(): void {
		this.service.trigger( this.trackEvent );
	}

	constructor(
		private service: TrackingService
	) { }
}
