import { Injectable } from '@angular/core';
import { environment } from '@builder/environments/environment';
import { Locale } from '../lang/locale';

export const MY_ACCOUNT_SETTINGS_PROFILE_LINK = 'profile';
export const MY_ACCOUNT_SETTINGS_ACCOUNT_DETAILS_TAB_LINK = 'account_details';
export const MY_ACCOUNT_SETTINGS_NOTIFICATIONS_SETTINGS_TAB_LINK = 'notifications';
export const PROFILE_UI_DOMAIN = environment.PROFILE_URL;

@Injectable({
  providedIn: 'root'
})
export class ProfileUrlsService {

  static locale: any;
  private _redirectUrl: string;

  constructor(
    public locale: Locale
  ) {}

  private buildUrl(tab: string): string {
    return `${PROFILE_UI_DOMAIN}?profileRedirectUrl=${this._redirectUrl}&lang=${this.locale.code}&tab=${tab}`;
  }

  public setRedirectUrl(value: string) {
    this._redirectUrl = value
  };
  
  public get accountDetails(): string {
		return this.buildUrl(MY_ACCOUNT_SETTINGS_ACCOUNT_DETAILS_TAB_LINK)
	}

  public get notificationsSettings(): string {
		return this.buildUrl(MY_ACCOUNT_SETTINGS_NOTIFICATIONS_SETTINGS_TAB_LINK)
	}
}
