import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { VideoPlayerComponent } from '@builder/common/media/video/player/videoPlayer';
import { TrackingService, TrackingEventName } from '@builder/tracking';

import { Lesson, Playlist } from '@builder/training';
import { PlaylistService } from '@builder/training/playlists/playlist-service';
import { PlaylistNavBarComponent } from '@builder/training/playlists/nav-bar/nav-bar.component';
import { ScrollEvent, CanScrollToBottom } from '@builder/common/util/scrollToBottom';
import { UserSurvey } from '@alpha-global/alpha-surveys';


/**
 * Lesson Detail Component
 */
@Component( {
	selector: 'lesson-detail',
	templateUrl: 'lesson-detail.component.html',
	styleUrls: [ './lesson-detail.component.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush

} )

export class LessonDetailComponent implements OnInit, OnDestroy, CanScrollToBottom {

	// option playlist that the lesson belongs to
	public playlist: Playlist = null;

	// lesson
	public lesson: Lesson = null;

	// if a lesson has finished we use this to show the auto timeout to next lesson modal
	public videoIsFinished = false;

	// set to true when autoplaying through the videos
	public canAutoPlayVideo = false;

	// if the user has acknowledged completion
	public moduleComplete = false;

	// a potential user survey that this detail is in the context of
	public userSurvey: UserSurvey;

	@ViewChild( 'videoPlayer' ) videoPlayer: VideoPlayerComponent;

	@ViewChild( PlaylistNavBarComponent ) navBar: PlaylistNavBarComponent;

	@HostBinding( 'class.has-playlist' ) get hasPlaylist(): boolean {
		return this.playlist !== null;
	}

	/**
	 * When scroll to 90% on an article page ( with no playlist, trigger the tracking event )
	 */
	@ScrollEvent( { threshold: .90 }, [ '$event' ] )
	protected onScrollToBottom( event ) {
		if ( this.lesson.type === 'article' && !this.playlist ) {
			this.completeArticleLesson( this.lesson );
		}
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private playlistService: PlaylistService,
		private cd: ChangeDetectorRef,
		private trackingService: TrackingService,
		public $element: ElementRef
	) {

		if ( this.route.parent.snapshot.data.survey ) {
			this.userSurvey = this.route.parent.snapshot.data.survey;
		}

	}

	ngOnInit() {

		this.route.data.subscribe( params => {

			this.videoIsFinished = false;

			/**
			 * If there was a previous current lesson that was an article we'll mark it as complete
			 */
			if ( this.lesson && this.lesson.type === 'article' ) {
				this.completeArticleLesson( this.lesson );
			}

			/**
			 * Data is passed in as either
			 *   playlistAndLesson - in the case of a /playlist/abcd/lesson/efgh url
			 * or
			 *   lesson - for just a lesson url
			 */
			const data = params.playlistAndLesson || params;

			if ( data.lesson ) {
				this.lesson = data.lesson as Lesson;
			}

			if ( data.playlist ) {
				this.playlist = data.playlist as Playlist;

				if ( this.playlist.lessonsComplete === this.playlist.lessonCount - 1 && this.lesson.type === 'article' && this.navBar ) {
					this.navBar.isExpanded = false;
				}

			}

			this.cd.markForCheck();

		} );

	}

	// needed for cleanup in decorator
	ngOnDestroy(): void { }

	public goToNextLesson(): void {

		if ( this.playlist ) {
			this.router.navigate( [ '/training', this.playlist.slug, this.playlist.nextLesson.slug ] );
		}
	}

	public completeArticleLesson( lesson ): void {
		this.completeLesson( lesson );

		// trigger tracking event
		this.trackingService.trigger( TrackingEventName.ReadTrainingArticle, {
			lesson: this.lesson,
			... ( this.playlist && { playlist: this.playlist } )
		} );


	}
	/**
	 * Complete a Lesson
	 */
	public completeLesson( lesson ): void {

		if ( this.playlist && !lesson.complete ) {

			lesson.complete = true;
			lesson.completed_on = new Date();

			this.cd.markForCheck();
			this.playlistService.saveLessonProgress( lesson, this.playlist ).subscribe();
		}

		if ( this.playlist && this.playlist.complete && this.lesson.type === 'article' ) {
			this.moduleComplete = true;
		}
	}

	public onWatchVideo( player: VideoPlayerComponent ): void {

		// trigger tracking event
		this.trackingService.trigger( TrackingEventName.WatchedTrainingVideo, {
			lesson: this.lesson,
			... ( this.playlist && { playlist: this.playlist } )
		} );

		if ( this.playlist ) {
			this.completeLesson( this.lesson );
		}
	}

	public onVideoFinished( event ): void {

		this.videoIsFinished = true;

		if ( this.playlist && this.playlist.complete ) {
			this.navBar.isExpanded = false;
		}
	}

}
