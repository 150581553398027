import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterComponent } from './search-filter.component';
import { ReactiveFormsModule } from '@angular/forms';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { NgxPopperjsModule, NgxPopperjsPlacements } from 'ngx-popperjs';

@NgModule({
  declarations: [
    SearchFilterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BuilderIconModule,
    BuilderMaterialModule,
		NgxPopperjsModule.forRoot({placement: NgxPopperjsPlacements.TOP})
  ],
  exports: [
    SearchFilterComponent
  ]
})
export class SearchFilterModule { }
