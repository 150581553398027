import { Directive, Input, HostListener } from '@angular/core';

@Directive( {
	selector: '[language-link]'
} )
export class LanguageLinkDirective {

	@Input( 'language-link' ) languageCode: string;

	@HostListener( 'click', [ '$event' ] ) onClick( event: Event ): void {

		event.preventDefault();
		const url: URL = new URL( document.location.href );
		url.searchParams.delete( 'lang' );
		url.searchParams.append( 'lang', this.languageCode );
		document.location.href = url.href;

	}

}
