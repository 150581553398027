import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserCache } from '../services/cache.service';

const merge = ( t, s ) => { const o = Object, a = o.assign; for ( const k of o.keys( s ) ) s[ k ] instanceof o && a( s[ k ], merge( t[ k ] || {}, s[ k ] ) ); return a( t || {}, s ), t; };

type Constructor<T> = new ( ...args: any[] ) => T;

export const ObStore = <T extends Constructor<{}>>( Base: T ) => {
	return class extends Base { };
};

export class ObjectStore {

	constructor(
		protected cache: UserCache,
		protected group: string,
		protected itemClass
	) { }

	updateStore( id, data ) {
		// see if there's a cached Alpha object
		let item = this.cache.getObject( id, this.group );
		if ( !item ) {
			// new item
			item = new this.itemClass( data );
		} else {
			// update existing item
			item = merge( item, data );
		}

		// update/add cached item
		this.cache.addObject( item, this.group );

		// update/add cached response
		const rKey = this.group + 'Response';
		const fetchedData: any = this.cache.getObject( id, rKey ) || {};
		Object.assign( fetchedData, data );
		this.cache.addObject( fetchedData, rKey );

		return item;
	}

	getItemStore( id ) {
		return this.cache.getObject( id, this.group );
	}

	getItem( id, params, serviceRequest, idKey = 'id' ): Observable<any> {

		const rKey = this.group + 'Response';

		const cached = this.cache.getObject( id, this.group, idKey );
		let needsFields = false;


		const fetchedData: any = this.cache.getObject( id, rKey, idKey );

		// If there is cached response data and specific fields are being requested, check to see that we haven't fetched the data yet
		// if so, remove them from the request
		if ( fetchedData && params._fields ) {

			// properties that have been fetched
			let propKeys: Array<string> = [];
			for ( const prop in fetchedData ) {
				const v = fetchedData[ prop ];
				if ( v instanceof Array ) {
					propKeys.push( prop );
				} else if ( v instanceof Object ) {
					propKeys = propKeys.concat( [ prop, ...Object.keys( v ).map( k => prop + '.' + k ) ] );
				} else {
					propKeys.push( prop );
				}
			}

			// filter the requested properties, removing those that have been fetched
			const fields: Array<string> = params._fields.split( ',' ).filter( field => {

				return propKeys.indexOf( field ) === -1;
			} );

			// make sure if the required fields are different from what's available that we don't serve back a cached version
			// @todo::not sure why we need the second condition, comment out for now
			// as it won't work when we have a spefic alpha in cache, and only query survey_id of that alpha
			// if ( fields.length && fields.join( ',' ) !== params._fields ) {
			if ( fields.length ) {
				needsFields = true;
				if ( fields.indexOf( 'id' ) === -1 ) {
					fields.push( 'id' );
				}
			}

			// update the _fields param
			params._fields = fields.join( ',' );
		}

		// if cached object, return it
		if ( cached && !needsFields ) {
			return of( cached );
		}

		return serviceRequest( params ).pipe(
			map( ( data: any ) => {
				const item = this.updateStore( cached ? cached.id : id, data );
				return item;
			} ) );
	}
}
