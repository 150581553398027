import { NgModule } from '@angular/core';
import { HeapAnalyticsComponent } from './heap.component';

@NgModule( {
	declarations: [
		HeapAnalyticsComponent
	],
	exports: [
		HeapAnalyticsComponent
	]
} )
export class HeapModule { }
