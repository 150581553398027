import { APP_INITIALIZER, Inject, Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, Subject, tap } from "rxjs";

import { TokenValidated, TrackingService, UserLogin, UserLogout } from "@builder/tracking";

import { Feature, FeaturesResponse } from "./features";
import { FeaturesMiddleWare, FEATURES_MIDDLEWARE } from "./features-middleware";

// API Endpoint
const SETTINGS_ENDPOINT = '/wp-json/wp/v2/features-config';

/**
 * Service for loading features from myAlpha
 * If a FeaturesMiddleWare is provided the setting/testing of features is off-loaded to that class
 */
@Injectable( {
	providedIn: 'root'
} )
export class FeaturesService {

	private features: Map<string, Feature> = new Map();

	constructor(
		private http: HttpClient,
		private tracking: TrackingService,
		@Optional() @Inject( FEATURES_MIDDLEWARE ) public middleware: FeaturesMiddleWare
	) {
		this.initEvents();
	}

	/**
	 * Tap into App events to possibly update the user attributes
	 */
	private initEvents(): void {

		this.tracking.events.subscribe( event => {

			/**
			 * Login or Token Validated ( site ready )
			 */
			if ( event instanceof TokenValidated || event instanceof UserLogin ) {

				this.updateUserAttributes( {
					loggedIn: true,
					id: event.data.user.id,
					country: event.data.user.country,
					role: event.data.user.user_roles[ 0 ]
				} );

			} else if ( event instanceof UserLogout ) {

				this.updateUserAttributes( { loggedIn: false } );

			}

		} );
	}

	/**
	 * Load the Settings
	 */
	public load(): Observable<any> {

		return this.http.get<FeaturesResponse>( SETTINGS_ENDPOINT ).pipe(
			tap( result => this.onLoadFeatures( result ) )
		);

	}

	private onLoadFeatures( result: FeaturesResponse ) {
		if ( this.middleware ) {
			this.middleware.setFeatures( result );
			return;
		}

		Object.values( result ).forEach( ( feature: Feature ) => this.setFeature( feature.key, feature ) );

	}

	/**
	 * Set a feature and create a Subject for it's observation
	 */
	private setFeature( key: string, feature: Feature ) {
		if ( this.middleware ) {
			this.middleware.setFeature( key, feature );
			return;
		}
		this.features.set( key, feature );
	}

	/**
	 * Check if a feature is enabled
	 */
	public isOn( key: string ): boolean {

		if ( this.middleware ) {
			return this.middleware.isOn( key );
		}

		const feature = this.features.get( key );
		return feature && feature.enabled;
	}

	/**
	 * Check if a feature is disabled
	 */
	public isOff( key: string ): boolean {

		if ( this.middleware ) {
			return this.middleware.isOff( key );
		}

		const feature = this.features.get( key );
		return !feature || !feature.enabled;
	}

	/**
	 * If middleware, you can subscribe to a feature enabled event
	 */
	public whenOn( key: string ): Subject<Feature> {
		if ( this.middleware ) {
			return this.middleware.whenOn( key );
		}
		console.warn( "whenOn is not supported for default feature implementation" );

		// return a subject that will never notify
		return new Subject();
	}

	/**
	 * If middleware, you can subscribe to a feature disabled event
	 */
	public whenOff( key: string ): Subject<Feature> {
		if ( this.middleware ) {
			return this.middleware.whenOff( key );
		}
		console.warn( "whenOff is not supported for default feature implementation" );

		// return a subject that will never notify
		return new Subject();
	}

	/**
	 * Get features
	 */
	public getFeatures() {
		if ( this.middleware ) {
			return this.middleware.getFeatures();
		}
		return this.features;
	}

	/**
	 * Update user attributes
	 */
	public updateUserAttributes( val: any ): void {
		if ( this.middleware ) {
			return this.middleware.updateUserAttributes( val );
		}
		console.warn( "User Attributes not supported for default feature implementation" );
	}
}

/**
 * Provider for import into app module
 */
export const featuresProvider = {
	provide: APP_INITIALIZER,
	deps: [ FeaturesService ],
	useFactory: ( service: FeaturesService ) => () => service.load(),
	multi: true
};