import { Component, Input, Output, EventEmitter, ViewChild, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, Directive, ViewContainerRef, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

import { SessionProgressTracker } from '@builder/tracking/session-progress-tracker';

import { IVideo } from '../videoService';
import { VimeoPlayerComponent } from '@builder/common/media/video/player/vimeoPlayer';
import { GDrivePlayerComponent } from '@builder/common/media/video/player/gdrivePlayer';
import { AppTheme } from '@builder/common/util/themeSupports';
import { IPlayer } from '@builder/common/media/video/player/player.base';
import { throttleTime } from 'rxjs/operators';



@Directive( {
	selector: '[video-ref]',
} )
export class VideoRefDirective {
	constructor( public viewContainerRef: ViewContainerRef ) { }
}


/**
 * A Video Player
 *
 *  Can load videos using different providers
 *
 */
@Component( {
	selector: 'video-player',
	template: `
    <div class='videoPlayerWrapper'>
		<div class='video-player' [class.playing]="playing">

            <div *ngIf="thumbSrc && !playing && src" class='thumbnail-container' [class.overlay]="preload" [class.clickable]="src?.length" (click)='onThumbClick($event)'>
				<ab-icon name="play" iconStyle='circular'
					class='bg-cover'
					[style.backgroundImage]="'url(' + thumbSrc +')'"
				></ab-icon>
			</div>

			<div *ngIf="thumbSrc && ! src" class='img'>
				<img [src]="thumbSrc" alt=""/>
			</div>

			<div *ngIf="showLoader && loading" class="videoLoader">
				<mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
			</div>
			<div class="video-container">
				<ng-template video-ref></ng-template>
			</div>
        </div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: [ 'videoPlayer.less' ]
} )

export class VideoPlayerComponent implements OnDestroy, OnInit {

	@ViewChild( VideoRefDirective, { static: true } ) videoHost: VideoRefDirective;

	@Input() autoPlay: boolean;
	@Input() watermark: boolean;
	@Input() disableFullScreen: boolean;
	@Input() showLoader: boolean;

	@HostBinding( 'class.watermark' ) get is_watermarked() {
		return this.watermark;
	}

	private _src: string;
	public get src(): string { return this._src; }
	@Input() public set src( val: string ) {

		if ( val === '' || val === null || this._src === val ) {
			return;
		}

		this._src = val;

		this.loadVideo();
		// reset triggeredCompletion
		this.triggeredCompletion = false;

	}

	// whether to load the video but not play it
	public preload: boolean;

	// is the video loading
	public loading: boolean;

	public get playerOptions(): any {
		const opts: any = {
			autoPlay: this.autoPlay,
			disableFullScreen: this.disableFullScreen
		};
		if ( this.player instanceof GDrivePlayerComponent ) {
			opts.apiKey = this.appTheme.value( 'google_drive_key' );
		}
		return opts;
	}


	private _video: IVideo;
	@Input() set video( val: IVideo ) {
		this._video = val;
		if ( val ) {
			this.src = val.url;
		}
	}
	get video(): IVideo {
		return this._video;

	}

	public get $element(): HTMLElement {
		return this.el.nativeElement;
	}

	private getComponent() {
		if ( /vimeo/.test( this.src ) ) {
			return VimeoPlayerComponent;
		}

		if ( /drive\.google/.test( this.src ) ) {
			return GDrivePlayerComponent;
		}
		return null;
	}

	/**
     *
     */
	private triggeredCompletion: boolean;
	/**
     *
     */
	private minPercentToCompletion = .95;


	/**
     * Set a video thumbnail
     */
	@Input() set thumbnail( image: { url: string } ) {
		this.thumbSrc = image.url;
		this.preload = true;
	}
	private _thumbSrc: string;
	public get thumbSrc(): string { return this._thumbSrc; }
	@Input() public set thumbSrc( val: string ) {
		this._thumbSrc = val;
		this.playing = false;
		this.preload = true;
	}

	@Output() videoPlay: EventEmitter<any> = new EventEmitter();
	@Output() videoPause: EventEmitter<any> = new EventEmitter();
	@Output() videoProgress: EventEmitter<any> = new EventEmitter();
	@Output() videoFinished: EventEmitter<any> = new EventEmitter();
	@Output() videoComplete: EventEmitter<any> = new EventEmitter();
	@Output() videoReady: EventEmitter<any> = new EventEmitter();
	@Output() videoError: EventEmitter<any> = new EventEmitter();


	private subscriptions: any = {};

	public playing: boolean;

	public player: IPlayer;

	private sizer: Subscription;

	constructor(
		private sessionTracker: SessionProgressTracker,
		private changeDetector: ChangeDetectorRef,
		private appTheme: AppTheme,
		private componentFactoryResolver: ComponentFactoryResolver,
		private el: ElementRef
	) {

		this.triggeredCompletion = false;

		this.sizer = fromEvent( window, 'resize' ).pipe(
			throttleTime( 100 )
		).subscribe( e => this.resize() );

		this.resize();
	}

	/**
     * Clean up message broker and listeners
     */
	ngOnDestroy() {

		this.sizer.unsubscribe();

		if ( this.player ) {
			this.player.destroy();
		}
		for ( const type in this.subscriptions ) {
			this.subscriptions[ type ].unsubscribe();
		}

	}

	/**
     * Initiate the API if neccessary
     */
	ngOnInit() {

		if ( this.src && !this.player ) {
			this.loadVideo();
		}

	}


	public play( currentTime: number = -1 ): void {
		if ( this.triggeredCompletion ) {

		}
		this.playing = true;
		if ( this.player ) {
			this.player.play( currentTime );
		}
	}
	public pause(): void {
		this.playing = false;
		if ( this.player ) {
			this.player.pause();
		}
	}

	private loadVideo(): void {

		const component: any = this.getComponent();

		if ( !component ) {
			console.error( 'cannot find a type of player for src: ' + this.src );
			return;
		}

		if ( !this.player || !( this.player instanceof component ) ) {

			if ( this.player ) {
				this.player.destroy();
				this.player = null;
			}

			const componentFactory = this.componentFactoryResolver.resolveComponentFactory( component );

			const viewContainerRef = this.videoHost.viewContainerRef;
			viewContainerRef.clear();

			const componentRef = viewContainerRef.createComponent( componentFactory );
			this.player = ( componentRef.instance as IPlayer );
			this.player.videoPause = this.videoPause;
			this.player.videoPlay = this.videoPlay;
			this.subscriptions.progress = this.player.videoProgress.subscribe( e => this.onProgressData( e ) );
			this.subscriptions.ready = this.player.videoReady.subscribe( e => this.onReady( e ) );
			this.subscriptions.finish = this.player.videoFinish.subscribe( e => this.onFinish( e ) );
			this.subscriptions.error = this.player.videoError.subscribe( e => this.onError( e ) );

		}

		this.player.options = this.playerOptions;

		this.loading = true;
		this.player.setSource( this.src );


	}

	private onProgressData( data ) {

		this.videoProgress.emit( data );

		// @todo
		// this.sessionTracker.setVideoProgress( this.player.videoId, data.progress.seconds );

		if ( data.progress.percent > this.minPercentToCompletion && !this.triggeredCompletion ) {
			this.watchedVideoToCompletion();
		}
	}

	private onReady( data ) {
		this.loading = false;
		this.player.resize();
		this.videoReady.emit( this );
		this.changeDetector.detectChanges();
	}

	private onError( error ) {
		this.loading = false;
		this.player.resize();
		this.videoError.emit( this );
		this.changeDetector.detectChanges();
	}

	private onFinish( event ): void {

		if ( !this.triggeredCompletion ) {
			this.watchedVideoToCompletion();
		}
		this.videoFinished.emit( this );


		this.changeDetector.detectChanges();

	}

	/**
     * Fit the iframe
     */
	private resize(): void {
		if ( this.player ) {
			this.player.resize();
		}
	}

	public onThumbClick( event: MouseEvent ): void {
		if ( this.src.length ) {
			this.playing = !this.playing;
			this.player.play();
		}
	}


	private watchedVideoToCompletion(): void {
		this.triggeredCompletion = true;
		this.videoComplete.emit( this );
	}

}
