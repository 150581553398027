import { NgModule } from '@angular/core';
import { IntercomComponent } from './intercom.component';

@NgModule( {
	declarations: [
		IntercomComponent
	],
	exports: [
		IntercomComponent
	]
} )
export class IntercomModule { }
