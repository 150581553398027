import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe( {
	name: 'hasItems'
} )
@Injectable()
export class HasItemsPipe implements PipeTransform {
	transform( items: any[], args: any = {} ): any {
		// filter items array, items which match and return true will be kept, false will be filtered out

		const currentItem: any = args.contains || null;
		const currentBucket: any = args.bucket || null;

		if ( currentItem && currentItem === currentBucket ) {
			return items;
		}

		if ( currentItem ) {
			return items.filter( item => item.name === currentItem.name );
		} else {
			return items.filter( item => item.items.length !== 0 );
		}
	}
}

@Pipe( {
	name: 'bucketsHaveItems'
} )
@Injectable()
export class BucketsHaveItemsPipe implements PipeTransform {
	transform( items: any[], args: any = {} ): any {
		// filter items array, items which match and return true will be kept, false will be filtered out

		const currentItem: any = args.contains || null;

		if ( currentItem ) {
			return items.filter( item => {
				let hasItems = false;
				if ( item.name === currentItem.name ) {
					hasItems = true;
				} else {
					if ( !item.buckets ) {
						return false;
					}
					item.buckets.forEach( bucket => {
						if ( bucket.name === currentItem.name ) {
							hasItems = true;
						}
					} );
				}
				return hasItems;
			} );
		}

		return items.filter( item => {
			let hasItems = false;
			if ( item.items && item.items.length ) {
				hasItems = true;
			} else if ( item.buckets ) {
				item.buckets.forEach( bucket => {
					if ( bucket.items.length ) {
						hasItems = true;
					}
				} );
			}

			return hasItems;
		} );
	}
}
