import { Injectable } from '@angular/core';
import { UserPreferences } from '@builder/users/preferences';
import { PopoverDOM, driver } from 'driver.js';
import { PRODUCT_TOUR_OVERVIEW_COMPLETED, WHATS_NEW_OPENED_PREVIOUSLY, PRODUCT_TOUR_OVERVIEW_OPENED } from '@builder/product-tour/product-tour.constants';
import { TrackingService, TrackingEventName } from '@builder/tracking';
import { FeaturesService } from '@builder/common/features/features.service';
import { Router } from '@angular/router';
import { FEATURE_USER_PROFILE } from '@builder/common/features/feature-flag';

@Injectable({
  providedIn: 'root'
})
export class ProductTourService {

  public productTourOverview;
  public whatsNewHighlight;
  
	public userProfileIsEnabled: boolean = false;

  public btn = document.getElementById("mobile-menu-more-btn");
  public oldMobileMenu = document.getElementById('old-mobile-menu');
  public mobileMenu = document.getElementById('mobile-menu');
  public mobileMenuPanel = document.getElementsByClassName('mobile-menu-panel');
  public total  = 0

  constructor(
    private userPreferences : UserPreferences,
    private trackingService: TrackingService,
    private features: FeaturesService,
		public router: Router,
  ) {
    this.userProfileIsEnabled = this.features.isOn( FEATURE_USER_PROFILE )
  }

  private stylePopover(popover: PopoverDOM, state) {
    // define a variable that points to mobileMenuPanel
    const mobileMenuPanel = document.getElementsByClassName('mobile-menu-panel')[0] as HTMLElement;
    // define a variable that points to accountPanel
    const accountPanel = document.getElementsByClassName('account-settings-title-mobile-active')[0] as HTMLElement;
    // define a variable that points to language-menu-mobile
    const languageMenu = document.getElementsByClassName('language-menu-mobile')[0] as HTMLElement;
    const step = state.activeStep.element
    const current = state.activeIndex +1;

    if(!step.includes( "[data-driver='overview9']")){
    const header = document.createElement('div');
    header.classList.add('mya-driver-js-header')

    const closeButton = document.createElement("button"); 
    closeButton.classList.add('mya-driver-js-exit-btn')

    const text = document.createElement("span"); 
    text.innerText = $localize`:productTour.button|Product Tour - Exit Button@@productTourExitButton:Exit Tour`;
    closeButton.prepend(text);

    const icon = document.createElement("span"); 
    icon.classList.add('icon-before', 'builder-icon', 'builder-icon-logout');
    icon.setAttribute("dir", "ltr");
    closeButton.prepend(icon);

    closeButton.addEventListener("click", () => {
      document.getElementsByClassName('page-header')[0].classList.remove('tour-header')
      this.productTourOverview.destroy();
      // clean up to unhide the panels
      if(this.oldMobileMenu || this.mobileMenu) {
        mobileMenuPanel.style.display = 'flex'
        accountPanel.style.display = 'block'
        languageMenu.style.display = 'block'
        if (this.mobileMenuPanel && this.mobileMenuPanel[0].classList.contains('open') && this.btn) {
          this.btn.click()
        }
      }
    });

    header.append(closeButton);
    popover.wrapper.prepend(header)

  }
  const stepsNum = document.createElement('div');
    stepsNum.style.display = 'flex';
    stepsNum.style.gap = '0.25rem';
    stepsNum.innerHTML = $localize`:productTour.text|Product Tour - Number of Steps@@productTourNumberOfSteps:${current}:item: <span>of</span> ${this.total}:total:`;
    popover.footer.append(stepsNum)



    for (const btn of Array.from(popover.footerButtons.children)) {
      btn.classList.add('mya-driver-js-btn', 'mdc-button', 'mat-mdc-button', 'mat-unthemed', 'mat-mdc-button-base');

      if (btn.classList.contains('driver-popover-prev-btn')) {
        btn.classList.add('mya-driver-secondary-btn');
      }
    }
  }

  private styleWhatsNewPopover(popover: PopoverDOM) {
    const takeTourButton = document.createElement("button"); 
    takeTourButton.innerText = $localize`:productTour.button|Product Tour - Take a Tour Buttonn@@takeTourButton:Take a tour`;
    takeTourButton.addEventListener("click", () => {
      this.whatsNewHighlight.destroy();
      this.router.navigate(['/preview-resources']).then(x => {
        this.initializeOverview();
      })
    });
    
    popover.footerButtons.append(takeTourButton);

    for (const btn of Array.from(popover.footerButtons.children)) {
      if (btn.classList.contains('driver-popover-next-btn')) {
        // btn.classList.add('mya-driver-secondary-btn');
        btn.classList.add('mya-driver-secondary-btn', 'mya-driver-js-btn', 'mdc-button', 'mat-mdc-button', 'mat-unthemed', 'mat-mdc-button-base');
      }
      if (!btn.classList.contains('driver-popover-take-tour-btn')) {
      }
    }
  }

  public initializeOverview() {
    this.trackingService.trigger(TrackingEventName.ProductTourStarted);
    // define a variable that points to mobileMenuPanel
    const mobileMenuPanel = document.getElementsByClassName('mobile-menu-panel')[0] as HTMLElement;
    // define a variable that points to accountPanel
    const accountPanel = document.getElementsByClassName('account-settings-title-mobile-active')[0] as HTMLElement;
    // define a variable that points to language-menu-mobile
    const languageMenu = document.getElementsByClassName('language-menu-mobile')[0] as HTMLElement;
    this.productTourOverview = driver({
      popoverClass: "mya-driver-js",
      showProgress: false,
      allowClose: false,
      disableActiveInteraction: true,
      overlayOpacity: 0.4,
      showButtons: ['next', 'previous', 'close'],
      nextBtnText: $localize`:productTour.button|Product Tour - Next Button@@productTourNextButton:Next`,
      prevBtnText: $localize`:productTour.button|Product Tour - Previous Button@@productTourPreviousButton:Previous`,
      doneBtnText: $localize`:productTour.button|Product Tour - Complete Button@@productTourCompleteButton:Complete`,
      steps: [
        {
          element: "[data-driver='overview1']",
          popover: {
            description: $localize`:productTour.text|Product Tour Overview - Step 1 Text@@ productTourOverviewStep1:Choose the Film series you want to use for your Alpha course. You are on this page now.`
          },
        },
        {
          element: "[data-driver='overview2'] .card-body",
          popover: {
            description: $localize`:productTour.text|Product Tour Overview - Step 2 Text@@ productTourOverviewStep2:Register your course using selected Film series. After registration you will have access to all Film series videos, guides and other materials.`,
            side: "bottom"
          },
        },
        { 
          element: "[data-driver='overview3']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 3 Text@@ productTourOverviewStep3:Learn how to run effective Alphas. Includes best practices, small group training and more.` 
          } 
        },
        { 
          element: "[data-driver='overview4']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 4 Text@@ productTourOverviewStep4:Search across all Alpha materials. To access Film/episodes videos, you'll need to register your Alpha first.`
          } 
        },
        { 
          element: "[data-driver='overview5']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 5 Text@@ productTourOverviewStep5:Access your profile settings to update your information, change password and more.`
          } 
        },
        { 
          element: "[data-driver='overview6']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 6 Text@@ productTourOverviewStep6:Select another language for viewing this site.`
          } 
        },
        { 
          element: "[data-driver='overview7']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 7 Text@@ productTourOverviewStep7:Access support articles or contact your National Alpha Office.`
          } 
        },
        { 
          element: "[data-driver='overview8']",
          popover: { 
            description: $localize`:productTour.text|Product Tour Overview - Step 8 Text@@ productTourOverviewStep8:Start registering a course by selecting Film series you want to use.`
          } 
        },{ 
          element: "[data-driver='overview9']",
          popover: { 
            title: $localize`:productTour.title|Product Tour Overview - Step 9 Title@@ productTourOverviewStep9Title:That's it!`,
            description: $localize`:productTour.text|Product Tour Overview - Step 9 Text@@ productTourOverviewStep9:You can revisit this tour at any time here in the What's New section.`
          }
        },
      ],

      onPopoverRender: (popover: PopoverDOM, { config, state }) => {
        this.total = config.steps.length
        this.stylePopover(popover, state);
        this.mobileMenu = document.getElementById('mobile-menu');
        const driverPopover = document.getElementById("driver-popover-content")
        if (this.mobileMenu && (state.activeIndex == 2 || state.activeIndex == 3 ||
          state.activeIndex == 6 || state.activeIndex == 7)) {

          if (driverPopover) {
            driverPopover.classList.add('driver-popover-mobile');
          }
        }
        else {
          if (driverPopover) {
            driverPopover.classList.remove('driver-popover-mobile');
          }
        }
      },
      
      onDestroyStarted: () => {
        if (!this.productTourOverview.hasNextStep() || confirm("Are you sure?")) {
          if(!this.productTourOverview.hasNextStep()){
            document.getElementsByClassName('page-header')[0].classList.remove('tour-header')
            this.userPreferences.set(PRODUCT_TOUR_OVERVIEW_COMPLETED,new Date()).subscribe();
          }
          this.trackingService.trigger(TrackingEventName.ProductTourCompleted);
          // on mobile destroy unhide panels
          if (this.mobileMenu) {
            mobileMenuPanel.style.display = 'flex'
            accountPanel.style.display = 'block'
            languageMenu.style.display = 'block'
          }
          this.productTourOverview.destroy();
        }
      },

      onHighlightStarted: (_, step) => {
        document.getElementsByClassName('page-header')[0].classList.add('tour-header')
        const overlay = document.getElementsByClassName('driver-overlay')[0] as HTMLElement;
        this.mobileMenu = document.getElementById('mobile-menu');
        if (this.mobileMenu && (String(step.element).includes("[data-driver='overview3']")
          || String(step.element).includes("[data-driver='overview4']") ||
          String(step.element).includes("[data-driver='overview7']")
          || String(step.element).includes("[data-driver='overview8']"))) {
          if (overlay) {
            overlay.classList.add('mobile-driver-overlay');
          }
        }
        else {
          if (overlay) {
            overlay.classList.remove('mobile-driver-overlay');  // Remove the z-index style
          }
        }
        // hiding panels on mobile
        if (this.mobileMenu && (String(step.element).includes("[data-driver='overview1']"))) {
          mobileMenuPanel.style.display = 'none'
        } else if (this.mobileMenu && mobileMenuPanel.style.display != 'flex') {
          mobileMenuPanel.style.display = 'flex'
        }
        if (this.mobileMenu) {
          accountPanel.style.display = 'none'
          languageMenu.style.display = 'none'
        }
      },

      onPrevClick: (_, step) => {
        this.oldMobileMenu = document.getElementById('old-mobile-menu');
        this.mobileMenu = document.getElementById('mobile-menu');
        // If the more button is rendered, toggle the mobile menu
        if(this.mobileMenu && (String(step.element).includes("[data-driver='overview5']") 
          || String(step.element).includes("[data-driver='overview7']") ||
          String(step.element).includes("[data-driver='overview3']")
          || String(step.element).includes("[data-driver='overview9']"))){
            if (this.btn) {
              this.btn.click();
              setTimeout(() => {
                this.productTourOverview.movePrevious();
              }, 900)
          }
        }
        else if(this.oldMobileMenu && (String(step.element).includes("[data-driver='overview4']"))){
            if (this.btn) {
              this.btn.click();
              setTimeout(() => {
                this.productTourOverview.movePrevious();
              }, 900)
          }
        }
        else {
          this.productTourOverview.movePrevious();
        }
      },

      onNextClick: (_, step) => {


        if ((String(step.element).includes("[data-driver='overview2']") || String(step.element).includes("[data-driver='overview4']") 
        || String(step.element).includes("[data-driver='overview6']") || String(step.element).includes("[data-driver='overview8']"))) {
          this.btn = document.getElementById("mobile-menu-more-btn");
          // If the more button is rendered, toggle the mobile menu
          if (this.btn && this.userProfileIsEnabled) {
            this.btn.click();
            setTimeout(() => {
              this.productTourOverview.moveNext();
            }, 900);
          }
          else {
            this.productTourOverview.moveNext();
          }
        }
        else if ((String(step.element).includes("[data-driver='overview3']"))) {
          // If the more button is rendered, toggle the mobile menu
          if (this.btn && !this.userProfileIsEnabled) {
            this.btn.click();
            setTimeout(() => {
              this.productTourOverview.moveNext();
            }, 900);
          }
          else {
            this.productTourOverview.moveNext();
          }
        }
        else {
          this.productTourOverview.moveNext();
        }
      }
    });

    this.productTourOverview.drive(0);
    this.userPreferences.set(PRODUCT_TOUR_OVERVIEW_OPENED, new Date()).subscribe();
  }

  public initializeWhatsNewHighlight() {
    this.whatsNewHighlight = driver({
      popoverClass: "mya-driver-js mya-driver-js-whats-new",
      allowClose: true,
      disableActiveInteraction: false,
      overlayOpacity: 0.1,
      showButtons: ['next'],
      doneBtnText: $localize`:productTour.button|Product Tour - Not Now Button@@productTourGotItButton:Not Now`,
      steps: [
        { 
          element: "[data-driver='overview9']",
          popover: { 
            title: $localize`:whatsNew.title|Whats new Tour Overview - Step 1 Title@@ whatsNewTourOverviewStep1Title:Would you like a quick tour of MyAlpha?`,
            description: $localize`:WhatsNew.text|Whats new Tour Overview - Step 1 Text@@ whatsNewTourOverviewStep1:
             No pressure, you can access the tour at any time from the What's New section highlighted above.
            `,
            side: "bottom",
            align: 'start'
          } 
        },
      ],
      onPopoverRender: (popover: PopoverDOM) => {
        this.styleWhatsNewPopover(popover);
      },
      onHighlightStarted: (_, step) => {
        document.getElementsByClassName('page-header')[0].classList.add('tour-header')
        const overlay = document.getElementsByClassName('driver-overlay')[0] as HTMLElement;
      },
      onDestroyStarted: () => {
        document.getElementsByClassName('page-header')[0].classList.remove('tour-header');
        this.whatsNewHighlight.destroy();
      }
    });

    this.whatsNewHighlight.drive(0);
    this.userPreferences.set(WHATS_NEW_OPENED_PREVIOUSLY, new Date()).subscribe();
  }
}
