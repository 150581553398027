import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';

import { VideoPlayerModule } from '../video/video-player.module';
import { MediaModalComponent, MediaModalDialog } from './media-modal.component';


@NgModule( {

	imports: [
		CommonModule,
		BuilderMaterialModule,
		VideoPlayerModule,
		BuilderIconModule
	],
	providers: [ MediaModalDialog ],
	declarations: [
		MediaModalComponent
	],
	exports: [
		MediaModalComponent
	],
	entryComponents: [
		MediaModalComponent
	]
} )
export class MediaModalModule { }
