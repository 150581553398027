<div class='video-playlister'>
	<div *ngIf="errorMessage"
		 class="ab-message error"
		 [innerHTML]="errorMessage"></div>
	<video-player #videoPlayer
				  [video]='currentVideo'
				  [watermark]='watermark'
				  [disableFullScreen]='disableFullScreen'
				  [autoPlay]='currentVideo?.playing'
				  (videoPlay)='videoPlaying($event)'
				  (videoPause)='videoPaused($event)'
				  (videoProgress)='videoProgress($event)'
				  (videoFinished)='videoFinished($event)'
				  (videoReady)="sizeListElement()"></video-player>
	<div class='video-playlist'
		 #listElement>
		<div *ngFor='let video of videos;index as index;'
			 class='video-playlist-item'
			 [class.active]="currentVideo === video">

			<span class='video-video-title'
				  (click)='loadVideo(video)'>{{video.title}}</span>

			<div class='video-duration-info'>
				<span class='video-current-time'
					  *ngIf='(video.playing || video.paused) && progressData'>{{progressData.seconds | playTime:{format:"timer"} }} / </span>
				<span class='video-duration-time'> {{video.duration | playTime:{format:"timer"} }}</span>
			</div>

		</div>
	</div>
</div>