import { NgModule } from '@angular/core';
import { MatomoComponent } from './matomo.component';

@NgModule( {
	declarations: [
		MatomoComponent
	],
	exports: [
		MatomoComponent
	]
} )
export class MatomoModule { }
