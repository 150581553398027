import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { UserRoleSelectComponent } from './user-role-select.component';

@NgModule( {
	imports: [
		CommonModule,
		FormsModule,
		AlphaLocaleModule
	],
	declarations: [
		UserRoleSelectComponent
	],
	exports: [
		UserRoleSelectComponent
	]
} )
export class UserRoleSelectModule {

}
