<div [id]="userProfileIsEnabled ? 'TitleHeader' : 'contentHeader'">

	<div class="content-wrapper">

		<h1 id="pageTitle"
			i18n="training.headers|Training Centre Title@@trainingCenterTitle">Learning Centre</h1>
			<div *ngIf="userProfileIsEnabled" class="divider"></div>
	</div>

</div>

<section *ngIf="supportsRecommendations"
		 class="content-wrapper">
	<recommendations-callout-card *ngFor="let survey of userSurveys | slice:0:1"
								  [survey]="survey"></recommendations-callout-card>
</section>

<section class="content-wrapper">


	<h6 class="section-title"
		i18n="dashboard.title|Learning Modules Title@@learningModulesTitle">Learning Modules</h6>

	<div id="learningModules"
		 class="column-box">

		<playlist-card *ngFor="let module of modules"
					   [playlist]="module"
					   [image-placement]="{'' : 'top', '(max-width:768px)' : 'left' }"></playlist-card>

	</div>

	<div *ngIf="totalModules > modules.length"
		 class="section-links">

		<a (click)="loadMoreModules()"
		   class="active"
		   i18n="learning.link|See All Learning Modules Link@@seeAllModulesLink">See All Learning Modules</a>

		<mat-progress-bar *ngIf="loadingMore"
						  mode="indeterminate"
						  color="accent"></mat-progress-bar>
	</div>
</section>

<section id="searchArea">

	<div class="content-wrapper">

		<search-form [navExtras]="{state:{section:'training'}}"></search-form>

		<popular-search-terms (selectTerm)="router.navigate(['/search'], {state: {section:'training'},queryParams:{q:$event.term}})">
			<p i18n="learning.title|Can't find what you're looking for?@@learningOptionsTitle">Can't find what you're looking for? Try one of the options below</p>
		</popular-search-terms>

	</div>

</section>

<section id="moreContent"
		 class="content-wrapper column-box">

	<div id="popularVideos">

		<h6 class="widget-title">
			<span i18n="common.title|Popular Videos@@commonTitlePopularVideos">Popular Videos</span>
		</h6>

		<lesson-card *ngFor="let item of popularVideos"
					 [routerLink]="['article', item.slug]"
					 [playlist]="item.playlists.length ? item.playlists[0] : null"
					 [lesson]="item"> </lesson-card>

	</div>

	<div id="popularArticles">

		<h6 class="widget-title">
			<span i18n="common.title|Popular Articles@@commonTitlePopularArticles">Popular Articles</span>
		</h6>

		<lesson-card *ngFor="let item of popularArticles"
					 [routerLink]="['article', item.slug]"
					 [playlist]="item.playlists.length ? item.playlists[0] : null"
					 [lesson]="item"> </lesson-card>
	</div>

	<div id="categoriesList">
		<div>
			<h6 class="widget-title"
				i18n="common.words|Categories@@commonWordsCategories">Categories</h6>

			<ab-icon class="text-icon-link"
					 [routerLink]="['category', category.slug]"
					 [name]="category.glyph"
					 *ngFor="let category of categories"><span>{{category.name}}</span></ab-icon>

		</div>
	</div>

</section>
