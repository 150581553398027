import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProductService } from '@plugin/common/services/product.service';
import { Product } from '@plugin/common/models/product';
import { Errors } from '@builder/common';
import { Locale } from '@builder/common/lang/locale';
import { FeaturesService } from '@builder/common/features/features.service';
import { FEATURE_SIMPLE_COURSE_CREATE } from '@builder/common/features/feature-flag';

export const SESSION_STORAGE_KEY_PRODUCT_SLUG = "courseCreationSeriesSlug";
/**
 * Alpha Questions resolver for before new alpha page
 */
@Injectable()
export class AlphaQuestions implements Resolve<Array<any>>{

	constructor(
		private productService: ProductService,
		private errors: Errors,
		private locale: Locale
	) { }

	/**
	 * Resolve
	 *
	 * @param route
	 * @param state
	 */
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> | Promise<Array<any>> | any {

	let productSlug = route.params?.product;
	
	if (productSlug) {
		sessionStorage.setItem(SESSION_STORAGE_KEY_PRODUCT_SLUG, productSlug);
	} else {
		productSlug = sessionStorage.getItem(SESSION_STORAGE_KEY_PRODUCT_SLUG); 
	}

    const params = productSlug ? 
      {
        slug: productSlug 
      } : {}

		return this.productService.getQuestions(params).pipe(

			map( ( questions: any ) => {

				/**
				 * Add all the site languages as additional options under the Course Language question
				 */
				const langQuestion = questions.find( q => q.slug === 'course_language' );

				if ( langQuestion ) {

					langQuestion.answers = langQuestion.answers.map( lang => {
						lang.title = lang.slug;
						return lang;
					} );

					langQuestion.additionalOptionsText = $localize`:language.button|See More Languages Button@@seeMoreLanguages:See more languages`;
					langQuestion.additionalOptions = this.locale.languages.map( lang => {
						return { name: lang.label, slug: lang.code, title: lang.code };
					} );

				}
				return questions;
			} ),
			catchError( error => {
				return this.errors.navigateToError( error.error || error );
			} )
		);

	}

}

/**
 * Resolver to load product detail
 */
@Injectable()
export class AlphaProductResolver implements Resolve<any>{

	constructor(
		private service: ProductService,
		private features: FeaturesService,
		private router: Router
	) {
	}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<Product> | Promise<Product> | any {

		if (sessionStorage.getItem("is_reloaded") && !this.features.isOn(FEATURE_SIMPLE_COURSE_CREATE)) {
			this.router.navigate(['/dashboard']);
			sessionStorage.removeItem("is_reloaded");
		}
		let productSlug = route.params?.product;
		
		if (productSlug) {
			sessionStorage.setItem(SESSION_STORAGE_KEY_PRODUCT_SLUG, productSlug);
		} else {
			productSlug = sessionStorage.getItem(SESSION_STORAGE_KEY_PRODUCT_SLUG); 
		}
		
		if (!productSlug ) {
			if(this.features.isOn(FEATURE_SIMPLE_COURSE_CREATE)){
				this.router.navigate( [ '/preview-resources' ] );
			}
			else{
				return
			}
		}
		return this.service.getProductByName( productSlug, {
			_fields:
				[
					'id',
					'blog_id',
					'title',
					'excerpt',
					'duration',
					'promotionalVideo',
					'image',
					'questions',
					'language',
					'availableLanguages',
					'siteLanguages'
				].join( ',' )
		} ).pipe(

			catchError( ( err, observable ) => {

				sessionStorage.removeItem( SESSION_STORAGE_KEY_PRODUCT_SLUG );
                    return this.router.navigate( [ '/preview-resources' ] );
			} )
		);

	}

}