import {
	Component,
	Injectable,
	Inject,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef
} from '@angular/core';

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

interface ModalData {
	media: { url: string, title: string };
	params: any;
}

@Injectable()
export class MediaModalDialog {

	private dialogRef: MatDialogRef<MediaModalComponent>;

	constructor( private dialog: MatDialog ) { }

	open( media, options: any = {} ): MediaModalComponent {
		const opts: any = Object.assign( {
			width: '80vw',
			maxWidth: '1024px',
			closeOnNavigation: true,
			disableClose: false,
			autoFocus: false,
			panelClass: 'ab-media-modal'
		}, options || {} );

		opts.data = {
			media,
			params: {
				autoPlay: options.autoplay
			}
		};

		this.dialogRef = this.dialog.open( MediaModalComponent, opts );

		return this.dialogRef.componentInstance;

	}
}

@Component( {
	selector: 'media-modal',
	template: `
	<div class="modal-header">
		<span class="modal-item-title" [innerHTML]="media?.title"></span>
		<ab-icon name="close" (click)="dialogRef.close()"></ab-icon>
	</div>

	<mat-progress-bar mode="indeterminate" color="accent" *ngIf="!ready"></mat-progress-bar>

	<ng-container *ngIf="isVideo">
		<video-player
			[autoPlay]="videoArgs.autoPlay"
			[src]="media.url"
			(videoFinished)="onVideoFinish( $event )"
			(videoComplete)="onVideoComplete( $event )"
			(videoReady)="onReady()"
			(videoProgress)="onVideoProgress($event)"
			(videoError)="onReady()"
		></video-player>
	</ng-container>

	<ng-container *ngIf="isPDF">
		<div #frameHolder></div>
	</ng-container>

	<div class="image-container" *ngIf="isImage">
		<img
			[src]="media.url"
			alt=""
			(load)="onReady()"
		/>
	</div>
	`,
	styleUrls: [ 'media-modal.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush

} )
export class MediaModalComponent {

	public isVideo: boolean;
	public isImage: boolean;
	public isPDF: boolean;

	public iframeSrc: any;

	public media: { url: string, title: string };

	public ready: boolean;

	public videoArgs: any;

	public onProgress: Subject<any> = new Subject();
	public onFinish: Subject<any> = new Subject();
	public onComplete: Subject<any> = new Subject();

	public destroyed$: Subject<void> = new Subject();

	@ViewChild('frameHolder') iframeHolder: ElementRef;


	/**
	 * Constructor
	 * @param sanitizer
	 */
	constructor(
		public dialogRef: MatDialogRef<MediaModalComponent>,
		private cd: ChangeDetectorRef,
		@Inject( MAT_DIALOG_DATA ) public data: ModalData
	) {

		this.media = data.media;
		this.videoArgs = data.params || {};

		this.parseMedia( data.media );

		dialogRef.afterOpened().subscribe( () => {
			/**
		     * Using an iframe in the template leads to a callstack error for some unknown reason
		     * workaround is to create the iframe ourselves
		    */
			if ( this.iframeHolder ) {
				const f = document.createElement( 'iframe' );
				f.src = this.iframeSrc;
				f.addEventListener( 'load', () => this.onReady() );
				this.iframeHolder.nativeElement.appendChild( f );
			}
		} );

		dialogRef.afterClosed().subscribe( () => {
			this.destroyed$.next();
			this.destroyed$.unsubscribe();
		} );

	}

	/**
	 * Handle the media item, determining if pdf, video or image
	 * @param media
	 */
	private parseMedia( media ): void {

		const matches = media.url.match( /\.[\w]+$/ );
		let extension = null;
		if ( matches ) {
			extension = matches[ 0 ].toLowerCase();
		}

		if ( extension === '.jpg' || extension === '.png' ) {

			this.isImage = true;

		} else if ( extension !== null ) {

			this.isPDF = true;
			this.iframeSrc = media.url;

		} else {
			this.isVideo = true;
		}

	}

	public onReady() {
		this.ready = true;
		this.cd.markForCheck();
		this.cd.detectChanges();
	}

	public onVideoFinish( event ) {
		this.onFinish.next( event );
	}
	public onVideoComplete( event ) {
		this.onComplete.next( event );
	}
	public onVideoProgress( event ) {
		this.onProgress.next( event );
	}
}
