<form (submit)="onFormSubmit($event)"
	  class="input-with-icon">

	<ab-icon name="search"></ab-icon>

	<input #searchInput
		   [(ngModel)]="searchTerm"
		   id="searchTerm"
		   name="searchTerm"
		   type="search"
		   placeholder="Search"
		   i18n-placeholder="common.words|Search@@commonWordSearch" />

</form>