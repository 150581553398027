<div class="resource-download-header"
	 [class.hasPrevious]="hasPrevious">
	<ab-icon name='arrow-left'
			 *ngIf="hasPrevious"
			 (click)='previous()'></ab-icon>
	<h5 *ngIf="stepper?.selected?.label || isMobile">{{ stepperLabel }}</h5>
	<ab-icon name='close'
			 (click)='closing.emit()'></ab-icon>
</div>

<div class="resource-download-content"
	 [class.hasPrevious]="hasPrevious">

	<mat-horizontal-stepper [linear]="true"
							(selectionChange)="onSelectionChange($event)"
							#stepper>
		<mat-step label=""
				  *ngIf="showPreviewStep">

			<resource-preview [media]="media"
							  [mediaTitle]="mediaTitle"
							  (next)="onNextStep()">
			</resource-preview>

		</mat-step>

		<mat-step label="Video download options"
				  i18n-label="videos|Video Download Modal Title@@videoDownloadModalTitle"
				  *ngIf="!skipDownloadOption && !loadingOptions && video.meta?.download?.length">

			<resource-download-options [video]="video"
										[mediaTitle]="mediaTitle"
									   [(downloadLink)]="downloadLink"
									   (next)="onNextStep()">
			</resource-download-options>

		</mat-step>

	</mat-horizontal-stepper>

</div>