import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { IPlayer } from '@builder/common/media/video/player/player.base';
import { PlayerBase } from '@builder/common/media/video/player/player.base';
import { FullScreen } from '@builder/common/util';


export const NATIVE_VIDEO_PLAYER_TEMPLATE =
	`<ab-video-controls *ngIf="!useNativeControls" [volumeStepper]="true" [allowFullScreen]="!options.disableFullScreen" [player]="this"></ab-video-controls>
	<video #player
		[src]='_src'
		[controls]="useNativeControls"
		controlsList="nodownload"
		(timeupdate)="onTimeUpdate($event)"
		(pause)="videoPause.next(this)"
		(play)="videoPlay.next(this)"
		(loadeddata)="onLoad()"
	></video>`;


@Component( {
	selector: 'native-video-player',
	template: NATIVE_VIDEO_PLAYER_TEMPLATE,
	styleUrls: [ 'nativePlayer.less' ]
} )
export class NativePlayerComponent extends PlayerBase implements OnInit, OnDestroy, AfterViewInit, IPlayer {

	@ViewChild('player') player: ElementRef;

	@Input( 'useNativeControls' ) useNativeControls = false;

	private get video(): HTMLVideoElement {
		return this.player ? this.player.nativeElement : null;
	}

	private loaded = false;

	public get currentTime(): number {
		return this.video ? this.video.currentTime : 0;
	}
	public get duration(): number {
		return this.video ? this.video.duration : 0;
	}

	public get paused(): boolean {
		return this.video ? this.video.paused : true;
	}

	public get volume(): number {
		return this.video ? this.video.volume : 0;
	}
	public set volume( val: number ) {
		if ( val > 1 ) val = 1;
		if ( val < 0 ) val = 0;
		if ( this.video ) {
			this.video.volume = val;
		}
	}

	private openCB;
	private closeCB;

	ngOnInit(): void {
		// if we're using custom controls they won't show up in fullscreen, so on change, enable fs controls
		if ( !this.useNativeControls ) {
			this.closeCB = () => { this.video.controls = false; };
			this.openCB = () => { this.video.controls = true; };
			FullScreen.on( 'open', this.openCB );
			FullScreen.on( 'close', this.closeCB );
		}
	}

	ngOnDestroy(): void {
		if ( !this.useNativeControls ) {
			FullScreen.off( 'open', this.openCB );
			FullScreen.off( 'close', this.closeCB );
		}
	}

	ngAfterViewInit(): void {
		this.video.oncontextmenu = e => e.preventDefault();
	}

	public requestFullScreen(): void {

		FullScreen.open( this.video );

	}

	public onTimeUpdate( e: Event ): void {

		this.videoProgress.emit( {
			progress: {
				seconds: this.currentTime,
				percent: this.currentTime / this.duration
			}
		} );
	}

	public onLoad(): void {

		this.loaded = true;
		if ( this.options.autoPlay ) {
			this.video.play();
		}
		this.videoReady.emit();
	}


	public play(): void {

		if ( this.loaded ) {
			this.video.play();
		} else {
			this.video.autoplay = true;
		}

	}
	public pause(): void {
		this.player.nativeElement.pause();
	}

	public seekTo( seconds: number ): void {
		this.video.currentTime = seconds;
	}

	/**
     * Fit the element
     */
	public resize(): void {
		if ( this.player.nativeElement ) {
			this.player.nativeElement.style.width = '100%';
			this.player.nativeElement.style.height = this.player.nativeElement.clientWidth * ( 540 / 960 ) + 'px';
		}
	}

}
