<form class="search-filters-form" [formGroup]="searchFiltersForm">
    <div class="search-filter" formGroupName="type">
        <span class="filter-heading"
            i18n="searchFilter.title|Search Filter Title - Resource Type@@searchFilterTitleResourceTypes">Resource
            Types</span>
        <mat-checkbox class="search-filter-checkbox" formControlName="video"
            i18n="searchFilter.option|Search Filter Option - Videos@@searchFilterOptionVideos">
            Videos
        </mat-checkbox>
        <mat-checkbox class="search-filter-checkbox" formControlName="document"
            i18n="searchFilter.option|Search Filter Option - Documents@@searchFilterOptionDocuments">
            Documents
        </mat-checkbox>
        <mat-checkbox class="search-filter-checkbox" formControlName="article"
            i18n="searchFilter.option|Search Filter Option - Articles@@searchFilterOptionArticles">
            Articles
        </mat-checkbox>
    </div>
    <div class="search-filter" formGroupName="category">
        <span class="filter-heading"
        i18n="searchFilter.title|Search Filter Title - Category@@searchFilterTitleCategory">Categories</span>
        <mat-checkbox 
            class="search-filter-checkbox"
            formControlName="material"
            i18n="searchFilter.option|Search Filter Option - Series Material@@searchFilterOptionSeriesMaterial">
            Series Material
        </mat-checkbox>
        <mat-checkbox class="search-filter-checkbox" formControlName="promotional"
            i18n="searchFilter.option|Search Filter Option - Promotional Material@@searchFilterOptionPromotionalMaterial">
            Promotional Material
        </mat-checkbox>
        <mat-checkbox class="search-filter-checkbox" formControlName="learning"
            i18n="searchFilter.option|Search Filter Option - Learning Modules@@searchFilterOptionLearningModules">
            Learning Centre
        </mat-checkbox>
    </div>
    <div class="search-filter" formGroupName="themes">
        <span class="filter-heading"
            i18n="searchFilter.title|Search Filter Title - Themes@@searchFilterTitleThemes">Themes</span>
        <mat-checkbox class="search-filter-checkbox" formControlName="all"
            i18n="searchFilter.option|Search Filter Option - All Themes@@searchFilterOptionAll">
            All
        </mat-checkbox>
        <mat-checkbox
            *ngFor="let filter of themesFilterList"
            class="search-filter-checkbox"
            [ngClass]="{'disabled': themeFiltersForm.controls.all.value }"
            [formControlName]="filter.formControlName">
            {{filter.title}}
        </mat-checkbox>
    </div>
    <div class="search-filter">
		<div class="tooltip-container"> 
            <span class="filter-heading"
                i18n="searchFilter.title|Search Filter Title - Search within@@searchFilterTitleSearchWithin">Search within</span>
			<ab-icon name="question" 
                [popper]="searchWithinFilter"
				popperTrigger="click"
				[popperHideOnClickOutside]="true">
			</ab-icon>        
            <popper-content #searchWithinFilter>
                <caption i18n="filter.tooltip|Search Within Filter - Search Within Alpha tooltip@@searchWithinFilter"
                    class="tooltip-text">Alpha content like Talks or Team Training materials are only accessible while or after running an Alpha Series. 
                    Choose “My Alphas” to exclude content outside your Alphas.
                </caption>
            </popper-content> 
        </div>
        <mat-radio-group formControlName="searchWithin" aria-label="Show Content From">
            <mat-radio-button class="filter-radio" value="all" i18n="searchFilter.option|Search Filter Option - All Alphas@@searchFilterOptionAllAlphas">All Alphas</mat-radio-button>
            <mat-radio-button class="filter-radio" value="active" i18n="searchFilter.option|Search Filter Option - My Alphas@@searchFilterOptionMyAlphas">My Alphas</mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="search-filter">
        <span class="filter-heading"
            i18n="searchFilter.title|Search Filter Title - Alpha Series@@searchFilterTitleAlphaSeries">Alpha Series</span>

        <mat-form-field appearance="outline" color="accent">
            
            <mat-select formControlName="products" multiple [placeholder]="seriesSelectPlaceholder" [value]="productsFilter">

                <mat-select-trigger>
                    <mat-chip-listbox>
                        <mat-chip class="chip-series" *ngFor="let productId of productsFilter">
                            {{ enabledProductsMap[productId] }}
                        </mat-chip>
                    </mat-chip-listbox>
                </mat-select-trigger>
            
                <mat-option *ngFor="let productId of enabledProductsIds" [value]="productId">{{enabledProductsMap[productId]}}</mat-option>
            </mat-select>

        </mat-form-field>

        <div class="button-container" *ngIf="hasProductsSelected">
            <button mat-button class="mya-text-button" (click)="clearSelection()">
                <span i18n="searchFilters.action|Search Filters - Clear@@searchFiltersActionsClear">Clear</span>
                <ab-icon name="close" size="10"></ab-icon>
            </button>
        </div>

    </div>

</form>