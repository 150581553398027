<div class="card-meta-icon-list">
	<ab-icon name="book"
			 *ngIf="playlist.articleCount"><span>{{ playlist.articleCount }}&nbsp;</span>
		<span i18n="common.words|Article@@commonWordArticle"
			  *ngIf="playlist.articleCount === 1">Article</span>
		<span i18n="common.words|Articles@@commonWordArticles"
			  *ngIf="playlist.articleCount > 1">Articles</span>
	</ab-icon>

	<ab-icon name="movie"
			 *ngIf="playlist.videoCount"><span>{{ playlist.videoCount }}&nbsp;</span>
		<span i18n="common.words|Video@@commonWordVideo"
			  *ngIf="playlist.videoCount === 1">Video</span>
		<span i18n="common.words|Videos@@commonWordVideos"
			  *ngIf="playlist.videoCount > 1">Videos</span>
	</ab-icon>

	<ab-icon *ngIf="playlist.duration"
			 name="time" flip="true"><span>{{ playlist.duration | playTime }}</span></ab-icon>

</div>

<div *ngIf="currentUser.loggedIn()"
	 class="playlist-progress">
	<progress-circle [value]="playlist.percentComplete"
					 diameter="16"></progress-circle>

</div>