import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';

import { ABVideoControlsComponent } from './player/controls';
import { NativePlayerComponent } from './player/nativePlayer';
import { VideoPlayerComponent, VideoRefDirective } from './player/videoPlayer';
import { GDrivePlayerComponent } from './player/gdrivePlayer';
import { VimeoPlayerComponent } from './player/vimeoPlayer';
import { VideoPlaylistComponent } from './playlist/videoPlaylist';


const components = [
	NativePlayerComponent,
	VideoPlayerComponent,
	VideoPlaylistComponent,
	VimeoPlayerComponent,
	GDrivePlayerComponent,
	ABVideoControlsComponent
];

@NgModule( {
	imports: [
		CommonModule,
		FormsModule,
		BuilderMaterialModule,
		CommonPipesModule,
		BuilderIconModule
	],
	declarations: [ components, VideoRefDirective ],
	exports: components,
	entryComponents: components
} )
export class VideoPlayerModule { }
