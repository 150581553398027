import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Locale } from "./locale";

@Injectable()
export class SSOLanguageHeaders implements HttpInterceptor {

	constructor( private locale: Locale ) {

	}

	intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

		const observable = next.handle( req );

		if ( req.url !== '/wp-json/wp/v2/sso/token/callback' ) {
			return observable;
		}

		return observable.pipe( switchMap( ( response: any ) => {

			return new Observable<any>( observer => {

				if ( !( response instanceof HttpResponse ) ) {
					return observer.next( response );
				}
				const requestedLanguage = ( response.headers.get( 'ab-sso-set-language' ) ?? '' ).toLowerCase();
				if ( !requestedLanguage || requestedLanguage === this.locale.code ) {
					return observer.next( response );
				}

				this.locale.set( requestedLanguage ).subscribe( r => {
					observer.next( response );
				} );
			} );

		} ) );
	}
}


export let ssoLanguageInterceptor = {
	provide: HTTP_INTERCEPTORS,
	useClass: SSOLanguageHeaders,
	multi: true,
	deps: [ Locale ]
};