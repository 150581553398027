import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { tap } from 'rxjs/operators';

import { Locale } from '@builder/common/lang/locale';
import { cloneObjectArrays, concat, convertObjectKeysToLabels } from '@builder/common';

import { MixPanelService, mixPanelSettingsProvider } from './mix-panel.service';
import { TrackingService, ClickCreateAlpha, CreatedAlpha, UpdatedAlpha, DeletedAlpha, PromoteCategoryChange, ChangeAlphaMaterial, ChangeAlphaOrganization, InvitedTeamMember, RemovedTeamMember, ResentTeamInvitation, AcceptedTeamInvitation, UpdatedAlphaSchedule, ResetAlphaSchedule, DownloadPromoMaterial, PreviewPromoMaterial, DownloadSeriesMaterial, CreateAccountRequest, UserLogin, UserLogout, UserSave, TokenValidated, CreateAccountRequestVerified, UpdateUserPreferences, ChangeUserOrganization, PlayEpisodePreviewVideo, FinishEpisodePreviewVideo, PlayProductTeaserVideo, FinishProductTeaserVideo, WatchedTrainingVideo, PageView, LibrarySearch, DeletedUser, UserLoginFailed, UserPasswordResetRequest, ReadTrainingArticle, UserPasswordReset } from '@builder/tracking';



/**
 * This class handles subscribing to app events and triggering mix-panel events
 */
@Component( {
	selector: 'mix-panel',
	template: '',
	providers: [ mixPanelSettingsProvider, MixPanelService ]
} )
export class MixPanelComponent {

	constructor(
		service: MixPanelService,
		trackingService: TrackingService,
		locale: Locale

	) {

		/**
		 * Some helpers and utils
		 */

		// date pipe for formatting
		const datePipe = new DatePipe( locale.code );

		// resource type labels
		const getResourceTypeLabel = resource => {
			if ( resource.type === 'document' ) {
				return 'Document - ' + resource.attachedDocument.file.substring( resource.attachedDocument.file.lastIndexOf( '.' ) + 1 ).toUpperCase();
			}
			return 'Video';
		};

		/**
		 * Subscribe to all Tracking Events system wide
		 */
		trackingService.events.pipe(

			// console log event ( if you want )
			// tap( event => console.info( event ) )

		).subscribe(

			event => {

				/**
				* Pageview
				*/
				if ( event instanceof PageView ) {

					service.trackEvent( 'Page View', {
						... ( event.data.url !== event.data.previous.path && { 'Previous URL': event.data.previous.url } )
					} );
				}

				/**
				 * Create Alpha Click
				 */
				else if ( event instanceof ClickCreateAlpha ) {

					service.trackEvent( 'Create Alpha Click' );
				}

				/**
				 * Created an Alpha
				 */
				else if ( event instanceof CreatedAlpha ) {

					service.trackEvent( 'Create an Alpha', {
						'Product Name': event.data.alpha.product.title,
						'Alpha Name': event.data.alpha.title,
						'Course Start Date': datePipe.transform( event.data.alpha.date, 'short' ),
						'Created Date': datePipe.transform( event.data.alpha.date_created, 'short' ),
						'Address': event.data.alpha.formatted_address,
						'Course Language': event.data.alpha.product.language,
						'Organization Name': event.data.alpha.organization.name,
						'Published/Promoted': event.data.alpha.promote ? 'Yes' : 'No',
						'Course Questions': event.data.questions
					} );
				}

				/**
				 * Updated Alpha ( not tracking )
				 */
				else if ( event instanceof UpdatedAlpha ) { }

				/**
				 * Deleted Alpha
				 */
				else if ( event instanceof DeletedAlpha ) {

					service.trackEvent( 'Delete Alpha', {
						'Product Name': event.data.alpha.product.title,
						'Alpha Name': event.data.alpha.title,
						'Course Start Date': datePipe.transform( event.data.alpha.date, 'short' ),
					} );
				}

				/**
				 * Changed Category in Alpha Promote Tab
				 */
				else if ( event instanceof PromoteCategoryChange ) {

					service.trackEvent( 'Promo Material Category Change', {
						'New Category': event.data.current.name,
						'Previous Category': event.data.previous.name
					} );
				}

				/**
				 * Changed Product in Alpha details editor
				 */
				else if ( event instanceof ChangeAlphaMaterial ) {

					service.trackEvent( 'Change Alpha Material', {
						'Alpha Name': event.data.alpha.title,
						'New Product Name': event.data.current.title,
						'Previous Product Name': event.data.previous.title
					} );
				}

				/**
				 * Changed the Organization associated with an Alpha
				 */
				else if ( event instanceof ChangeAlphaOrganization ) {

					service.trackEvent( 'Change Alpha Organization', {
						'Alpha Name': event.data.alpha.title,
						'New Organization Name': event.data.current.name,
						'Previous Organization Name': event.data.previous.name
					} );
				}

				/**
				 * Invited a Team Member to an Alpha
				 */
				else if ( event instanceof InvitedTeamMember ) {

					service.trackEvent( 'Invite Team Member', {
						'Alpha Name': event.data.alpha.title,
						'Emails': event.data.emails,
						'# of Accpted Invites': event.data.team.accepted,
						'# of Pending Invites': event.data.team.pending
					} );
				}

				/**
				 * Removed a Team Member from an Alpha
				 */
				else if ( event instanceof RemovedTeamMember ) {

					service.trackEvent( 'Remove Team Member', {
						'Alpha Name': event.data.alpha.title,
						'Emails': event.data.emails,
						'# of Accpted Invites': event.data.team.accepted,
						'# of Pending Invites': event.data.team.pending
					} );
				}

				/**
				 * Resent Team Member invitation
				 */
				else if ( event instanceof ResentTeamInvitation ) {

					service.trackEvent( 'Re-send Team Member Invite', {
						'Alpha Name': event.data.alpha.title,
						'Emails': event.data.emails,
					} );
				}

				/**
				 * Team Member visited Alpha they were invited to for the first time
				 */
				else if ( event instanceof AcceptedTeamInvitation ) {

					service.trackEvent( 'Team Member Accepted Invitation', {
						'Alpha Name': event.data.alpha.title
					} );
				}

				/**
				 * Schedule Updated for Alpha Sessions
				 */
				else if ( event instanceof UpdatedAlphaSchedule ) {

					const transformSchedule = planning => {
						const plan = cloneObjectArrays( planning ),
							data = {},
							items = concat(
								plan.locked ? plan.locked : [],
								plan.standard ? plan.standard : [],
								plan.weekend ? plan.weekend : [],
								plan.postweekend ? plan.postweekend : []
							);
						items.forEach( ( item, i ) => {
							data[ 'Session ' + ( i + 1 ) ] = item.title;
						} );
						( plan.available ? plan.available : [] ).forEach( ( item, i ) => {
							data[ 'Removed ' + ( i + 1 ) ] = item.title;
						} );
						return data;

					};

					service.trackEvent( 'Changed Alpha Schedule', {
						'Alpha Name': event.data.alpha.title,
						'Start Date': datePipe.transform( event.data.alpha.date, 'short' ),
						'End Date': datePipe.transform( event.data.alpha.end_date, 'short' ),
						'Source': event.data.source,
						...{ ...transformSchedule( event.data.alpha.planning ) }
					} );
				}

				/**
				 * Reset the Alpha Schedule
				 */
				else if ( event instanceof ResetAlphaSchedule ) {

					service.trackEvent( 'Reset Alpha Schedule', {
						'Alpha Name': event.data.alpha.title,
						'Start Date': datePipe.transform( event.data.alpha.date, 'short' )
					} );
				}

				/**
				 * Downloaded item from the Alpha Promote tab
				 */
				else if ( event instanceof DownloadPromoMaterial ) {

					service.incrementSuperProperty( 'Downloaded - Promo Materials' );

					service.trackEvent( 'Download Alpha Promo Material', {
						'Alpha Name': event.data.alpha.title,
						'Product Name': event.data.alpha.product.title,
						'Material Name': event.data.resource.title,
						'Material Type': getResourceTypeLabel( event.data.resource ),
						'Material Language': event.data.resource.language
					} );

				}

				/**
				 * Opened preview modal for item in the Alpha Promote tab
				 */
				else if ( event instanceof PreviewPromoMaterial ) {

					service.trackEvent( 'Preview Alpha Promo Material', {
						'Alpha Name': event.data.alpha.title,
						'Product Name': event.data.alpha.product.title,
						'Material Name': event.data.resource.title,
						'Material Type': getResourceTypeLabel( event.data.resource ),
						'Material Language': event.data.resource.language
					} );
				}

				/**
				 * Downloaded an item from the Series Material tab
				 */
				else if ( event instanceof DownloadSeriesMaterial ) {

					service.incrementSuperProperty( 'Downloaded - Series Materials' );

					service.trackEvent( 'Download Alpha Series Material', {
						'Alpha Name': event.data.alpha.title,
						'Product Name': event.data.alpha.product.title,
						'Material Name': event.data.resource.title,
						'Material Type': getResourceTypeLabel( event.data.resource ),
						'Material Language': event.data.resource.language
					} );

				}

				/**
				 * User completed the signup form
				 */
				else if ( event instanceof CreateAccountRequest ) {

					service.trackEvent( 'Create Account Request', convertObjectKeysToLabels( event.data.userData ) );
				}

				/**
				 * User verified their account request
				 */
				else if ( event instanceof CreateAccountRequestVerified ) {

					// setting the alias will tie pre-signup events to this new user
					service.alias( event.data.user.id );
					service.trackEvent( 'Create Account Verified' );
				}

				/**
				 * User logged in ( set the super properties here )
				 */
				else if ( event instanceof UserLogin ) {

					// set the user
					service.setUser( event.data.user );

					service.incrementSuperProperty( 'Total Logins' );

					// track login
					service.trackEvent( 'User Login' );

				}

				/**
				 * Token validated ( when a user is logged in and refreshes the page )
				 */
				else if ( event instanceof TokenValidated ) {

					service.setUser( event.data.user );
				}

				/**
				 * User login failed
				 */
				else if ( event instanceof UserLoginFailed ) {

					service.trackEvent( 'User Login Failed', {
						'Email': event.data.useremail
					} );
				}

				/**
				 * User logged out
				 */
				else if ( event instanceof UserLogout ) {

					service.trackEvent( 'User Logout' );
					service.logout();
				}

				/**
				 * User sent password reset request
				 */
				else if ( event instanceof UserPasswordResetRequest ) {

					service.trackEvent( 'User Password Reset Request', {
						'Email': event.data.useremail
					} );
				}

				/**
				 * User reset password
				 */
				else if ( event instanceof UserPasswordReset ) {

					service.trackEvent( 'User Password Reset', {
						'distinct_id': event.data.user.id
					} );
				}

				/**
				 * User saved profile ( not tracking )
				 */
				else if ( event instanceof UserSave ) {

					service.setUser( event.data.user );

				}

				/**
				 * Deleted Account
				 */
				else if ( event instanceof DeletedUser ) {

					service.deleteUser( event.data.user );

				}

				/**
				 * User updated their preferences
				 */
				else if ( event instanceof UpdateUserPreferences ) {

					service.trackEvent( 'Update Email Notifications', {
						'Previous': event.data.previous.notifications.subscriptions.join( ', ' ),
						'Current': event.data.current.notifications.subscriptions.join( ', ' )
					} );
				}

				/**
				 * User changed their Organization
				 */
				else if ( event instanceof ChangeUserOrganization ) {

					service.trackEvent( 'Change User Organization', {
						'New Organization Name': event.data.current.name,
						'Previous Organization Name': event.data.previous.name
					} );
				}

				/**
				 * Playing a Product Preview Episode
				 */
				else if ( event instanceof PlayEpisodePreviewVideo ) {

					/**
					 * @todo would be nice to have this only sending at key mile stones ( play, 25%, 50%, 75%, finish)
					 */
					service.trackEvent( 'Play Episode Preview Video', {
						'Product Name': event.data.product.title,
						'Video Title': event.data.video.title,
						'Seconds Watched': Math.floor( event.data.progress.seconds ),
						'% Watched': Math.floor( event.data.progress.percent * 100 )
					} );
				}

				/**
				 * Finished watching a Product Preview Episode
				 */
				else if ( event instanceof FinishEpisodePreviewVideo ) {

					service.trackEvent( 'Finish Episode Preview Video', {
						'Product Name': event.data.product.title,
						'Video Title': event.data.video.title
					} );
				}

				/**
				 * Playing a Product Teaser video
				 */
				else if ( event instanceof PlayProductTeaserVideo ) {

					/**
					 * @todo would be nice to have this only sending at key mile stones ( play, 25%, 50%, 75%, finish)
					 */
					service.trackEvent( 'Play Product Teaser Video', {
						'Product Name': event.data.product.title,
						'Seconds Watched': Math.floor( event.data.progress.seconds ),
						'% Watched': Math.floor( event.data.progress.percent * 100 )
					} );
				}

				/**
				 * Finished watching a Product Teaser video
				 */
				else if ( event instanceof FinishProductTeaserVideo ) {

					service.trackEvent( 'Finish Product Teaser Video', {
						'Product Name': event.data.product.title
					} );
				}

				/**
				 * Used the search functionality
				 */
				else if ( event instanceof LibrarySearch ) {

					service.trackEvent( 'Library Search', event.data.query );
				}

				/**
				 * Finished watching a video in the Training Centre
				 */
				else if ( event instanceof WatchedTrainingVideo ) {

					service.incrementSuperProperty( 'Training Videos Watched' );

					service.trackEvent( 'Watched Training Video', {
						'Video Name': event.data.lesson.title,
						'Seconds Watched': Math.floor( event.data.lesson.duration ),
						... ( event.data.playlist && { 'Module Name': event.data.playlist.title } )
					} );

					service.setUserSuperProperty( 'Last Video Watched Date', new Date().toISOString() );
				}

				/**
				 * Finished reading an article in the Training Centre
				 */
				else if ( event instanceof ReadTrainingArticle ) {

					service.incrementSuperProperty( 'Training Articles Read' );

					service.trackEvent( 'Read Training Article', {
						'Article Name': event.data.lesson.title,
						... ( event.data.playlist && { 'Module Name': event.data.playlist.title } )
					} );

					service.setUserSuperProperty( 'Last Article Read Date', new Date().toISOString() );
				}


			}
		);
	}

}
