import { Component, ElementRef, ViewChild, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CurrentUser } from '@builder/users';
import { ISignonOptions } from '@builder/common/models/signon-options';
import { UserService } from '@builder/users/service';
import { Locale, ILanguageOption } from '../../common/lang/locale';

import { expandDown } from '../../common/animations';

@Component( {
	selector: 'login-component',
	templateUrl: 'login.component.html',
	styleUrls: [ '../styles/signon.common.less', 'login.component.styles.less' ],
    animations: [
		expandDown( 'expandDown' )
	]
} )
export class LoginComponent implements OnInit, AfterViewInit {

	@ViewChild('passwordField') passwordField: ElementRef;

	public form: FormGroup;
	public signonOptions: FormGroup;

	public showExtraLoginOptions: boolean;

	public errorMessage: string;

	public loading: boolean;

	public autoLogin = '';

	public messageOnlyDisplay: boolean; // whether to remove all actions on the page

	public showLanguageMenu: boolean;

	public languages: ILanguageOption[];

	constructor(
		public user: CurrentUser,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private userService: UserService,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		public locale: Locale
	) {
		this.languages = locale.languages.filter( l => l.public === true && l.code !== locale.code );
	}

	/**
	*directly redirect to dashboard if user is logged in.
	*/
	public ngOnInit(): void {

		this.form = this.formBuilder.group( {

			user_email: [ this.autoLogin, Validators.required ],
			password: [ '', Validators.required ],
			rememberMe: [ false ]
		} );

		this.route.queryParams.subscribe( params => {

			if ( params[ 'userlogin' ] ) {
				this.autoLogin = params[ 'userlogin' ];
				this.form.controls.user_email.setValue( this.autoLogin );
			}
		} );

		this.signonOptions = new FormGroup( {
			skipSalesForce: new FormControl( false ),
			isAdminQA: new FormControl( false )
		} );

	}

	public ngAfterViewInit(): void {

		// request ani frame and set focus to pw field if autologin
		if ( this.autoLogin ) {
			requestAnimationFrame( () => {
				this.passwordField.nativeElement.focus();
			} );

		}

		/**
		 * The already logged in text has dynamic content from poeditor. This is no longer supported as compiled content, so find [routerlinks] and listen to them to navigate
		 */

		document.querySelectorAll( 'a[\\[routerlink\\]]' ).forEach( el => {

			el.addEventListener( 'click', event => {

				const instructionString: string = ( event.target as HTMLElement ).getAttribute( '[routerlink]' );
				try {
					const { instruction } = JSON.parse( '{"instruction": ' + instructionString.replace( /'/g, '"' ) + '}' );
					this.router.navigate( instruction );
				} catch ( err ) {

				}
			} );
		} );
	}

	/**
	 *
	 */
	public resetEmail(): void {

		if ( this.loading ) {
			return;
		}

		this.errorMessage = null;

		this.form.reset();

		this.changeDetector.detectChanges();

	}

	/**
     * Handle the signon form submission.
     * Different actions will be taken depending on the state of the form.
     *  - It can check if the email is attached to a WP account, or is in SF
     *  - It can accept a sign up request
     */
	public onFormSubmit(): void {

		this.login();

	}

	/**
     * Attempt a login
     */
	private login(): void {

		this.errorMessage = null;
		this.loading = true;

		this.form.disable();

		const signonOptions: ISignonOptions = this.signonOptions.value;

		this.userService.login( this.form.controls.user_email.value, this.form.controls.password.value, this.form.controls.rememberMe.value, signonOptions )
			.subscribe(
				currentUser => {

					this.loading = false;
					// redirection handled by signon.component

				},
				errorResponse => {

					this.errorMessage = errorResponse.error.message;

					if ( errorResponse.error.code === '[jwt_auth] user_exists' ) {
						this.messageOnlyDisplay = true;
					}

					this.loading = false;

					this.form.enable();

					// push changes
					this.changeDetector.detectChanges();
				}
			);
	}

}
