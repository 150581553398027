import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { ProgressCircleModule } from '@builder/common/ui/progress-circle/progress-circle.module';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module';

import { PlaylistMetaBarComponent } from './playlist-meta-bar.component';


@NgModule( {
	imports: [
		CommonModule,
		BuilderIconModule,
		CommonPipesModule,
		ProgressCircleModule,
		AlphaLocaleModule

	],
	declarations: [
		PlaylistMetaBarComponent
	],
	exports: [
		PlaylistMetaBarComponent
	]
} )
export class PlaylistMetaBarModule { }
