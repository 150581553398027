import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FeaturesService } from "@builder/common/features/features.service";
import { FEATURE_SEARCH_UPDATE } from "@builder/common/features/feature-flag";

/**
 * Search Update Feature flag
 * Adds a classname to body tag
 */
@Injectable( {
	providedIn: 'root'
} )
export class SearchUpdate{
	constructor(
		features: FeaturesService,
		@Inject(DOCUMENT) private document: Document,
	) {
		features.whenOn(FEATURE_SEARCH_UPDATE).subscribe( () => this.initialize() );
	}

	/**
	 * Add the script to the page
	 */
	private initialize(): void {
		this.document.body.classList.add( 'search-update' )
	}
}