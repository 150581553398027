<nav [class.menu-panel-open]="menuVisible">
    <ng-container *ngIf="currentUser.loggedIn()">
        <div class="menu-item" routerLinkActive="menu-item-current" [routerLink]="['/dashboard']">
            <ab-icon size="22" name="house"></ab-icon>
            <a i18n="common.words|Dashboard@@commonWordDashboard">Dashboard</a>
        </div>
        <div class="menu-item" routerLinkActive="menu-item-current" [routerLink]="['/alphas']">
            <ab-icon size="22" name="computer"></ab-icon>
            <a i18n="alpha.myAlpha|My Alphas Courses@@MyAlphasCourses">My Alpha Courses</a>
        </div>
        <div class="menu-item" routerLinkActive="menu-item-current" [routerLink]="['/preview-resources']"
			[attr.data-driver]="'overview1'">
            <ab-icon size="22" name="tvstand"></ab-icon>
            <a i18n="common.resources|Preview Resources Label@@previewResourcesLabel">Preview Resources</a>
        </div>
    </ng-container>
    <div class="menu-item" [class.menu-item-selected]="menuVisible" (click)="toggleMobileMenu()"
        id="mobile-menu-more-btn">
        <ab-icon size="22" name="more-dots"></ab-icon>
        <a i18n="common.words|Common Words - More@@commonWordsMore">More</a>
    </div>
</nav>
<div class="mobile-menu-panel" [class.open]="menuVisible">
    <ab-icon name="close" class="menu-close-button" (click)="toggleMobileMenu()"></ab-icon>

    <div routerLinkActive="menu-item-current" *ngIf="!theme.supports('disable_training') && locale.trainingCenter.enable" [routerLink]="['/training']" class="menu-item"
        [attr.data-driver]="'overview3'">
        <ab-icon name="book"></ab-icon>
        <a i18n="training|Learning Centre Title@@learningCenterTitle">Learning Centre</a>
    </div>
    <div class="menu-item" [attr.data-driver]="'overview7'">
        <ab-icon name="support"></ab-icon>
        <a *ngIf="theme.value('support_site_url')" [href]="theme.value('support_site_url')" class="link"
            target="_blank" attr.data-test="auth-link-mobileHelp"
            i18n="common.labels|Menu Support Label@@commonLabelsHelpnSupport">
            Help and support
        </a>
    </div>
    <div routerLinkActive="menu-item-current" *ngFor="let page of additionalMenuItems" [routerLink]="['/' + page.slug ]" class="menu-item">
        <ab-icon [name]="page.icon"></ab-icon>
        <a [innerHTML]="page.title"></a>
    </div>
    <div class="divider"></div>
    <div class="menu-item" (click)="toggleSearch()" [attr.data-driver]="'overview4'">
        <ab-icon name="search"></ab-icon>
        <a i18n="common.words|Search@@commonWordSearch">Search</a>
    </div>

    <div class="mobile-menu-cta-and-version">
        <a
            [attr.data-driver]="'overview8'"
            mat-flat-button
            class = "create-button"
            *ngIf="currentUser.loggedIn() && !simpleCreateCourseIsEnabled && !coraEnabled"
            id="navCreateAlphaButton"
            color="primary"
            [routerLink]="['/alphas/new/step/1']"
            [state]="{ack:true}"
            i18n="common.buttons|Create an Alpha@@buttonCreateAlpha"
            track-event="create-alpha-click"
        >
            Create an Alpha
        </a>
        <a
            [attr.data-driver]="'overview8'"
            mat-flat-button
            class = "create-button"
            *ngIf="currentUser.loggedIn() && (simpleCreateCourseIsEnabled || coraEnabled)"
            id="navCreateAlphaButton"
            color="primary"
            [routerLink]="['/preview-resources']"
            [state]="{ack:true}"
            i18n="common.buttons|Select a course button@@selectCourseButton"
            track-event="create-alpha-click"
        >
            Select a course
        </a>
        <div>
            <div class="version"
            i18n="common.version|Version and year@@versionYear">Version {{versionAndYear// i18n:(ph="version_number")}}</div>
        </div>
        <a mat-flat-button *ngIf="!currentUser.loggedIn()" id="navLoginButton" color="primary" [routerLink]="['/user', 'signon']" i18n="common.labels|Login@@commonLabelsLogin">Login</a>
    </div>
</div>