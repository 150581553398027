import { Component, OnInit, ChangeDetectorRef, Input, forwardRef } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { WithDestroy } from '@builder/common/mixins/with-destroy';
import { takeUntil } from 'rxjs/operators';

@Component( {
	selector: 'password-editor',
	templateUrl: './password-editor.component.html',
	styleUrls: [ 'password-editor.component.less' ],
	exportAs: 'passwordEditor',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef( () => PasswordEditorComponent ),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef( () => PasswordEditorComponent ),
			multi: true
		},
	]
} )
export class PasswordEditorComponent extends WithDestroy() implements OnInit {

	@Input() user;

	public form: FormGroup;

	public errorMsg: string;

	public minPasswordLength = 8;

	ngOnInit(): void {

		const validatePWMatch = this.validatePasswordMatch.bind( this );

		this.form = new FormGroup( {
			username: new FormControl(),
			oldPassword: new FormControl( '', this.user ? Validators.required : [] ),
			newPassword: new FormControl( '', [ Validators.required, Validators.minLength( this.minPasswordLength ) ] ),
			confirmPassword: new FormControl(),
		}, {
			validators: validatePWMatch
		} );

		if ( this.user ) {
			this.form.controls.username.setValue( this.user.email );
		}

		this.form.valueChanges.pipe( takeUntil( this.destroy$ ) ).subscribe( changes => {

			if ( !this.form.valid ) {
				this.propagateChange( null );
			} else {
				this.propagateChange( changes );
			}
		} );
	}

	validate( c: FormControl ) {

		const errors = Object.assign( this.form.errors || {}, this.newPassword.errors || {}, this.oldPassword.errors || {} );

		return Object.keys( errors ).length > 0 ? errors : null;
	}


	propagateChange = ( _: any ) => { };

	registerOnChange( fn ) {
		this.propagateChange = fn;
	}

	registerOnTouched() { }

	writeValue( value ) { }

	reset(): void {
		this.form.reset();
	}
	get value() {
		return this.form.value;
	}

	get dirty() {
		return this.form.dirty;
	}

	get valid() {
		return this.form.valid;
	}

	get oldPassword() {
		return this.form.get( 'oldPassword' );
	}

	get newPassword() {
		return this.form.get( 'newPassword' );
	}

	get confirmPassword() {
		return this.form.get( 'confirmPassword' );
	}

	private validatePasswordMatch( group: FormGroup ): ValidationErrors | null {
		if ( !this.form ) {
			return {};
		}
		const pass1: string = group.get( 'newPassword' ).value;
		const pass2: string = group.get( 'confirmPassword' ).value;

		const errors: any = {};

		if ( !pass1 ) {
			errors.passwordRequired = true;
			return errors;
		}

		if ( pass1.length < this.minPasswordLength ) {
			errors.passwordMinLength = true;
		}

		const passwordRulesRegEx = new RegExp( /^(?=(.*\d))(?=.*[ -\/:-@[-`{-~]+)(?=.*[a-z])(?=.*[A-Z])[\x20-\xFF]{8,}$/ );
		if ( !passwordRulesRegEx.test( pass1 ) ) {
			errors.passwordMinRules = true;
		}

		const passwordRulesUppercaseRegEx = new RegExp( /^(?=.*[a-z])(?=.*[A-Z])[\x20-\xFF]{1,}$/ );
		if ( !passwordRulesUppercaseRegEx.test( pass1 ) ) {
			errors.passwordMinRulesUpperLowercase = true;
		}

		const passwordRulesNumberRegEx = new RegExp( /^(?=(.*\d))[\x20-\xFF]{1,}$/ );
		if ( !passwordRulesNumberRegEx.test( pass1 ) ) {
			errors.passwordMinRulesNumbers = true;
		}

		const passwordRulesSymbolRegEx = new RegExp( /^(?=.*[ -\/:-@[-`{-~]+)[\x20-\xFF]{1,}$/ );
		if ( !passwordRulesSymbolRegEx.test( pass1 ) ) {
			errors.passwordMinRulesSymbols = true;

		}

		if ( pass1 !== pass2 ) {
			errors.passwordMismatch = true;
		}

		return errors;
	}

}
