import { Injectable } from '@angular/core';
import { FEATURE_PRODUCT_TOUR } from '@builder/common/features/feature-flag';
import { FeaturesService } from '@builder/common/features/features.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTour {

  constructor(
    features: FeaturesService
  ) {
    
    features.whenOn(FEATURE_PRODUCT_TOUR).subscribe( () => this.initialize() );
  }

    private initialize(): void {

        const scriptId = 'mya-driverjs-js';
        let s: HTMLScriptElement = document.getElementById( scriptId ) as HTMLScriptElement;
        if ( s ) {
            return;
        }

        s = document.createElement( 'script' );
        s.id = scriptId;
        s.src = 'https://cdn.jsdelivr.net/npm/driver.js@1.0.1/dist/driver.js.iife.js';
        s.async = true;
        const e = document.getElementsByTagName( 'script' )[ 0 ];
        e.parentNode.insertBefore( s, e );
        
        const cssId = 'mya-driverjs-css';
        var link = document.createElement('link');
        link.id = cssId;
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://cdn.jsdelivr.net/npm/driver.js@1.0.1/dist/driver.css';
        link.media = 'all';
        e.parentNode.insertBefore( link, e );
    }
}
