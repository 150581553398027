import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppTheme } from './common/util/themeSupports';
import { CurrentUser } from './users';
import { AnnouncementsService } from './announcements/announcements.service';
import { AppInjector } from './app.injector';

/**
 * Import Features
 */
import { Survicate } from './features/survicate';
import { Tidio } from './features/tidio';
import { ProductTour } from './features/driverjs';
import { SimpleCourseCreate } from './features/simple-course-create';
import { SearchUpdate } from './features/search-update';
import { FeaturesService } from './common/features/features.service';
import { CustomTitleStrategyService } from './common/custom-title/custom-title-strategy.service';
import { PageLoadingService } from './common/page-loading/page-loading.service';
import { Observable, takeUntil } from 'rxjs';
import { WithDestroy } from './common/mixins/with-destroy';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { BuilderMenuComponent } from './common/menu/builder-menu.component';
import { PageView, TrackingService } from './tracking';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { ProfileUrlsService } from './common/profile-urls/profile-urls.service';
import { FEATURE_PRODUCT_TOUR, FEATURE_SEARCH_UPDATE, FEATURE_SIMPLE_COURSE_CREATE, FEATURE_USER_PROFILE, FEATURE_CORA } from './common/features/feature-flag';
import { Cora } from './features/cora';

@Component( {
	selector: 'alpha-builder',
	template: `
	
	<div id="bodyWrapper">
		<div class="page-header" *ngIf="currentUser.loggedIn() && !isFullScreenPage">
			<announcement id="announcement" *ngIf="announcementService.latestAnnouncement && !showCookieConsent" 
				[announcement]="announcementService.latestAnnouncement"></announcement>
			<mobile-header *ngIf="!userProfileIsEnabled && isMobileView" attr.data-test="manageAlphas-container-mobileHeader"></mobile-header>
			<user-header *ngIf="userProfileIsEnabled" attr.data-test="manageAlphas-container-userHeader"></user-header>
		</div>
		<div class="page-content">
			<ng-container *ngIf="currentUser.loggedIn() && !isMobileView && !isFullScreenPage">
				<builder-menu *ngIf="!userProfileIsEnabled" id="builderMenu" (toggleSearchPanelEvent)="toggleSearchPanel()"></builder-menu>
				<side-menu *ngIf="userProfileIsEnabled" id="sideMenu" (toggleSearchPanelEvent)="toggleSearchPanel()"></side-menu>
			</ng-container>
			<div page-loading-target id='contentContainer'> 
				<search-panel 
					*ngIf="!searchUpdateEnabled && !isFullScreenPage" 
					[showSearch]="showSearchPanel"
					(toggleSearchPanelEvent)="toggleSearchPanel()"
				></search-panel>
				<page-loading></page-loading>
				<router-outlet></router-outlet>
				<back-to-top></back-to-top>
			</div>
		</div>
		<div class="page-footer" *ngIf="!isFullScreenPage">
			<old-mobile-menu id="old-mobile-menu" *ngIf="currentUser.loggedIn() && isMobileView && !userProfileIsEnabled" (toggleSearchPanelEvent)="toggleSearchPanel()"></old-mobile-menu>
			<mobile-menu id="mobile-menu" *ngIf="currentUser.loggedIn() && isMobileView && userProfileIsEnabled" (toggleSearchPanelEvent)="toggleSearchPanel()"></mobile-menu>
		</div>
		<div *ngIf="enabledCookieConsent && showCookieConsent" class="overlay" >
			<cookie-consent-widget (dismissOverlayEvent)="dismissCookieOverlay()"></cookie-consent-widget>
		</div>
	</div>

	<router-outlet name="signon"></router-outlet>

	<google-analytics *ngIf="supportsGA"></google-analytics>

	<mix-panel *ngIf="supportsMixPanel"></mix-panel>

	<geotargetly *ngIf="supportsGeotargetly"></geotargetly>

	<fb-pixel *ngIf="supportsFBPixel"></fb-pixel>

	<heap-analytics *ngIf="supportsHeap"></heap-analytics>

	<intercom *ngIf="supportsIntercom"></intercom>

	<matomo *ngIf="supportsMatomo"></matomo>
`,
	styles: [ 
	`
    /* Styles for the overlay */
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
      z-index: 1000000002; /* Ensure it's above other content */
   
    }
  `]
} )

export class AlphaBuilderComponent extends WithDestroy() {

	@ViewChild(BuilderMenuComponent, { static: false }) builderMenu: BuilderMenuComponent;
	@ViewChild(SideMenuComponent, { static: false }) sideMenu: SideMenuComponent;


	public supportsGA: boolean;
	public supportsMixPanel: boolean;
	public supportsMatomo: boolean;
	public supportsGeotargetly: boolean;
	public supportsFBPixel: boolean;
	public supportsHeap: boolean;
	public supportsIntercom: boolean;
	public enabledCookieConsent: boolean;
	public enabledSimpleCreateCourse: boolean;
	public showCookieConsent: boolean = false;
	public userProfileIsEnabled: boolean = false;
	public simpleCourseCreateEnabled: boolean = false;
	public coraEnabled: boolean = false;
	public searchUpdateEnabled: boolean = false;
	public isMobileView: boolean;
	public showSearchPanel: boolean = false;
	public isFullScreenPage: boolean = false;
	public productTourOverviewEnabled: boolean = false;

	constructor(
		// app theme
		appTheme: AppTheme,
		// announcement service,
		public announcementService: AnnouncementsService,
		// current user
		public currentUser: CurrentUser,
		private features: FeaturesService,
		private titleStrategy : CustomTitleStrategyService,
		private loader: PageLoadingService,
		private bo: BreakpointObserver,
		public router: Router,
		private trackingService: TrackingService,
		public profileUrlsService: ProfileUrlsService
	) {
		super();
		this.supportsGA = appTheme.tracks( 'google-analytics' );
		this.supportsMixPanel = appTheme.tracks( 'mix-panel' );
		this.supportsMatomo = appTheme.tracks( 'matomo' );
		this.supportsGeotargetly = appTheme.tracks( 'geotargetly' );
		this.supportsFBPixel = appTheme.tracks( 'fb-pixel' );
		this.supportsHeap = appTheme.tracks( 'heap-analytics' );
		this.supportsIntercom = appTheme.tracks( 'intercom' );
		this.enabledCookieConsent = appTheme.supports( 'cookieConsent' );
		this.searchUpdateEnabled = this.features.isOn(FEATURE_SEARCH_UPDATE);
		this.userProfileIsEnabled = this.features.isOn(FEATURE_USER_PROFILE);

		this.features.whenOn(FEATURE_USER_PROFILE).subscribe( status => this.userProfileIsEnabled = true );
		this.features.whenOn(FEATURE_SIMPLE_COURSE_CREATE).subscribe( status => this.simpleCourseCreateEnabled = true );		
		this.features.whenOn(FEATURE_CORA).subscribe(status => this.coraEnabled = true);
		this.features.whenOn(FEATURE_PRODUCT_TOUR).subscribe((status) => (this.productTourOverviewEnabled = true));	

		this.loader.isLoading().subscribe(loading => {
			if(!loading && currentUser.loggedIn()){
					if(this.enabledCookieConsent)
					this.showCookieConsent =  localStorage.getItem( 'cookies_agreed' ) ? false : true;
					if (this.enabledCookieConsent && this.showCookieConsent) {
						document.documentElement.classList.add( 'hide-overflow' );
					}
			}
		  });

		  const lc: Observable<BreakpointState> = this.bo.observe(['(max-width:768px)']);

		  lc.pipe(takeUntil(this.destroy$)).subscribe(result => {
			this.isMobileView = result.matches
		  });

		this.trackingService.events.pipe(
			takeUntil( this.destroy$ )
		).subscribe( event => {

			if ( event instanceof PageView ) {

				const url = event.data.url;
				
				this.isFullScreenPage = 
					url.indexOf( '/alphas/new' ) !== -1 || 
					(
						url.indexOf( '/alphas/' ) !== -1 
						&& url.split("/").slice(-1)[0] === "survey"
					);

				this.profileUrlsService.setRedirectUrl(window.location.origin + window.location.pathname);
				
			} 
		} );
	}
	ngAfterViewInit() {
	}

	ngOnInit(): void {
		this.showCookieConsent = false;
		this.applyFeatures();
		if(this.userProfileIsEnabled){
			this.titleStrategy.setUseComponentTitle(true)
		}
	}

	/**
	 * Apply features here
	 */
	private applyFeatures(): void {

		// create an instance of Survicate
		AppInjector.get( Survicate );
		AppInjector.get( Tidio );
		AppInjector.get( ProductTour );
		AppInjector.get( SimpleCourseCreate );
		AppInjector.get( SearchUpdate );
		AppInjector.get( Cora );
	}

	dismissCookieOverlay() {
	 	// Function to dismiss the overlay
		document.documentElement.classList.remove( 'hide-overflow' );// Remove the class to enable scrolling
	 	this.showCookieConsent = false;
	}

	toggleSearchPanel() {

		if (this.searchUpdateEnabled) {
			const searchItem = sessionStorage.getItem("savedSearchQuery-"+this.currentUser.id) ?? ""
			if (searchItem){
				this.router.navigate( [ '/search' ], Object.assign( {}, { queryParams: { q: searchItem } } ) );
			}
			else{
				this.router.navigate( [ '/search' ])
			}
			return;
		} else {
			this.showSearchPanel = !this.showSearchPanel;
		}

	}
}
