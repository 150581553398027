/**
 * Provider for the APP_INITIALIZER
 * When included in the AppModule it will preload the announcements as part of the app bootstrap
 */

import { APP_INITIALIZER } from "@angular/core";
import { AnnouncementsService } from "./announcements.service";

export const announcementsProvider = {
	provide: APP_INITIALIZER,
	deps: [ AnnouncementsService ],
	useFactory: ( service: AnnouncementsService ) => () => service.load().subscribe(),
	multi: true
};
