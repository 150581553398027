import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { CommonModule } from '@angular/common';
import { ImagePositionModule } from '../directives/image-position/image-position.module';
import { BuilderMaterialModule } from '@builder/common/ng-material';

@NgModule( {
	imports: [
		CommonModule,
		BuilderMaterialModule,
		ImagePositionModule
	],
	declarations: [
		CardComponent
	],
	exports: [
		CardComponent,
		ImagePositionModule
	]
} )

export class CardModule { }
