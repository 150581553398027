import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';
import { CardModule } from '@builder/common/cards/card.module';
import { ImagePositionModule } from '@builder/common/directives/image-position/image-position.module';
import { BuilderSelectModule } from '@builder/common/forms/builder-select/builder-select.module';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module';

import { SearchResultsComponent, CardTypeTemplateDirective } from './search-results.component';
import { SearchFormModule } from '../form/search-form.module';
import { SearchFormUpdatedModule } from '../form-updated/search-form-updated.module';
import { PopularSearchTermsModule } from '../popular/popular-search-terms.module';
import { SearchResultsSetComponent } from './result-set/result-set.component';
import { SearchResultsSetUpdatedComponent } from './result-set-updated/result-set-updated.component';
import { SearchResultComponent } from './result-set-updated/search-result/search-result.component';
import { FilterInUseCategoriesPipe, FilterInUseProductsPipe, PostsFilterPipe, PostsFilterUpdatedPipe } from './search-results.pipes';
import { MatSelectModule } from '@angular/material/select';
import { SearchFiltersDialogComponent } from './search-filters-dialog/search-filters-dialog.component';
import { SearchFilterModule } from '../search-filter/search-filter.module';
import { LookupAlphaModule } from '../lookup-alpha/lookup-alpha.module';

const components = [
	SearchResultsComponent,
	SearchResultsSetComponent,
	SearchResultsSetUpdatedComponent,
	SearchResultComponent,
	SearchFiltersDialogComponent,
];

@NgModule( {
	imports: [
		CommonModule,
		RouterModule,
		BuilderMaterialModule,
		InfiniteScrollModule,
		CardModule,
		BuilderIconModule,
		BuilderSelectModule,
		AlphaLocaleModule,
		SearchFormModule,
		SearchFormUpdatedModule,
		PopularSearchTermsModule,
		ImagePositionModule,
		SearchFilterModule,
		CommonPipesModule,
		MatSelectModule,
		MatDialogModule,
		LookupAlphaModule,
	],
	declarations: [
		CardTypeTemplateDirective,
		PostsFilterPipe,
		PostsFilterUpdatedPipe,
		FilterInUseProductsPipe,
		FilterInUseCategoriesPipe,
		components
	],
	providers:[PostsFilterUpdatedPipe],
	exports: components,
	entryComponents: components
} )
export class SearchResultsModule { }
