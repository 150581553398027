<ab-icon *ngIf="state === SignupFormState.Complete" name="logo"
			color="red"
			size="37"></ab-icon>

<div *ngIf="state !== SignupFormState.Complete" class="register-header">
	<ab-icon name="logo"
			color="red"
			size="37"></ab-icon>

	<h3 *ngIf="state !== SignupFormState.Complete"
		>Sign Up</h3>
    <div id="menuLanguages">
		<div (click)="showLanguageMenu = ! showLanguageMenu"
			class="link"
			id="menuLanguagesText">{{ locale.code | languageInfo }}<ab-icon [class.toggle]="showLanguageMenu"
					name="chevron-down"></ab-icon>
		</div>
		<div id="menuLanguagesOptions"
			@expandDown
			*ngIf="showLanguageMenu">
			<div class="menu-language-option link"
				*ngFor="let language of languages"
				[language-link]="language.code"
				[title]="language.code">
				{{language.native_name}}
			</div>
		</div>
	</div>
</div>

<form alpha-form
	  *ngIf="state !== SignupFormState.Complete"
	  (submit)="onFormSubmit()"
	  [formGroup]="form">

	<div class="form-row">

		<label for="emailField"
			   class="required field-label text-bold"
			   >Email</label>
		<input id="emailField"
			   type="text"
			   formControlName="email" />

		<div *ngIf="$any(form.errors)?.invalidEmail"
			 class="error-message"
			 >Please enter a valid email address.</div>
	</div>

	<div class="form-row">

		<label for="firstName"
			   class="required field-label text-bold"
			   >First Name</label>
		<input id="firstName"
			   formControlName="first_name"
			   type="text" />
	</div>

	<div class="form-row">

		<label for="lastName"
			   class="required field-label text-bold"
			   >Last Name</label>
		<input id="lastName"
			   formControlName="last_name"
			   type="text" />
	</div>

	<div class="form-row">

		<label for="mobileNumber"
			   class="field-label text-bold"
			   >Mobile Number</label>
		<input id="mobileNumber"
			   formControlName="mobileNumber"
			   type="text" />
	</div>

	<div class="form-row">

		<label for="teamRole"
			   class="required field-label text-bold"
			   >Role at Alpha</label>
		<team-roles-select formControlName="role_at_alpha"></team-roles-select>

	</div>

	<password-editor formControlName="password"
					 class="bold-labels"></password-editor>

	<p *ngIf="response"
	   class="{{response.code}}-msg form-row"
	   [innerHTML]="response.message"></p>

	<div id="gdprMessage"
		 *ngIf="gdprMessage">
		<div [innerHTML]="gdprMessage"></div>
	</div>


	<div *ngIf="hasTermsAndConditionsLink"
		 id="tosRow"
		 class="form-row terms">

		<mat-checkbox formControlName="agreedToTerms"
					  class="round"></mat-checkbox>
		<span (click)="viewToS()">I agree to the <a link="terms-and-conditions">Terms and Conditions</a></span>

	</div>
	<p id="sign-up-btn">
		<button mat-flat-button
				color="primary"
				class="signup-btn"
				[disabled]="!form.valid || state === SignupFormState.Submitting">Sign Up</button>

	</p>

	<p class="user-link-container">
		<span class="text-bold">Already have an account? </span>
		<a [routerLink]="['../', 'signon']"
		   class="active">Login</a>
	</p>

</form>

<div *ngIf="state === SignupFormState.Complete">
	<p *ngIf="response"
	   class="{{response.code}}-msg form-row"
	   [innerHTML]="response.message"></p>
</div>

<mat-progress-bar mode="indeterminate"
				  color="accent"
				  *ngIf="state === SignupFormState.Submitting"></mat-progress-bar>
