import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { TrackingModule } from '@builder/tracking/tracking.module';
import { SearchFormModule, PopularSearchTermsModule } from '@builder/search';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { SideMenuComponent } from './side-menu.component';


@NgModule( {
	imports: [
		CommonModule,
		RouterModule,
		AlphaLocaleModule,
		BuilderMaterialModule,
		BuilderIconModule,
		AlphaLocaleModule,
		SearchFormModule,
		PopularSearchTermsModule,
		TrackingModule
	],
	declarations: [
        SideMenuComponent
	],
	exports: [ SideMenuComponent ],
	entryComponents: []
} )
export class SideMenuModule { }
