<ng-container *ngIf="!searchUpdateIsEnabled">
	<div id="contentHeader"
		[class.empty]="!searchTerm">
		<search-form 
			class="content-wrapper"
			#searchForm
			[searchTerm]="searchTerm"></search-form>
	</div>


	<ng-container *ngIf="searchResults$ | async as results; else searchResultsLoading">
		<mat-tab-group disableRipple
			[selectedIndex]="initialTabIndex"
			#tabGroup="matTabGroup"
			*ngIf="resultsCount">

			<!-- All Results -->
			<mat-tab>
				<ng-template mat-tab-label>
					<span i18n="common.words|All@@commonWordAll">All</span>
				</ng-template>
				
				<ng-template matTabContent>
						<section class="content-wrapper"
								*ngIf="results?.materials.posts.length">
								
								
							<h6 i18n="common.words|Series Material@@commonWordSeriesMaterial">Series Material</h6>

							<search-results-set [resultSet]="results?.materials"
												[limit]="3">

								<ng-template cardTypeTemplate>
									<div class="card-type-label"
										i18n="common.words|Series Material@@commonWordSeriesMaterial">Series Material</div>
								</ng-template>

							</search-results-set>

							<a *ngIf="results?.materials.total > 3"
							class="active see-all-link"
							i18n="search|See All Results@@seeAllResults"
							(click)="tabGroup.selectedIndex=1">See All Results</a>

						</section>

						<section class="content-wrapper"
								*ngIf="results?.promotional.posts.length">

							<h6 i18n="common.words|Promo Material@@commonWordPromoMaterial">Promo Material</h6>

							<search-results-set [resultSet]="results?.promotional"
												[limit]="3">

								<ng-template cardTypeTemplate>
									<div class="card-type-label"
										i18n="common.words|Promo Material@@commonWordPromoMaterial">Promo Material</div>
								</ng-template>

							</search-results-set>

							<a *ngIf="results?.promotional.total > 3"
							class="active see-all-link"
							i18n="search|See All Results@@seeAllResults"
							(click)="tabGroup.selectedIndex=2">See All Results</a>

						</section>


						<section class="content-wrapper"
								*ngIf="results?.learning.posts.length">

							<h6 i18n="training|Learning Centre Title@@learningCenterTitle">Learning Centre</h6>

							<search-results-set [resultSet]="results?.learning"
												disableAction
												[limit]="3">

								<ng-template cardTypeTemplate>
									<div class="card-type-label"
										i18n="training.headers|Learning Module Title@@learningModuleTitle">Learning Module</div>
								</ng-template>

							</search-results-set>

							<a *ngIf="results?.learning.total > 3"
							class="active see-all-link"
							i18n="search|See All Results@@seeAllResults"
							(click)="tabGroup.selectedIndex=3">See All Results</a>

						</section>
				
				</ng-template>

			</mat-tab>

			<!-- Series Material -->
			<mat-tab>
				<ng-template mat-tab-label>
					<span i18n="common.words|Series Material@@commonWordSeriesMaterial">Series Material</span>
				</ng-template>

				<ng-template matTabContent>

					<section class="content-wrapper">

						<div class="section-filters">

							<ng-container [ngTemplateOutlet]="seriesSelectTemplate"
										[ngTemplateOutletContext]="{posts:results?.materials.posts}"></ng-container>
							<ng-container [ngTemplateOutlet]="resourceTypeTemplate"></ng-container>

						</div>

						<search-results-set 
							[resultSet]="results?.materials"
							[filters]="{products:selectedSeries?.value, type : selectedResourceType?.value}">

							<ng-template cardTypeTemplate>
								<div class="card-type-label"
									i18n="common.words|Series Material@@commonWordSeriesMaterial">Series Material</div>
							</ng-template>

						</search-results-set>

					</section>

				</ng-template>

			</mat-tab>

			<!-- Promo Material -->
			<mat-tab>
				<ng-template mat-tab-label>
					<span i18n="common.words|Promo Material@@commonWordPromoMaterial">Promo Material</span>
				</ng-template>

				<ng-template matTabContent>

					<section class="content-wrapper">

						<div class="section-filters">

							<ng-container [ngTemplateOutlet]="seriesSelectTemplate"
										[ngTemplateOutletContext]="{posts:results?.promotional.posts}"></ng-container>
							<ng-container [ngTemplateOutlet]="resourceTypeTemplate"></ng-container>

						</div>

						<search-results-set 
							[resultSet]="results?.promotional"
							[filters]="{products:selectedSeries?.value, type : selectedResourceType?.value}">

							<ng-template cardTypeTemplate>
								<div class="card-type-label"
									i18n="common.words|Promo Material@@commonWordPromoMaterial">Promo Material</div>
							</ng-template>

						</search-results-set>

					</section>

				</ng-template>

			</mat-tab>

			<!-- Learning Center -->
			<mat-tab>
				<ng-template mat-tab-label>
					<span i18n="training|Learning Centre Title@@learningCenterTitle">Learning Centre</span>
				</ng-template>

				<ng-template matTabContent>

					<section class="content-wrapper">

						<div class="section-filters">

							<ng-container [ngTemplateOutlet]="learingCategoryTemplate"
										[ngTemplateOutletContext]="{posts:results?.learning.posts}"></ng-container>

							<ng-container [ngTemplateOutlet]="learningTypeTemplate"></ng-container>

						</div>

						<search-results-set 
							[resultSet]="results?.learning"
							[filters]="{category:selectedLearningCategory?.value, type : selectedLearningType?.value}"
							disableAction>

							<ng-template cardTypeTemplate>
								<div class="card-type-label"
									i18n="training.headers|Learning Module Title@@learningModuleTitle">Learning Module</div>
							</ng-template>

						</search-results-set>

					</section>

				</ng-template>
			</mat-tab>

		</mat-tab-group>

		<div *ngIf="!resultsCount"
			id="noResultsArea"
			class="content-wrapper">

            <h5 i18n="search|Search Results - Nothing Found@@noResultsFound">Sorry, nothing was found for your search query.</h5>
			<popular-search-terms 
				[exclude]="[searchTerm]" (selectTerm)="router.navigate(['/search'], {queryParams:{q:$event.term}})">
			</popular-search-terms>
		</div>
	</ng-container>

	<ng-template #searchResultsLoading>
		<mat-progress-bar
			mode="indeterminate"
			color="accent"></mat-progress-bar>
	</ng-template>

	<!-- Series Filter Dropdown Template-->
	<ng-template #seriesSelectTemplate
				let-posts="posts">
		<builder-select labelProperty="viewValue"
						[value]="selectedSeries"
						(changeOption)="onSeriesChange($event)"
						[options]="theme.value( 'preview_resources' ).products | filterInUseProducts : posts">

			<label><span i18n="common.words|Common Words - Series - Relating to a Product@@commonWordsSeries">Series</span>:</label>

			<ng-template builder-option-template
						let-option="option">{{option.viewValue}}</ng-template>

		</builder-select>
	</ng-template>

	<!-- Resource Type Dropdown Template-->
	<ng-template #resourceTypeTemplate>
		<builder-select labelProperty="viewValue"
						[value]="selectedResourceType"
						(changeOption)="onResourceTypeChange($event)"
						[options]="resourceTypes">
			<label><span i18n="common.words|Common Words - Type of Something@@commonWordsType">Type</span>:</label>

			<ng-template builder-option-template
						let-option="option">{{option.viewValue}}</ng-template>

		</builder-select>
	</ng-template>

	<!-- Learning Category Dropdown Template-->
	<ng-template #learingCategoryTemplate
				let-posts="posts">
		<builder-select labelProperty="viewValue"
						[value]="selectedLearningCategory"
						(changeOption)="onLearningCategoryChange($event)"
						[options]="learningCategories | filterInUseCategories : posts">
			<label><span i18n="common.words|Common Words - Category@@commonWordsCategory">Category</span>:</label>

			<ng-template builder-option-template
						let-option="option">{{option.viewValue}}</ng-template>

		</builder-select>
	</ng-template>

	<!-- Learning Type Dropdown Template-->
	<ng-template #learningTypeTemplate>
		<builder-select labelProperty="viewValue"
						[value]="selectedLearningType"
						(changeOption)="onLearningTypeChange($event)"
						[options]="learningTypes">
			<label><span i18n="common.words|Common Words - Type of Something@@commonWordsType">Type</span>:</label>

			<ng-template builder-option-template
						let-option="option">{{option.viewValue}}</ng-template>

		</builder-select>
	</ng-template>
</ng-container>

<!-- (search-update) -->
<ng-container *ngIf="searchUpdateIsEnabled">
	<search-form-updated [searchTerm]="searchTerm"></search-form-updated>
	<ng-container *ngIf="searchResults$ | async as results; else searchResultsLoading">
		<ng-container *ngIf="results.length">
			<div class="search-filter">
				<a *ngIf="!showMobileFilters"
					class="filter-link" (click)="toggleFilterSection($event)">
					<ab-icon name="filters">
						<span *ngIf="showFilter" 
							i18n="searchFilters.action|Search Filters - Hide Filters@@searchFiltersActionsHideFilters">
							Hide Filters
						</span>
						<span *ngIf="!showFilter" 
							i18n="searchFilters.action|Search Filters - Show Filters@@searchFiltersActionsShowFilters">
							Show Filters
						</span>
					</ab-icon>
				</a>
				<a *ngIf="showMobileFilters"
					class="filter-link" (click)="openDialog()">
					<ab-icon name="filters">
						<span 
							i18n="searchFiltersDialog.text|Search Filters Dialog - Filter Search Results@@searchFiltersDialogTitle">
							Filter Search Results
						</span>
					</ab-icon>
				</a>
				<p *ngIf="count" class="mya-p color-grey-60">
					<span i18n="common.words|Common Words - Showing@@commonWordsShowing">
						Showing
						{{count.current// i18n: (ph="count_current")}}
						out of 
						{{count.total// i18n: (ph="count_total")}}
						results for
						{{searchTerm// i18n: (ph="search_term")}}
					</span>
				</p>
			</div>

			<div class="search-results-columns">
				<search-filter 
					*ngIf="!showMobileFilters"
					[ngClass]="{ 'hide-filter': !showFilter }"
					[filterDefaults]="filterParams"
					(filters)="handleValueChange($event)"
					></search-filter>
				<search-results-set-updated 
					[resultSet]="results"
					[filters]="filterParams"
					(updateCountEvent)="updateResultCount($event)"
					></search-results-set-updated>
			</div>
		</ng-container>
		<ng-container *ngIf="!resultsCount">
			<p *ngIf="searchTerm"
				class="mya-h5 color-grey-50 font-weight-medium"
				i18n="noResults.msg|no results@@searchOptionsTerms">
					Sorry, we didn't find any results for "{{searchTerm}}"
			</p>
			<lookup-alpha *ngIf="isLookupAlphaVisible" (hideComponentEvent)="hideLookupAlpha()"></lookup-alpha>
			<popular-search-terms [exclude]="[searchTerm]" 
				(selectTerm)="router.navigate(['/search'], {queryParams:{q:$event.term}})">
			</popular-search-terms>
		</ng-container>
	</ng-container>

	<ng-template #searchResultsLoading>
		<mat-progress-bar
			class="search-progress-bar"
			mode="indeterminate"
			color="accent"></mat-progress-bar>
	</ng-template>
</ng-container>
