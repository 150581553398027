import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { forkJoin, from, interval, lastValueFrom, Observable, Observer, of, Subscription } from 'rxjs';
import { map, catchError, tap, shareReplay, switchMap, distinctUntilChanged, skip, filter } from 'rxjs/operators';
import { UserManager, User as OIDCUser, UserManagerSettings, Log, WebStorageStateStore } from 'oidc-client';

import { environment } from '../environments/environment';

import { clone } from '@builder/common';
import { TrackingService } from '@builder/tracking/tracking-service';
import { TrackingEventName } from '@builder/tracking/tracking-events';
import { AuthHttp } from '../http/restHttp';
import { CurrentUser, User } from './user';
import { SESSION_STORAGE_KEY_PRODUCT_SLUG } from '@builder/alphas/create/create-alpha.resolver';

import { Organization } from '../organizations/organization';

import { ProfileStorageProvider } from './local-profile.provider';
import { ISignonOptions } from '@builder/common/models/signon-options';
import { Locale } from '@builder/common/lang/locale';
import { PageLoadingService } from '@builder/common/page-loading/page-loading.service';
import { FeaturesService } from '@builder/common/features/features.service';
import { UrlRegistrationService } from '@builder/common/url-registration/url-registration.service';
import { UserPreferences } from './preferences';
import { FEATURE_USER_PROFILE } from '@builder/common/features/feature-flag';
import { Insights } from '@builder/common/insights/insights.service';


let LOGIN_REDIRECT: string = null;
const setLoginRedirect = value => {
	LOGIN_REDIRECT = value;
};
export { LOGIN_REDIRECT, setLoginRedirect };

@Injectable()
export class UserService implements CanActivate {

	private _storageExceededErrorName = 'QuotaExceededError'; // DOMException 'name' when localStorage is full when trying to set
	private _loginURI = '/user/signon';
	private _endpoint = '/wp-json/wp/v2/user';

	private _userManager: UserManager;

	private verified: boolean;
	private verifyObserver;
	private userExpirySubscription:Subscription;

	constructor(
		private _http: HttpClient,
		private _authHttp: AuthHttp,
		private _currentUser: CurrentUser,
		private _router: Router,
		private profileProvider: ProfileStorageProvider,
		private userPreferences: UserPreferences,
		private trackingService: TrackingService,
		private locale:Locale,
		private loader:PageLoadingService,
		private features:FeaturesService,
		private urlRegistrationService: UrlRegistrationService,
		private insights: Insights
	) {

		this.monitorUserExpiration();

	}

	public get authEndpoint():string{
		if ( this.features.isOn( 'sso_enabled' ) ) {
			return '/wp-json/wp/v2/sso';
		}
		return '/wp-json/jwt-auth/v1';
	}

	public get userManager():UserManager {
		if ( ! this._userManager ) {
			this._userManager = new UserManager( this.idpSettings );
		}
		return this._userManager;
	}

	/**
	 * This will check the state of the current user every second until the user changes from logged in to not logged in.
	 * Then the subscribe method is called
	 */
	private monitorUserExpiration(): void {
		this.userExpirySubscription = interval( 1000 ).pipe(
			map( c => this._currentUser.loggedIn() ),
			distinctUntilChanged(),
			skip( 1 ),
			filter( loggedIn => !loggedIn ),
			tap( loggedIn => document.location.href = this.idpSettings.post_logout_redirect_uri )
			
		).subscribe();		
	}

	private get idpSettings(): UserManagerSettings {

		let uiLocale:string = this.locale.code.replace( '_', '-' );
		
		// if there's a mapping for myAlpha -> IDP codes, apply it
		if ( this.locale.idpMappings[ this.locale.code ] ) {
			uiLocale = this.locale.idpMappings[ this.locale.code ];
		}
		
		return {
			//monitorSession: true,
			automaticSilentRenew: false,
			silentRequestTimeout: 15000,
			authority: environment.IDP.url,
			client_id: environment.IDP.client_id,
			client_secret: environment.IDP.client_secret,
			redirect_uri: `${ document.location.origin }${this._loginURI}`,
			post_logout_redirect_uri: `${ document.location.origin }${this._loginURI}`,
			scope: "openid profile",
			response_type: "code",
			ui_locales: uiLocale,
			userStore: new WebStorageStateStore( { store: localStorage } )
		};
	}

	/**
	 *
	 */
	checkToken( state: RouterStateSnapshot ): Observable<any> {

		if ( !this.verifyObserver ) {

			this.verifyObserver = new Observable( ( observer: Observer<boolean> ) => {

				if ( this.verified ) {
					// already verified this session
					observer.next( true );
					observer.complete();
					return;
				}

				// get the
				if ( this.profileProvider.getToken() ) {

					this.verifyToken()
						.subscribe(
							success => {
								this.verified = true;

								observer.next( true );
								observer.complete();
							},
							error => {
								// token is not valid, clear it

								this.logout().subscribe();
								observer.next( false );
								observer.complete();

								// store where we were, and go to login page
								LOGIN_REDIRECT = state.url;

								// this._router.navigate( [ '/user/signon' ] );

							}
						);
				} else {
					this.verified = true;
					observer.next( true );
					observer.complete();
				}

			} ).pipe( shareReplay() );
		}
		return this.verifyObserver;
	}


	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): any {
		const url: string = state.url;

		if ( url.indexOf( '/(signon:' ) === 0 || ( this.verified && !this.profileProvider.expired() ) ) {
			return true;
		}

		return this.userLoggedIn( state ).pipe(

			map( loggedIn => {

				if ( loggedIn ) {

					// after login, set the requested product, pass along url params to reg page
					const params = this.urlRegistrationService.getParams();
					if ( params.product ) {
						sessionStorage.setItem( SESSION_STORAGE_KEY_PRODUCT_SLUG, params.product );
						return this._router.navigate( [ '/alphas/new' ], { state: { productContext: params } } );
					}
					
					const firstPath = url.split("/").filter(Boolean).at(0);
					if ( this.features.isOff( FEATURE_USER_PROFILE ) ) {
						if ( firstPath == "profile" || url.includes("course-settings")) {
							// We rename the /user path to /profile to match Profile UI
							return this._router.navigate( [ this._loginURI ], { state: { redirect: url } } );
						}
					} else {
						if ( firstPath == "user" ) {
							// We rename the /user path to /profile to match Profile UI
							return this._router.navigate( [ this._loginURI ], { state: { redirect: url } } );
						}
					}
				
					// set verified so we don't have to validate the first guarded request
					this.verified = true;
					return true;
				} else {
					this.verified = false;
					this.profileProvider.clear();
					return this._router.navigate( [ this._loginURI ], { state: { redirect: url } } );
				}

			} ),
			catchError( err => {
				this.verified = false;
				this.profileProvider.clear();
				return this._router.navigate( [ this._loginURI ] );
			} )
		);


	}

	/**
	 * If current user is logged in ( team member counts )
	 * @param state
	 */
	userLoggedIn( state: RouterStateSnapshot ): Observable<any> {
		const tokenExpired: boolean = this.profileProvider.expired();
		if ( tokenExpired ) {
			return of( false );
		}
		return this.checkToken( state );

	}

	/**
	* Check if this User exists by email
	*/
	public userExists( email: string ): Observable<any> {

		return new Observable( ( observer: Observer<any> ) => {

			const body = JSON.stringify( { email } );
			return this._http.post( this._endpoint + '/exists', body )
				.subscribe(
					response => {
						observer.next( response );
						observer.complete();
					},
					errorResponse => {
						errorResponse.error = JSON.parse( errorResponse.error );
						observer.error( errorResponse );
						// observer.complete();
					} );
		} );
	}

	/**
	 *
	 */
	public login( useremail: string, password: string, rememberMe: boolean, signonOptions: ISignonOptions ): Observable<any> {
		const body = JSON.stringify( { user_email: useremail, password, options: signonOptions } );
		return this._http.post<any>( this.authEndpoint + '/token', body ).pipe(
			map( response => {
				return this.handleLogin( response, rememberMe );
			} ),
			catchError( err => {
				this.trackingService.trigger( TrackingEventName.UserLoginFailed, { useremail } );
				throw err;
			} )
		);
	}

	/**
	 * Initiate the signin redirect
	 * Clear stale states before hand
	 * If local storage exceeded, clear and refresh
	 */
	public ssoLogin() {

		let state:any = {};

		// check for url params and set them on the idp state
		if ( this.urlRegistrationService.hasParams() ) {
			state.productContext = Object.fromEntries( new URLSearchParams( this.urlRegistrationService.getParams() ) );
		}
		
		return lastValueFrom(
			from( this.userManager.clearStaleState() ).pipe(
				switchMap( s => this.userManager.signinRedirect( { state } ) ),
				catchError( err => {
					if ( err.name === this._storageExceededErrorName ) {
						localStorage.clear();
						document.location.href = this._loginURI;
					}
					throw err;
				})				
			)
		);
	}

	/**
	 * Finish the login redirect process
	 * Clear stale states before hand
	 * If local storage exceeded, clear and refresh
	 */
	public ssoFinishLogin(): Promise<any> {

		return lastValueFrom(
			from( this.userManager.clearStaleState() ).pipe(
				switchMap( s => this.userManager.signinRedirectCallback() ),
				catchError( err => {
					if ( err.name === this._storageExceededErrorName ) {
						localStorage.clear();
						document.location.href = this._loginURI;
					}
					throw err;
				})				
			)
		);		
	}

	public externalLogin( request: any, rememberMe: boolean, signonOptions: ISignonOptions = {} ): Observable<any> {
		const body = JSON.stringify( { user_email: request.profile.email, options: signonOptions } );
		return this._http.post<any>( this.authEndpoint + '/token/callback', body, { headers: { 'Authorization': ( 'Bearer ' + request.access_token ), 'Content-Type': 'application/json' } } ).pipe(
			map( response => {
				return this.handleLogin( response, rememberMe );
			} ),
			catchError( err => {
				this.trackingService.trigger( TrackingEventName.UserLoginFailed, { useremail: request.profile.email } );
				throw err;
			} )
		);
	}

	private handleLogin( res: any, rememberMe: boolean ): CurrentUser {
		const organization = new Organization( res.organization || {} );
		if ( res.organization ) {
			res.profile.organization = organization;
		}

		if ( rememberMe ) {

			this.profileProvider.rememberMe();

		} else {

			this.profileProvider.rememberSession();
		}

		this.profileProvider.set( res.token, res.profile );

		if ( this._currentUser ) {
			this._currentUser.setData( res.profile );
			this._currentUser.organization = organization;
			this.userPreferences.initFromUser( this._currentUser );
		}

		// set verified so we don't have to validate the first guarded request
		this.verified = true;

		// trigger log in event
		this.trackingService.trigger( TrackingEventName.UserLogin, { user: this._currentUser } );

		// sanity check. If the current user is missing an 'id' property, something has gone wrong
		if ( this._currentUser && ! this._currentUser.id ) {
			this.insights.trackProfileException(this._currentUser);
			this.logout();
		}

		return this._currentUser;
	}

	public verifyToken(): Observable<any> {

		// sanity check. If the current user is missing an 'id' property, something has gone wrong
		if ( this._currentUser && ! this._currentUser.id ) {
			this.insights.trackProfileException(this._currentUser);
			throw "Invalid";
		}

		return this._authHttp.post( this.authEndpoint + '/token/validate', '' ).pipe(
			tap( res => this.trackingService.trigger( TrackingEventName.TokenValidated, { user: this._currentUser } ) )
		);
	}

	/**
	 *
	 */
	public logout(): Observable<any> {

		if ( this.loader.show() ) {
			this.loader.getLoader().style.paddingLeft = '0';
		}

		this.userExpirySubscription.unsubscribe();

		let request = this._authHttp.post<CurrentUser>( this._endpoint + '/logout', {} ).pipe(
			tap( response => {
				this.profileProvider.clear();

				this.verified = false;

				this.trackingService.trigger( TrackingEventName.UserLogout, { user: this._currentUser } );

			} )
		);

		// if sso oidc then pipe the request to go through the signout redirect
		if ( this.features.isOn( 'sso_enabled' ) ) {

			request = request.pipe(
				switchMap( response => this.userManager.getUser() ),
				switchMap( user => {
					if ( user ) {
						return this.userManager.signoutRedirect( { 'id_token_hint': user.id_token, extraQueryParams: { ui_locales: this.locale.code } } ) 
					}
					return null;
				} ),
				catchError( err => {
					document.location.href = this._loginURI;
					return of( err );
				} )
			);
		}
		// otherwise pipe request to navigate back to home which will take us back to signin
		else {
			request = request.pipe(
				switchMap( response => this._router.navigateByUrl( '/?loggedout=1' ) )
			);
		}

		return request;
	}

	public ssoSilentLogout() {
		
		return forkJoin( [
			this.userManager.getUser(),
			this.userManager.removeUser()
		]).pipe(
			switchMap( responses => this.userManager.createSignoutRequest({ id_token_hint: responses[0].id_token, post_logout_redirect_uri: 'null' } ) ),
			tap( request => {
				const iframe = document.createElement('iframe');
				iframe.src = request.url;
				document.body.appendChild(iframe);
			})
		);
	}

	/**
	 *
	 */
	public saveProfile( data ): Observable<any> {

		data.id = this._currentUser.id;

		const orgChanging =
			// there was previously an organization but now there's not
			( !data.organization && this._currentUser.organization ) ||
				// the organization is new or the id has changed
				( !data.organization.id || ( !this._currentUser.organization || data.organization.id !== this._currentUser.organization.id ) ) ? clone( this._currentUser.organization ) : null;

		return this._authHttp.post<CurrentUser>( this._endpoint + '/update', data ).pipe(
			tap( response => {

				this._currentUser.setData( response );

				this.profileProvider.update( response );

				// trigger UserSave event
				this.trackingService.trigger( TrackingEventName.UserSave, { user: this._currentUser } );

				if ( orgChanging ) {
					this.trackingService.trigger( TrackingEventName.ChangeUserOrganization, {
						user: this._currentUser,
						previous: orgChanging,
						current: clone( this._currentUser.organization )
					} );
				}

			} )
		);
	}

	public delete( user: User ): Observable<any> {
		return this._authHttp.delete( this._endpoint + '/' + user.id ).pipe(
			tap( () => this.trackingService.trigger( TrackingEventName.DeletedUser, { user } ) )
		);
	}

	/**
	 * Sign up a user for a WP account
	 */
	public requestAccount( userData: any ): Observable<any> {
		const body = JSON.stringify( { user: userData } );
		return this._http.post( this._endpoint + '/account/request', body ).pipe(
			tap( () => this.trackingService.trigger( TrackingEventName.CreateAccountRequest, { userData } ) )
		);
	}

	/**
	 * Finish the verification process by supplying the code, and the user object
	 * with the password field set.
	 */
	public completeVerification( code: string ): Observable<any> {
		return this._http.post<{ data: any, responseType; }>( this._endpoint + '/account/verify', { code } ).pipe(
			tap( result => this.trackingService.trigger( TrackingEventName.CreateAccountRequestVerified, { user: new User( result.data ) } ) )
		);
	}

	/**
	 * Initiate a password recovery for a user
	 */
	public initPwdRecovery( email: string ): Observable<any> {
		return this._http.post( this._endpoint + '/recoverpwd/init', JSON.stringify( { email } ) ).pipe(
			tap( response => this.trackingService.trigger( TrackingEventName.UserPasswordResetRequest, { useremail: email } ) )
		);
	}

	/**
	 * Verify a password reset key for a user
	 */
	public verifyPwdRecoveryKey( key: string, login: string ): Observable<any> {
		return this._http.get( this._endpoint + '/recoverpwd/verify?key=' + key + '&login=' + login );
	}

	/**
	 * Choose a password to complete a password recovery
	 */
	public chooseRecoveryPassword( key: string, login: string, password: string, extra: any = {} ): Observable<any> {

		const data = Object.assign( extra, { key, login, password } );

		return this._http.post<{ data: any, responseType; }>( this._endpoint + '/recoverpwd/set', data ).pipe(
			tap( result => this.trackingService.trigger( TrackingEventName.UserPasswordReset, { user: new User( result.data ) } ) )
		);
	}

	/**
	 * Reset the password for the currently logged in user.
	 */
	public resetPassword( password: string, oldPassword: string, ): Observable<any> {
		return this._authHttp.post( this._endpoint + '/resetpwd', JSON.stringify( { password, oldPassword } ) );
	}

	/**
	 * Get the profile data for a user
	 */
	public getProfile( userId: number ): Observable<any> {
		return this._authHttp.get( this._endpoint + '/profile/' + userId );
	}
}
