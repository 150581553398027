import { NgModule } from '@angular/core';
import { TrackEventDirective } from './tracking.directive';

@NgModule( {
	declarations: [
		TrackEventDirective
	],
	exports: [
		TrackEventDirective
	]
} )
export class TrackingModule { }
