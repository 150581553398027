import { NgModule } from '@angular/core';
import { TrainingBackLinkDirective } from './training-back-link';

@NgModule( {
	imports: [

	],
	declarations: [
		TrainingBackLinkDirective
	],
	exports: [
		TrainingBackLinkDirective
	]
} )
export class TrainingDirectivesModule { }
