import { Resource } from '@plugin/common/models/resource';
import { Product } from '@plugin/common/models/product';
import { Alpha } from '@builder/alphas/alpha';
import { IOrganization } from '@builder/organizations/organization';
import { User } from '@builder/users/user';
import { NotificationSettings } from '@builder/users/notifications/notificationSettings';
import { Lesson } from '@builder/training/lessons/lesson-class';
import { Playlist } from '@builder/training/playlists/playlist-class';

/**
 * Common Event data interfaces
 */
interface IPostReference { id: number; blog_id: number; title: string; }
interface IPromoteCategory { label: string; name: string; items: any[]; }
interface IProgressData { seconds: number; percent: number; duration: number; }
interface IVideoData { id: number; blog_id: number; url: string; title: string; }

/**
 * Base Tracking Event
 */
export class TrackingEvent {
	constructor(
		public data?: any
	) { }
}

/**
 * All the Events
 */
export class AlphaEvent extends TrackingEvent {
	public data: { alpha: Alpha };
}

export class LibrarySearch extends TrackingEvent {
	public data: { [ key: string ]: string };
}

export class TitleChange extends TrackingEvent {
	public data: { title: string, previous: { title: string } };
}

export class PageView extends TrackingEvent {
	public data: { url: string, title: string, previous: { url: string, title: string, path: string } };
}

export class ClickCreateAlpha extends TrackingEvent { }
export class CreatedAlpha extends TrackingEvent {
	public data: { alpha: Alpha, questions: { slug: string, answer: string }[] };
}
export class UpdatedAlpha extends TrackingEvent {
	public data: { alpha: Alpha, dateChanged: boolean };
}
export class PublishedAlpha extends TrackingEvent {
	public data: { alpha: Alpha };
}
export class UnpublishedAlpha extends TrackingEvent {
	public data: { alpha: Alpha };
}
export class DeletedAlpha extends AlphaEvent {
	public data: { alpha: Alpha };
}

export class ChangeAlphaMaterial extends TrackingEvent {
	public data: { alpha: Alpha, previous: IPostReference, current: IPostReference };
}
export class ChangeAlphaOrganization extends TrackingEvent {
	public data: { alpha: Alpha, previous: IOrganization, current: IOrganization };
}
export class InvitedTeamMember extends TrackingEvent {
	public data: { alpha: Alpha, emails: string[], team: { pending: number, accepted: number } };
}
export class RemovedTeamMember extends TrackingEvent {
	public data: { alpha: Alpha, emails: string[], team: { pending: number, accepted: number } };
}
export class ResentTeamInvitation extends TrackingEvent {
	public data: { alpha: Alpha, emails: string[] };
}
export class AcceptedTeamInvitation extends TrackingEvent {
	public data: { alpha: Alpha, user: User };
}

export class UpdatedAlphaSchedule extends TrackingEvent {
	public data: { alpha: Alpha, source: string, previous, current };
}
export class ResetAlphaSchedule extends TrackingEvent {
	public data: { alpha: Alpha };
}

export class CreateAccountRequest extends TrackingEvent {
	public data: { userData: any };
}
export class CreateAccountRequestVerified extends TrackingEvent {
	public data: { user: User };
}
export class UserLogin extends TrackingEvent {
	public data: { user: User };
}
export class UserLoginFailed extends TrackingEvent {
	public data: { useremail: string };
}
export class UserLogout extends TrackingEvent { }
export class UserPasswordResetRequest extends TrackingEvent {
	public data: { useremail: string };
}
export class UserPasswordReset extends TrackingEvent {
	public data: { user: User };
}
export class UserSave extends TrackingEvent {
	public data: { user: User };
}

export class DeletedUser extends TrackingEvent {
	public data: { user: User };
}

export class TokenValidated extends TrackingEvent {
	public data: { user: User };
}

export class UpdateUserPreferences extends TrackingEvent {
	public data: { user: User, previous: { notifications: NotificationSettings }, current: { notifications: NotificationSettings } };
}
export class ChangeUserOrganization extends TrackingEvent {
	public data: { user: User, previous: IOrganization, current: IOrganization };
}

export class PromoteCategoryChange extends TrackingEvent {
	public data: { previous: IPromoteCategory, current: IPromoteCategory };
}
export class DownloadVideo extends TrackingEvent {
	public data: { resolution, episodeName };
}
export class DownloadPromoMaterial extends TrackingEvent {
	public data: { resource: Resource, alpha: Alpha };
}
export class PreviewPromoMaterial extends TrackingEvent {
	public data: { resource: Resource, alpha: Alpha };
}
export class DownloadSeriesMaterial extends TrackingEvent {
	public data: { resource: Resource, alpha: Alpha };
}

export class WatchedTrainingVideo extends TrackingEvent {
	public data: { lesson: Lesson, playlist: Playlist };
}
export class ReadTrainingArticle extends TrackingEvent {
	public data: { lesson: Lesson, playlist: Playlist };
}

export class PlayEpisodePreviewVideo extends TrackingEvent {
	public data: { progress: IProgressData, product: Product, video: IVideoData };
}
export class FinishEpisodePreviewVideo extends TrackingEvent {
	public data: { product: Product, video: IVideoData };
}

export class PlayProductTeaserVideo extends TrackingEvent {
	public data: { progress: IProgressData, product: Product };
}
export class FinishProductTeaserVideo extends TrackingEvent {
	public data: { product: Product };
}

export class FormTracking extends TrackingEvent {
	public data: { formElement: HTMLElement };
}

export class FormConversion extends TrackingEvent {
	public data: { formElement: HTMLElement };
}

export class FormSubmit extends TrackingEvent {
	public data: { formElement: HTMLElement };
}


export class ProductTourStarted extends TrackingEvent {
	public data: { user: User };
}

export class ProductTourCompleted extends TrackingEvent {
	public data: { user: User };
}
/**
 * Enum of Event Tracking names for reference
 */
export enum TrackingEventName {

	LibrarySearch = 'search-library',
	PageView = 'page-view',
	TitleChange = 'title-change',

	ClickCreateAlpha = 'create-alpha-click',
	CreatedAlpha = 'created-alpha',
	UpdatedAlpha = 'updated-alpha',
	DeletedAlpha = 'deleted-alpha',
	PublishedAlpha = 'published-alpha',
	UnpublishedAlpha = 'unpublished-alpha',
	ChangeAlphaMaterial = 'change-alpha-material',
	ChangeAlphaOrganization = 'change-alpha-organization',
	InvitedTeamMember = 'invited-team-member',
	RemovedTeamMember = 'removed-team-member',
	ResentTeamInvitation = 'resent-team-invitation',
	AcceptedTeamInvitation = 'accepted-team-invitation',

	UpdatedAlphaSchedule = 'updated-alpha-schedule',
	ResetAlphaSchedule = 'reset-alpha-schedule',

	PromoteCategoryChange = 'promote-category-change',
	DownloadVideo = 'download-video',
	DownloadPromoMaterial = 'download-promo-material',
	PreviewPromoMaterial = 'preview-promo-material',
	DownloadSeriesMaterial = 'download-series-material',

	UserLogin = 'user-login',
	UserLoginFailed = 'user-login-failed',
	UserLogout = 'user-logout',
	UserPasswordResetRequest = 'user-password-reset-request',
	UserPasswordReset = 'user-password-reset',
	TokenValidated = 'token-validated',
	CreateAccountRequest = 'create-account-request',
	CreateAccountRequestVerified = 'create-account-request-verified',
	UserSave = 'user-save',
	DeletedUser = 'user-deleted',
	UpdateUserPreferences = 'update-user-preferences',
	ChangeUserOrganization = 'change-user-organization',

	WatchedTrainingVideo = 'watched-training-video',
	ReadTrainingArticle = 'read-training-article',

	PlayProductTeaserVideo = 'play-product-teaser-video',
	FinishProductTeaserVideo = 'finish-product-teaser-video',

	PlayEpisodePreviewVideo = 'play-episode-preview-video',
	FinishEpisodePreviewVideo = 'finish-episode-preview-video',
	FormTracking = 'form-tracking',
	FormConversion = 'form-conversion',
	FormSubmit = 'form-submit',
	ProductTourStarted = 'product-tour-started',
	ProductTourCompleted = 'product-tour-completed',

}

/**
 * This is the mapping of event slugs to event classes
 */
export const EVENTS_MAP = new Map<TrackingEventName, new( data ) => TrackingEvent>( [

	/**
	 * Alpha Events
	 */
	[ TrackingEventName.ClickCreateAlpha, ClickCreateAlpha ],
	[ TrackingEventName.CreatedAlpha, CreatedAlpha ],
	[ TrackingEventName.UpdatedAlpha, UpdatedAlpha ],
	[ TrackingEventName.DeletedAlpha, DeletedAlpha ],
	[ TrackingEventName.PublishedAlpha, PublishedAlpha ],
	[ TrackingEventName.UnpublishedAlpha, UnpublishedAlpha ],
	[ TrackingEventName.PromoteCategoryChange, PromoteCategoryChange ],
	[ TrackingEventName.ChangeAlphaMaterial, ChangeAlphaMaterial ],
	[ TrackingEventName.ChangeAlphaOrganization, ChangeAlphaOrganization ],
	[ TrackingEventName.InvitedTeamMember, InvitedTeamMember ],
	[ TrackingEventName.RemovedTeamMember, RemovedTeamMember ],
	[ TrackingEventName.ResentTeamInvitation, ResentTeamInvitation ],
	[ TrackingEventName.AcceptedTeamInvitation, AcceptedTeamInvitation ],

	[ TrackingEventName.UpdatedAlphaSchedule, UpdatedAlphaSchedule ],
	[ TrackingEventName.ResetAlphaSchedule, ResetAlphaSchedule ],
	[ TrackingEventName.DownloadVideo, DownloadVideo ],
	[ TrackingEventName.DownloadPromoMaterial, DownloadPromoMaterial ],
	[ TrackingEventName.PreviewPromoMaterial, PreviewPromoMaterial ],
	[ TrackingEventName.DownloadSeriesMaterial, DownloadSeriesMaterial ],



	/**
	 * User Events
	 */
	[ TrackingEventName.CreateAccountRequest, CreateAccountRequest ],
	[ TrackingEventName.UserLogin, UserLogin ],
	[ TrackingEventName.UserLoginFailed, UserLoginFailed ],
	[ TrackingEventName.UserLogout, UserLogout ],
	[ TrackingEventName.UserPasswordResetRequest, UserPasswordResetRequest ],
	[ TrackingEventName.UserPasswordReset, UserPasswordReset ],
	[ TrackingEventName.UserSave, UserSave ],
	[ TrackingEventName.DeletedUser, DeletedUser ],
	[ TrackingEventName.TokenValidated, TokenValidated ],
	[ TrackingEventName.CreateAccountRequestVerified, CreateAccountRequestVerified ],
	[ TrackingEventName.UpdateUserPreferences, UpdateUserPreferences ],
	[ TrackingEventName.ChangeUserOrganization, ChangeUserOrganization ],

	[ TrackingEventName.FormTracking, FormTracking ],
	[ TrackingEventName.FormConversion, FormConversion ],
	[ TrackingEventName.FormSubmit, FormSubmit ],
	[ TrackingEventName.ProductTourStarted, ProductTourStarted ],
	[ TrackingEventName.ProductTourCompleted, ProductTourCompleted ],
	/**
	 * Product Events
	 */

	[ TrackingEventName.PlayEpisodePreviewVideo, PlayEpisodePreviewVideo ],
	[ TrackingEventName.FinishEpisodePreviewVideo, FinishEpisodePreviewVideo ],

	[ TrackingEventName.PlayProductTeaserVideo, PlayProductTeaserVideo ],
	[ TrackingEventName.FinishProductTeaserVideo, FinishProductTeaserVideo ],

	/**
	 * Learning Center
	 */
	[ TrackingEventName.WatchedTrainingVideo, WatchedTrainingVideo ],
	[ TrackingEventName.ReadTrainingArticle, ReadTrainingArticle ],


	/**
	 * Standard EVents
	 */
	[ TrackingEventName.PageView, PageView ],
	[ TrackingEventName.TitleChange, TitleChange ],
	[ TrackingEventName.LibrarySearch, LibrarySearch ],

] );
