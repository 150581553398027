import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { TrackingModule } from '@builder/tracking/tracking.module';
import { SearchFormModule, PopularSearchTermsModule } from '@builder/search';
import { BuilderIconModule } from '../icons/ab-icon.module';
import { AlphaLocaleModule } from '../lang/locale.module';

import { BuilderMenuComponent } from './builder-menu.component';

@NgModule( {
	imports: [
		CommonModule,
		RouterModule,
		AlphaLocaleModule,
		BuilderMaterialModule,
		BuilderIconModule,
		AlphaLocaleModule,
		SearchFormModule,
		PopularSearchTermsModule,
		TrackingModule,
	],
	declarations: [
		BuilderMenuComponent
	],
	exports: [ BuilderMenuComponent ],
	entryComponents: []
} )
export class BuilderMenuModule { }
