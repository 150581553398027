import { Component, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { CurrentUser } from '@builder/users';
import { AppTheme } from '../util/themeSupports';
import { Locale, ILanguageOption } from '../lang/locale';
import { UserService } from '@builder/users/service';
import { expandDown } from '../animations';
import { TrackingService, PageView, UserLogin, UserSave } from '@builder/tracking';
import { WithDestroy } from '../mixins/with-destroy';
import { FeaturesService } from '@builder/common/features/features.service';
import { CookieService } from '../cookies/cookies.service';
import { FEATURE_SIMPLE_COURSE_CREATE } from '../features/feature-flag';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		expandDown( 'expandDown' )
	]
})
export class MobileMenuComponent extends WithDestroy() implements OnDestroy {
	@Output() toggleSearchPanelEvent: EventEmitter<any> = new EventEmitter();
	@HostBinding( 'hidden' ) get isHidden(): boolean {
		return this.menuHidden;
	}

	public showLanguageMenu: boolean;
	public menuVisible: boolean;
	private menuHidden: boolean; // some views may request that the entire nav be hidden

	public languages: ILanguageOption[];

	public additionalMenuItems: { title: string; icon: string; slug: string; }[];

	public simpleCreateCourseIsEnabled: boolean = false;

	public versionAndYear: string = "";
	public coraEnabled :boolean = false;

	constructor(
		public theme: AppTheme,
		private cd: ChangeDetectorRef,
		public currentUser: CurrentUser,
		public router: Router,
		private userService: UserService,
		public locale: Locale,
		private trackingService: TrackingService,
		private features: FeaturesService,
		private cookies: CookieService,
	) {
		super();
		
		this.trackingService.events.pipe(
			takeUntil( this.destroy$ )
		).subscribe( event => {

			if ( event instanceof PageView ) {

				this.cd.markForCheck();

			} else if ( event instanceof UserLogin ) {

				this.cd.detectChanges();

			} else if ( event instanceof UserSave ) {

				this.cd.detectChanges();

			}
		} );

		this.languages = locale.languages.filter( l => l.public === true && l.code !== locale.code );
		this.additionalMenuItems = theme.value( 'additional_menu_items' );
		this.simpleCreateCourseIsEnabled = this.features.isOn( FEATURE_SIMPLE_COURSE_CREATE );
		this.versionAndYear = this.theme.value( 'version' ) + " " + (new Date()).getFullYear();
		this.coraEnabled = features.isOn('cora');
	}

	public doLogout() {
		this.cookies.deleteAllWithName('dismissed_user_modal','/')
		sessionStorage.clear();
		setTimeout(() => {
			this.userService.logout().subscribe();
		}, 2000);
	}

	public toggleSearch(): void {
		this.toggleSearchPanelEvent.emit();
		this.toggleMobileMenu();
		return;
	}
	
	public toggleMobileMenu(): void {
		this.menuVisible = !this.menuVisible;

		if (this.menuVisible) {
			document.getElementById("bodyWrapper").classList.add("overlay");
		} else {
			document.getElementById("bodyWrapper").classList.remove("overlay");
		}
	}

	ngOnDestroy(): void {
		this.menuVisible = false;
		document.getElementById("bodyWrapper").classList.remove("overlay");
	}
}

