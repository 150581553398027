import { Injectable } from '@angular/core';
import { ɵparseCookieValue } from '@angular/common';
import { WEEK_IN_MILLISECONDS } from '@builder/common/util/date';

import { JwtHelper } from '@builder/http/jwtHelper';
import { Insights } from '@builder/common/insights/insights.service';

export const TOKEN_PROPERTY = 'token';
export const PROFILE_PROPERTY = 'profile';
export const REMEMBER_ME_COOKIE = 'remember_me';

export enum StorageTypes {
	Session,
	Persistent
}

const jwtHelper = new JwtHelper();

@Injectable()
export class ProfileStorageProvider {

	private type: StorageTypes;
	private twoFAData: TwoFAData;

	constructor(
		private insights: Insights
	) {

		const isRemember = parseInt( ɵparseCookieValue( document.cookie, REMEMBER_ME_COOKIE ), 10 );

		if ( !isRemember ) {

			this.type = StorageTypes.Session;

			if ( this.getProfile() ) {
				// we aren't supposed to remember the profile, but it's here so lets clear it
				this.clear();
			}

		} else {

			this.type = StorageTypes.Persistent;

		}

		if ( this.getToken() && this.expired() ) {
			localStorage.removeItem( TOKEN_PROPERTY );
			localStorage.removeItem( PROFILE_PROPERTY );
		}

	}


	/**
	 * Checks for presence of token and that token hasn't expired.
	 * For use with the @CanActivate router decorator and NgIf
	 */
	public expired(): boolean {
		// check that the profile object is valid too
		const profile = this.getProfile();
		if ( ! profile || ! profile.id ) {
			return true;
		}
		const token: string = this.getToken();
		return token === null || jwtHelper.isTokenExpired( token );
	}

	/**
	 * Clear profile & token
	 */
	public clear() {
		localStorage.removeItem( TOKEN_PROPERTY );
		localStorage.removeItem( PROFILE_PROPERTY );
		this.forgetMe();
	}

	/**
	 * Update profile data
	 */
	public update( profileData: any ): void {
		if (!profileData?.id) {
			this.insights.trackProfileException(profileData);
		}

		localStorage.setItem( PROFILE_PROPERTY, JSON.stringify( profileData ) );

	}

	/**
	 *
	 */
	public getProfile() {
		const profileString: string = localStorage.getItem( PROFILE_PROPERTY );
		if ( profileString ) {
			return JSON.parse( profileString );
		}
		return null;

	}


	public getToken(): string {
		return this.getProfile() ? localStorage.getItem( TOKEN_PROPERTY ) || null : null;
	}

	/**
	 * Set remember me cookie
	 *
	 */
	public rememberMe(): void {
		const cookieString: string = encodeURIComponent( REMEMBER_ME_COOKIE ) + '=1;path=/;expires=' + ( new Date( new Date().getTime() + WEEK_IN_MILLISECONDS ) );
		document.cookie = cookieString;
	}

	/**
	 * Set the cookie, but only for this session
	 */
	public rememberSession(): void {
		const cookieString: string = encodeURIComponent( REMEMBER_ME_COOKIE ) + '=1;path=/;expires=';
		document.cookie = cookieString;
	}

	/**
	 * Clear cookie
	 */
	public forgetMe(): void {
		document.cookie = encodeURIComponent( REMEMBER_ME_COOKIE ) + '=0;path=/;expires=0';
	}

	/**
	 * Set persisten profile
	 */
	public set( token: string, profile: any ): void {

		if (!profile?.id) {
			this.insights.trackProfileException(profile);
		}
		localStorage.setItem( TOKEN_PROPERTY, token );
		localStorage.setItem( PROFILE_PROPERTY, JSON.stringify( profile ) );
	}

	public setTwoFAData( token: string, twoFAConfig: any ) {
		this.twoFAData = new TwoFAData(
			token,
			twoFAConfig.primary,
			twoFAConfig.configurable
		);
	}

	public getTwoFAData( key ) {
		return this.twoFAData[ key ];
	}

}

export class TwoFAData {
	constructor(
		public token: string,
		public primary: string,
		public configurable: boolean
	) { }
}
