import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WithDestroy } from '@builder/common/mixins/with-destroy';

@Component({
  selector: 'lookup-alpha',
  templateUrl: './lookup-alpha.component.html',
  styleUrls: ['./lookup-alpha.component.less']
})
export class LookupAlphaComponent extends WithDestroy() {
  @Output() hideComponentEvent = new EventEmitter<boolean>();
  public hasAlphas: boolean = false;

  constructor(
    private route: ActivatedRoute,
  ) {
    super(); 

    route.data.subscribe( ({ hasAlphas }) => {

      this.hasAlphas = hasAlphas

		});

  }

  hideComponent() {
    this.hideComponentEvent.emit(false);
  }
}
