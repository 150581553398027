import { Component, HostBinding, HostListener, Input, SimpleChanges } from "@angular/core";
import { trigger, style, transition, animate, state } from '@angular/animations';

import { Announcement } from "../announcement.class";
import { AnnouncementsService } from "../announcements.service";

/**
 * Announcement Component
 *
 * @usage  <announcement [announcement]="announcementObject"></announcement>
 */
@Component( {
	selector: 'announcement',
	template: `<div [innerHTML]="announcement.content"></div><ab-icon (click)="dismissedAnimation = 'closed'" class="close" name='close'></ab-icon>`,
	styleUrls: [ 'announcement.styles.less' ],
	animations: [
		trigger( 'dismiss', [
			state( 'closed', style( {
				opacity: 0,
				transform: 'translateY(-100%)'
			} ) ),
			state( 'open', style( {
				opacity: 1,
				transform: 'translateY(0px)'
			} ) ),
			transition( 'closed => open', [ animate( '0.2s 0.2s ease-out' ) ] ),
			transition( 'open => closed', [ animate( '0.2s 0s ease-out' ) ] ),
		] )
	],
} )
export class AnnouncementComponent {

	// animation state binding on the component itself
	@HostBinding( '@dismiss' ) public dismissedAnimation: string = 'open';

	// when the dismissal transition has ended, then mark it as dismissed
	@HostListener( '@dismiss.done', [ '$event' ] ) public onAniDone( e ): void {
		if ( e.toState === 'closed' ) {
			this.service.dismiss( this.announcement.id );
		}
	}

	// the announcement object input
	@Input() announcement: Announcement;

	constructor(
		private service: AnnouncementsService
	) { }

	// when the @Input changes, change the animation state to 'open'
	ngOnChanges( s: SimpleChanges ): void {
		this.dismissedAnimation = 'open';
	}

}