<ng-template #audioOption
			 let-audioLanguage="audioLanguage">
	<span i18n="video.download.options|Audio Language Label@@audioLanguageLabel">{{audioLanguage// i18n: (ph="audioLanguage")
	}} audio</span>
</ng-template>

<ng-template #subVideoOption
			 let-subtitleLanguage="subtitleLanguage"
			 let-audioLanguage="audioLanguage">
	<span i18n="products.video|Product available in audio with subtitles@@productSubtitledAudio">
		{{audioLanguage// i18n: (ph="audioLanguage")
		}} audio with {{subtitleLanguage// i18n: (ph="subtitleLanguage")
	}} subtitles
	</span>
</ng-template>

<div id="downloadLanguages"
	 *ngIf="showOptions">
	<span class="label"
		  i18n="videos|Language Selection@@whatVideoLanguage">Select the language of the video</span>
	<div class="select-field-wrapper">

		<select [ngModel]="selectedVideoOption"
				(ngModelChange)="changeVideoDownloadOption($event)">

			<ng-container *ngFor="let langOption of videoOptions">
				<option *ngIf="langOption.video"
						[ngValue]="langOption">
					<ng-template [ngTemplateOutlet]="audioOption"
								 [ngTemplateOutletContext]="{
		  audioLanguage: _getAudioLanguage(langOption)
	   }"></ng-template>

				</option>
				<option *ngFor="let subVideo of langOption.subtitles;"
						[ngValue]="subVideo">

					<ng-template [ngTemplateOutlet]="subVideoOption"
								 [ngTemplateOutletContext]="{
		  subtitleLanguage: _getSubtitleLanguage( subVideo ),
		  audioLanguage : _getAudioLanguage( langOption )
	   }">

					</ng-template>
				</option>
			</ng-container>



		</select>
	</div>
</div>

<div id="downloadOptions"
	 *ngIf="downloadOptions.length > 1">
	<span class="label"
		  i18n="videos|Resolution Selection@@whatVideoResolution">What video resolution would you like?</span>

	<mat-radio-group (change)="selectDownload( $event.value )">
		<div class="video-download-option"
			 *ngFor="let download of downloadOptions">

			<span class="download-input">
				<mat-radio-button disableRipple
								  [value]="download"
								  [checked]="currentDownload === download">{{download.name}}</mat-radio-button>
			</span>

			<span class="meta">
				{{download.width}}x{{download.height}}
				<ng-container *ngIf="download.size"> / {{download.size | fileSize}}</ng-container>
			</span>

		</div>
	</mat-radio-group>
</div>
<div *ngIf="notFoundError"
	 class="ab-message error"
	 i18n="videos|Video no longer available@@videoUnavailable">The video you requested is no longer available. Please make another selection.</div>

<div id="downloadControls">
	<button id="downloadButton"
			mat-flat-button
			color="primary"
			(click)="download()"
			[disabled]="!currentDownload || loading"
			i18n="alpha.link|Download text@@download">Download</button>

	<div *ngIf="selectedVideoOption.video.external_url"
		 id="shareLinkContainer">

		<span *ngIf="!showingCopy"
			  (click)="showingCopy = true"
			  id="shareLink"
			  i18n="common|Share Link label@@shareLinkLabel">Share Link</span>

		<clipboard-copy *ngIf="showingCopy"
						id="copyClipboard"
						[text]="selectedVideoOption.video.external_url">
		</clipboard-copy>

	</div>
</div>


<mat-progress-bar *ngIf="loading"
				  mode="indeterminate"
				  color="accent"></mat-progress-bar>