import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ObStore, ObjectStore } from '@plugin/common/mixins/object-store.mixin';
import { UserCache } from '@plugin/common/services/cache.service';

import { isString } from '@builder/common/util/index';
import { AuthHttp } from '@builder/http/restHttp';
import { HttpFormParams } from '@builder/http/form-params';

import { Playlist } from '@builder/training/playlists/playlist-class';
import { Lesson } from '@builder/training/lessons/lesson-class';


@Injectable( {
	providedIn: 'root'
} )
export class PlaylistService extends ObStore( ObjectStore ) {

	private _playlistEndpoint = '/wp-json/wp/v2/playlist';

	constructor(
		private authHttp: AuthHttp,
		cache: UserCache
	) {
		super( cache, 'playlist', Playlist );
	}

	/**
     * Load Playlists
     */
	public list( query: any = {} ) {

		return this.authHttp.get<Array<Playlist>>( this._playlistEndpoint, { params: new HttpFormParams( query ), observe: 'response' } ).pipe(
			map( ( response: any ) => {

				// map the raw items into lessons and cache them
				if ( response.body.length && !( response.body[ 0 ] instanceof Playlist ) ) {
					response.body = response.body.map( data => {
						return this.updateStore( data.id, data );
					} );
				}

				return response;

			} )
		);


	}

	/**
     * Get an Playlist by Id or Slug
     */
	public getPlaylist( id: number | string, params: any = {} ): any {

		const propField = isString( id ) ? 'slug' : 'id';

		return this.getItem( id, params, ( p => this.authHttp.get<Playlist>( this._playlistEndpoint + '/' + id, { params: p } ) ), propField );
	}

	/**
     * Save lesson progress on a playlist
     * @todo blog_id implement
     */
	saveLessonProgress( lesson: Lesson, playlist: Playlist ) {

		return this.authHttp.post( this._playlistEndpoint + '/lesson-complete', {
			lesson_id: lesson.id,
			lesson_blog_id: lesson.blog_id,
			playlist_id: playlist.id,
			playlist_blog_id: playlist.blog_id

		} );

	}

	public resetPlaylistProgress( playlist ) {
		return this.authHttp.delete( this._playlistEndpoint + '/' + playlist.blog_id + '/' + playlist.id + '/progress' );
	}

}
