import { MatomoSettings } from "../matomo-settings";
import { MatomoInterface } from "./api.interface";


export class MatomoClassicAnalytics implements MatomoInterface {

	private settings: MatomoSettings;
	private currentUrl: string;

	/**
	 * Get reference to the _paq variable on the document
	 */
	public get paq(): Array<Array<any>> {
		return (window as any)._paq = (window as any)._paq || [];
	}

	public setOptions(options: MatomoSettings): void {
		this.settings = options;
	}

	public initialize(): void {

		this.currentUrl = document.location.href;

		this.paq.push(["setTrackerUrl", this.settings.trackerJSHost + "matomo.php"]);

		if (this.settings.siteId) {
			this.paq.push(["setSiteId", this.settings.siteId]);
		} else {
			console.warn('Matomo is missing configuration parameter "siteId"');
		}

		if (this.settings.enableLinkTracking) {
			this.paq.push(["enableLinkTracking"]);
		}

		this.paq.push(["trackPageView"]);

		this.loadDeps();
	}

	/**
	 * Create and attach script element for Matomo client js
	 */
	private loadDeps(): void {

		const d = document, g = d.createElement("script"), s = d.getElementsByTagName("script")[0];
		g.type = "text/javascript";
		g.async = true;
		g.src = this.settings.trackerJSHost + "matomo.js";
		s.parentNode.insertBefore(g, s);
	}

	/**
	 * Track a page view
	 */
	public trackPageView(url: string, title: string = null): void {

		this.paq.push(['setReferrerUrl', this.currentUrl]);
		this.paq.push(['setCustomUrl', url]);

		if (title !== null) {
			this.paq.push(['setDocumentTitle', title]);
		}

		this.paq.push(['trackPageView']);

		// set the current url for next referrer
		this.currentUrl = document.location.href;

	}

	public trackEvent(category: string, action: string, name: string, value: any = null, customDimension?: any): void {

		/**
		 * @todo the event names being used for tag manager are different than what we've been using for classic analytics
		 * We've updated the event names before they reach here so when classic analytics is removed we won't need to make any changes to our tag manager implementation
		 * We need to re-map some event names to maintain backwards compatibility here
		 * see here: https://alphainternational.atlassian.net/browse/ADP-2239
		 */

		if (category === 'course-create') {
			// no name value used
			name = '';
			value = 1

			category = 'CreateCourse';
			action = 'CourseCreatedSuccessfully';

		}
		if (category === 'course-update') {

			category = 'UpdatedCourse';
			action = 'CourseUpdatedSuccessfully';

			name = '';
			value = 1
		}
		if (category === 'course-publish') {

			category = 'PublishCourse';
			action = 'CoursePublishedSuccessfully';

			name = '';
			value = 1
		}
		if (category === 'course-unpublish') {

			category = 'UnpublishCourse';
			action = 'CourseUnpublishedSuccessfully';

			name = '';
			value = 1
		}
		if (category === 'download-video') {

			category = 'DownloadVideo';
			action = 'DownloadVideoSuccessfully';

			name = '';
			value = 1
			customDimension = customDimension
		}
		if (category === 'product-tour-started') {

			category = 'ProductTourStarted';
			action = 'ProductTourStartedSuccessfully';

			name = '';
			value = 1
		}
		if (category === 'product-tour-completed') {

			category = 'ProductTourCompleted';
			action = 'ProductTourCompletedSuccessfully';

			name = '';
			value = 1
		}

		this.paq.push(['trackEvent', category, action, name, value, customDimension]);
	}

	public trackForm(formElement: HTMLElement): void {
		this.paq.push(['FormAnalytics::trackForm', formElement]);
	}

	public trackFormSubmit(formElement: HTMLElement): void {
		this.paq.push(['FormAnalytics::trackFormSubmit', formElement]);
	}

	public trackFormConversion(formElement: HTMLElement): void {
		this.paq.push(['FormAnalytics::trackFormConversion', formElement]);
	}
}