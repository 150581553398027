import { Inject, Injectable } from '@angular/core';

export interface IOrganization {
	id: string;
	name: string;
	address: string;
	city: string;
	postalCode: string;
	locality: string;
	country: string;
	phoneNumber: string;
	denomination: string;
	type: string;
	meta: any;
}
@Injectable()
export class Organization implements IOrganization {

	public id: string;
	public name: string;
	public address: string;
	public city: string;
	public postalCode: string;
	public locality: string;
	public country: string;
	public phoneNumber: string;
	public denomination: string;
	public type: string;
	public meta: any;
	public clear: boolean;
	public overrideDefault: boolean;
	private _formatted_address: string;

	constructor( @Inject( 'Organization' ) data?) {

		this.meta = {};

		const obj = data || {};
		for ( const prop in obj ) {
			this[ prop ] = obj[ prop ];

		}
	}

	public set formatted_address( address ) {
		this._formatted_address = address;
	}

	public get formatted_address(): string {

		const parts: Array<string> = [ this.address, this.city, this.locality, this.country ].filter( e => e );

		return parts.join( ', ' );

	}

	reset() {
		this.id = null;
		this.name = null;
		this.phoneNumber = null;
		this.denomination = null;
		this.type = null;
		this.address = null;
		this.city = null;
		this.postalCode = null;
		this.locality = null;
		this.meta = {};
		this.clear = true;
	}

}
