import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FeaturesService } from '@builder/common/features/features.service';
import { Locale, ILanguageOption } from '../../lang/locale';
import { Observable, takeUntil } from 'rxjs';
import { WithDestroy } from '@builder/common/mixins/with-destroy';
import { FEATURE_USER_PROFILE } from '@builder/common/features/feature-flag';

@Component({
  selector: 'language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.less'],
  animations: [
    trigger('languageMenuToggle', [
      state('open', style({ transform: 'translateX(0)' })),
      state('closed', style({ transform: 'translateX(-100%)' })),
      transition('open <=> closed', [
        animate('200ms 100ms ease-out')
      ])
    ])
  ]
})
export class LanguageComponent extends WithDestroy(){
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;

  public isMobileView: boolean = false
  public showLanguageMenu: boolean = false;
  public languages: ILanguageOption[];
  public simpleCreateCourseIsEnabled: boolean = false;
  public userProfileIsEnabled: boolean = false;
  public languageSearchTerm: string = '';
  public mobileLanguages: ILanguageOption[];

  constructor(
    public locale: Locale,
    private features: FeaturesService,
    private bo: BreakpointObserver,
  ) {
      super();

      this.userProfileIsEnabled = this.features.isOn( FEATURE_USER_PROFILE );
      this.languages = locale.languages.filter(l => l.public === true && l.code !== locale.code);
      this.mobileLanguages = locale.languages.filter(l => l.public === true);
      const lc: Observable<BreakpointState> = this.bo.observe(['(max-width:768px)']);
  
      lc.pipe(takeUntil(this.destroy$)).subscribe(result => {
        this.isMobileView = result.matches
      });

    }

  languageAnimationDone(_: any) {
    this.toggleActivatedPanel();
  }

  languageAnimationStart(_: any) {
    this.toggleActivatedPanel();
  }

  toggleLanguageMenu() {
    this.showLanguageMenu = !this.showLanguageMenu;
    this.toggleActivatedPanel();
  }

  toggleActivatedPanel() {
    if (this.showLanguageMenu) {
      document.documentElement.classList.add('activated-panel');
    } else {
      document.documentElement.classList.remove('activated-panel');
    }
  }

  filteredLanguages() {
    if (this.isMobileView) {
      // Use filter to get the matching languages based on languageSearchTerm
      if (this.languageSearchTerm) {
        this.mobileLanguages = this.languages.filter(
          (language) =>
            language.english_name.toLowerCase().includes(this.languageSearchTerm.toLowerCase())
        );
      }
      else {
        this.mobileLanguages = this.locale.languages.filter(l => l.public === true);
      }
    }
    else {

      // Use filter to get the matching languages based on languageSearchTerm
      if (this.languageSearchTerm) {
        this.languages = this.languages.filter(
          (language) =>
            language.english_name.toLowerCase().includes(this.languageSearchTerm.toLowerCase())
        );
      }
      else {
        this.languages = this.locale.languages.filter(l => l.public === true && l.code !== this.locale.code);
      }
    }
  }

  ngOnDestroy(): void {
    document.documentElement.classList.remove('activated-panel');
	}
}
