import { Component } from "@angular/core";

@Component({
    selector: "mobile-header",
    templateUrl: "mobile-header.component.html",
    styleUrls: ["mobile-header.component.less"],
})
export class MobileHeaderComponent {
    constructor() {}

}
