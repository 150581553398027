import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { UserService } from '@builder/users/service';

@Injectable()
export class VerificationResolver implements Resolve<any> {

	constructor(
		private userService: UserService,
		private router: Router
	) { }

	/**
	 * Resolve /user/verify/:code
	 */
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> | Promise<any> | any {

		// verification code
		const code: string = route.params[ 'code' ];

		return this.userService.completeVerification( code ).pipe(
			switchMap(result => {
				return this.router.navigate(['/user', 'signon' ], {
					queryParams: {
						userlogin: result.user_email
					}
				 } );
			}),
			catchError( error => {
				// if they are visiting this verification url but their verification was a team invite, redirect them there
				if ( error.error?.code === 'team_redirect' ) {
					return this.router.navigate( [ '/team', 'invite', code ] );
				}
				// resolve with the error as a property of the data
				return from( [ { error: error.error ?? error } ] );
			} )
		);
	}
}
