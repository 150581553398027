import { Component } from '@angular/core';

import { GeotargetlyService, geotargetlySettingsProvider } from './geotargetly.service';

/**
 * This class handles subscribing to app events and passing those to the matomo service class
 */
@Component( {
	selector: 'geotargetly',
	template: '',
	providers: [ geotargetlySettingsProvider, GeotargetlyService ]
} )
export class GeotargetlyComponent {

	constructor(
		service: GeotargetlyService
	) { }
}
