<div class="select-field-wrapper">
	<select id="userRole"
			#selectField
			[ngModel]="selectedRole"
			(change)="roleChanged($event)">
		<option value=""
				[disabled]="placeholderOptionIsDisabled"
				i18n="forms.select|Select Role@@roleSelect">-- Select Role --</option>
		<option *ngFor="let role of roles"
				[value]="role.value"
				[innerHTML]="role.label"></option>
	</select>
</div>