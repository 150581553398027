import { Component, ChangeDetectionStrategy, OnDestroy, HostBinding, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { CurrentUser } from '@builder/users';
import { AppTheme } from '../util/themeSupports';
import { Locale } from '../lang/locale';
import { expandDown } from '../animations';
import { TrackingService, PageView } from '@builder/tracking';
import { WithDestroy } from '../mixins/with-destroy';

@Component( {
	selector: 'search-panel',
	templateUrl: 'search-panel.component.html',
	styleUrls: [ 'search-panel.component.less' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		expandDown( 'expandDown' )
	]
} )
export class SearchPanelComponent extends WithDestroy() implements OnDestroy {
	@Input() showSearch: boolean; 
	@Output() toggleSearchPanelEvent: EventEmitter<any> = new EventEmitter();

	@HostBinding( 'class.open' ) get isOpen(): boolean {
		return this.showSearch;
	}

	public isSearchPage = false;

	constructor(
		public theme: AppTheme,
		public currentUser: CurrentUser,
		public router: Router,
		public locale: Locale,
		private trackingService: TrackingService,
	) {
		super();

		this.trackingService.events.pipe(
			takeUntil( this.destroy$ )
		).subscribe( event => {

			if ( event instanceof PageView ) {
				
				if (this.showSearch) {
					this.toggleSearchPanelEvent.emit();
				}
				this.isSearchPage = event.data.url.indexOf( '/search' ) !== -1;

			} 
		} );
	}

	ngOnChanges(changes: SimpleChanges) {

		if ( this.isSearchPage ) {
			document.getElementById( 'searchTerm' ).focus();
			return;
		}

		if (changes.showSearch.currentValue) {
			document.getElementById("contentContainer").classList.add( 'search-panel-open' );
			document.getElementById( 'searchTerm' ).focus();
		} else {
			document.getElementById("contentContainer").classList.remove( 'search-panel-open' );
		}
        
    }

	public hideSearchForm(): void {

		this.toggleSearchPanelEvent.emit();

	}
}
