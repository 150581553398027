import { Alpha } from '@builder/alphas';
import { UserSurvey, Survey } from '@alpha-global/alpha-surveys';

export class PostAlphaSurvey extends UserSurvey {
	public id: number;
	public surveyId: number;
	public surveyBlogId: number;

	private _alpha: Alpha;
	public get alpha(): Alpha { return this._alpha; }
	public set alpha( val ) {
		if ( val ) {
			this._alpha = new Alpha( val );
		}
	}


	protected _survey: Survey;
	public get survey(): Survey { return this._survey; }
	public set survey( val ) {
		if ( val ) {
			this._survey = !( val instanceof Survey ) ? new Survey( val ) : val;
		}
	}

	constructor( data: any = {} ) {
		super( data );
		Object.assign( this, { ...data } );
	}
}

