<div id="navBarHeader">

	<div id="navBarLeft"
		 (click)="isExpanded = !isExpanded">
		<div id="navBarModuleSelector">

			<ab-icon name="module"
					 id="moduleIcon"></ab-icon>

			<div id="moduleTitle"><span i18n="common.words|Common Word - Module@@commonWordsModule">Module</span>: {{playlist.title}}</div>

			<ab-icon name="chevron-up"
					 [class.toggle]="isExpanded"></ab-icon>
		</div>

		<div id="navBarModuleComplete">
			{{playlist.lessonsComplete}} / {{playlist.lessonCount}} <span i18n="common.words|Common Words - Completed@@commonWordsCompleted">Completed</span>
		</div>
	</div>

	<div id="navBarControls">
		<ab-icon name="rwd"
				 [class.disabled]="!playlist.previousLesson"
				 (click)="goPrevious()"></ab-icon>
		<ab-icon name="ffwd"
				 [class.disabled]="!playlist.nextLesson"
				 (click)="goNext()"></ab-icon>
	</div>
</div>

<div id="navBarContent"
	 *ngIf="isExpanded"
	 @expand>

	<div id="navBarItems">
		<div *ngFor="let lesson of playlist.lessons"
			 class="playlist-step lesson"
			 routerLinkActive="current">

			<ab-icon class="lesson-complete-icon"
					 name="check"
					 *ngIf="lesson.complete"></ab-icon>

			<a class="lesson-title"
			   (click)="goTo(lesson)"
			   [innerHTML]="lesson.title"></a>

			<div class="lesson-meta">

				<div class="lesson-type">

					<span i18n="common.words|Article@@commonWordArticle"
						  *ngIf="lesson.type === 'article'">Article</span>

					<span i18n="common.words|Video@@commonWordVideo"
						  *ngIf="lesson.type === 'video'">Video</span>

				</div>

				<div class="lesson-duration">
					{{lesson.duration | playTime}}
				</div>

			</div>

		</div>
	</div>


</div>
