import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LocalitySelectComponent } from './locality-select.component';

@NgModule( {
	imports: [
		CommonModule,
		ReactiveFormsModule
	],
	declarations: [
		LocalitySelectComponent
	],
	exports: [
		LocalitySelectComponent
	]
} )
export class LocalitySelectModule { }
