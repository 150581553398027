import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserService } from '@builder/users/service';

import { SearchResultsComponent } from './results/search-results.component';
import { SearchResultsModule } from './results/search-results.module';
import { PopularSearchTermsResolver } from './popular/popular-search-terms.resolver';
import { SearchResultsResolver } from './results/search-results.resolver';
import { LookupAlphaResolver } from './lookup-alpha/lookup-alpha.resolver';

@NgModule( {
	imports: [
		RouterModule.forChild( [
			{
				path: '',
				component: SearchResultsComponent,
				canActivate: [ UserService ],
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				resolve: {
					popularTerms: PopularSearchTermsResolver,
					results: SearchResultsResolver,
					hasAlphas: LookupAlphaResolver
				}
			}
		] ),
		SearchResultsModule
	],
	declarations: [

	]

} )
export class SearchModule { }
