import { NgModule } from '@angular/core';
import { ImagePositionDirective } from './image-position.directive';

@NgModule( {
	declarations: [
		ImagePositionDirective
	],
	exports: [
		ImagePositionDirective
	]
} )
export class ImagePositionModule { }
