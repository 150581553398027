import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { PasswordEditorComponent } from './password-editor.component';

@NgModule( {
	imports: [
		CommonModule,
		ReactiveFormsModule,
		BuilderMaterialModule,
		AlphaLocaleModule
	],
	exports: [ PasswordEditorComponent ],
	declarations: [ PasswordEditorComponent ],
} )
export class PasswordEditorModule { }
