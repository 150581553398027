import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Organization } from './organization';


@Injectable()
export class OrganizationsService {

	private baseUrl = '/wp-json/wp/v2';

	constructor( private _http: HttpClient ) {

	}


	/**
     *
     * @param searchName
     * @param per_page
     * @param page
     */
	public searchOrganizations( searchName: string, per_page: number = 30, page: number = 1 ): any {
		const orgSearchEndpoint = this.baseUrl + '/organization?search=' + searchName + '&per_page=' + per_page + '&paged=' + page;
		return this._http.get<any>( orgSearchEndpoint, {
			observe: 'response'
		} ).pipe(
			map( ( orgs: HttpResponse<any> ) => {
				const response = orgs.clone( {
					body: orgs.body.map( o => new Organization( o ) )
				} );
				return response;
			} )
		);
	}

	/**
     * Get an Organization by Id
     */
	public getOrganization( id ): Observable<Organization> {
		return this._http.get<Organization>( this.baseUrl + '/organization/' + id.trim() ).pipe(
			map( data => {
				const organization = new Organization( data );
				return organization;
			} ) );


	}
}
