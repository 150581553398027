
/**
 * The data properties for the page
 */
export class PageData {

	public title: string;
	public name: string;
	public content: string;
	public status: string;
	public canView = true;
	public video_url: string;
	public video_thumbnail: { url: string };
	public hideNavigation: boolean;


	constructor( data = {} ) {
		this.title = '';
		this.content = '';
		for ( const prop in data ) {
			this[ prop ] = data[ prop ];
		}
	}
}


export class PageError {

	constructor(
		public title: string,
		public code: string,
		public message: string,
		public data: {
			url: string,
			label: string
		} = null
	) {

	}
}
