
export class GeotargetlySettings {

	public id: string;

	constructor( params ) {
		for ( const prop in params ) {
			this[ prop ] = params[ prop ];
		}

	}
}
