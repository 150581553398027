import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlphaFieldOptions } from '../fieldOptions';

import { find } from '../../util';

@Component( {
	selector: 'user-roles-select',
	templateUrl: 'user-role-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef( () => UserRoleSelectComponent ),
			multi: true
		}
	]
} )
export class UserRoleSelectComponent implements ControlValueAccessor {

	@ViewChild( 'selectField', { static: true } ) select: ElementRef;

	public placeholderOptionIsDisabled: boolean;

	private _selectedRole;
	@Input() set selectedRole( value ) {
		this._selectedRole = value || '';
	}
	get selectedRole() {
		let role = '';
		this.roles.forEach( ( item ) => {
			if ( item.value === this._selectedRole || item.label === this._selectedRole ) {
				role = item.value;
			}
		} );
		return role;
	}

	public roles: Array<any>;

	constructor( private _fieldOptions: AlphaFieldOptions, private _changeDetector: ChangeDetectorRef ) {

		this.roles = [];

		// Get the localities
		this._fieldOptions.roles()
			.subscribe( result => {
				this.roles = result;
				this._changeDetector.markForCheck();
			} );
	}

	setDisabledState( disabled ) {

		this.select.nativeElement.disabled = disabled;
	}

	propagateChange = ( _: any ) => { };

	registerOnChange( fn ) {
		this.propagateChange = fn;
	}

	registerOnTouched() { }

	writeValue( value: any ) {

		if ( value ) {
			this.selectedRole = value;
			this.placeholderOptionIsDisabled = Boolean(this.selectedRole);
		}
	}

	roleChanged( event ) {
		const role = find( this.roles, { value: event.target.value } ) || null;
		this.selectedRole = event.target.value;
		this.placeholderOptionIsDisabled = Boolean(this.selectedRole);
		this.propagateChange( role );
	}

}
