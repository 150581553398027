<div class="search-alpha">
    <div class="text-group">
        <h4 class="mya-h4">
            <span *ngIf="hasAlphas" i18n="noResults.title|Looking for your Alpha?@@noResultsTitleLookingForAlphas">
                Looking for your Alpha?
            </span>
            <span *ngIf="!hasAlphas" i18n="noResults.title|Looking to start an Alpha?@@noResultsTitleStartAnAlpha">
                Looking to start an Alpha?
            </span>
        </h4>
        <p [hidden]="!hasAlphas" class="mya-p" i18n="noResults.msg|Navigate to@@searchOptionsMsg">
            Navigate to the 'My Alphas' page and look for your Alpha title to access it.
        </p>
    </div>
    <div>
        <button 
            mat-button 
            color="basic" 
            class="goto-alpha-btn"
            [routerLink]="hasAlphas ? ['/alphas'] : ['/preview-resources']">
            <span *ngIf="hasAlphas" i18n="noResults.button|Link to My Alphas Text@@noResultsButtonMyAlphas">
                Go to My Alphas
            </span>
            <span *ngIf="!hasAlphas" i18n="noResults.button|Link to Preview Resources Text@@noResultsButtonPreviewResources">
                Select an Alpha to run
            </span>
        </button>
    </div>
    <ab-icon class="dialogClose"
        name="close"
        (click)="hideComponent()"></ab-icon>
</div>