import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";

import { map, Observable } from "rxjs";

import { LessonService } from "@builder/training/lessons/lesson-service";
import { Lesson } from '@builder/training/lessons/lesson-class';

@Injectable({
  providedIn: "root",
})
export class LessonDetailTitleResolver implements Resolve<string> {
  constructor(private service: LessonService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): string | Observable<string> | Promise<string> {
    const id = route.paramMap.get("lessonId") ?? route.paramMap.get("id")

    return this.service
      .getLesson(id, { _fields: 'title' })
      .pipe(map((lesson: Lesson) => lesson.title));
  }
}
