import { NgModule } from '@angular/core';
import { FBPixelComponent } from './fb-pixel.component';

@NgModule( {
	declarations: [
		FBPixelComponent
	],
	exports: [
		FBPixelComponent
	]
} )
export class FBPixelModule { }
