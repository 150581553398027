<ng-container *ngIf="error.productAccess">
	<h6 i18n="errors|Content Access Denied Title@@contentAccessDeniedTitle">You do not have access to this content</h6>
	<ng-container [ngTemplateOutlet]="productAccess"
				  [ngTemplateOutletContext]="{productName:error.productAccess.title}"></ng-container>

	<button *ngIf="error.productAccess.slug"
			mat-flat-button
			color="primary"
			[routerLink]="['/alphas', 'new', error.productAccess.slug]"
			i18n="series.button|Run This Series Button@@runThisSeriesButton">Run this Series</button>
</ng-container>


<ng-template #productAccess
			 let-productName="productName">
	<p i18n="resources|Resource Access Denied - Missing Product@@resourceProductAccessDenied">You need to be running {{productName// i18n: (ph="productName")
	}} in order to have access to this resource.</p>
</ng-template>