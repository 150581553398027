import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CardModule } from '@builder/common/cards/card.module';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { LessonCardComponent } from './lesson-card.component';

@NgModule( {
	imports: [
		CommonModule,
		RouterModule,
		AlphaLocaleModule,
		CommonPipesModule,
		CardModule,
		BuilderIconModule
	],
	declarations: [
		LessonCardComponent
	],
	exports: [
		LessonCardComponent
	]
} )
export class LessonCardModule {

}
