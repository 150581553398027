<mat-chip-set class="card-chips"
			   *ngIf="chips.length">
	<ng-content select="mat-chip"></ng-content>
</mat-chip-set>

<div class="card-wrapper">
	<div class="card-image"
		 *ngIf="image || defaultCardImage"
		 (click)="imageClick.emit()"
		 [style.backgroundImage]="!image ? 'none' : 'url(' + image + ')'">

		<ng-template *ngIf="!image"
					 [ngTemplateOutlet]="defaultCardImage"></ng-template>
	</div>

	<div class="card-body">
		<ng-content></ng-content>
	</div>

	<ng-content select=".card-footer"></ng-content>
</div>