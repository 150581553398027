import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { BuilderIconModule } from "../icons/ab-icon.module";

import { MobileHeaderComponent } from "./mobile-header.component";

@NgModule({
    imports: [CommonModule, RouterModule, BuilderMaterialModule, BuilderIconModule],
    declarations: [MobileHeaderComponent],
    exports: [MobileHeaderComponent],
    entryComponents: [],
})
export class MobileHeaderModule {}
