import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
	name: 'fileIcon'
} )
export class FileIconPipe implements PipeTransform {

	transform( type: string, path?: string ): string {

		if ( type === 'video' ) {
			return 'movie';
		}

		const extension = path ? path.substr( path.lastIndexOf( '.' ) + 1 ) : null;

		switch ( extension ) {
			case 'docx':
			case 'doc':
				return 'document-filled';
			case 'zip':
				return 'folder';
			default:
				return 'document';

		}
	}
}
