import { Directive, Input, HostListener, Output, EventEmitter, NgModule } from '@angular/core';

/**
 * Simple little directive to have a series of clicks within a given time trigger an event
 */
@Directive( {
	selector: '[click-to-unlock]'
} )
export class ClickToUnlockDirective {

	@Input() clicks = 8;
	@Input() maxTime = 5000;

	@Output( 'click-to-unlock' ) unlock: EventEmitter<any> = new EventEmitter();

	private unlocked = false;

	private currentClicks = 0;
	private startTime: number;

	@HostListener( 'click', [ '$event' ] ) onClick( event ): void {

		if ( this.unlocked ) {
			return;
		}
		const diff: number = this.startTime ? new Date().getTime() - this.startTime : 0;
		if ( diff > this.maxTime ) {
			this.currentClicks = 0;
		}
		if ( this.currentClicks === 0 ) {
			this.startTime = new Date().getTime();
		}
		this.currentClicks++;
		if (
			this.currentClicks === this.clicks
			&& diff < this.maxTime
		) {
			this.unlocked = true;
			this.unlock.emit();
		}
	}
}

@NgModule( {
	declarations: [
		ClickToUnlockDirective
	],
	exports: [
		ClickToUnlockDirective
	]
} )
export class ClickToUnlockModule { }
