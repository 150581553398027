import { isString } from '@builder/common/util';

interface IGooglePlacesConfig {
	address_mappings: { [ key: string ]: Array<string> | string; };
	on_match_multiple: string;
}

export class AddressComponent {
	public long_name: string;
	public short_name: string;
	public types: Array<string>;
}

export class AddressComponentsMatcher {

	public address: AddressComponent[];
	public street: AddressComponent[];
	public city: AddressComponent[];
	public postal_code: AddressComponent[];
	public locality: AddressComponent;
	public country: AddressComponent;


	constructor(
		private components: Array<AddressComponent>,
		settings: IGooglePlacesConfig
	) {


		for ( const prop in settings.address_mappings ) {
			const componentValue = [];
			const matchTypes = ( settings.address_mappings[ prop ] instanceof Array ? settings.address_mappings[ prop ] : [ settings.address_mappings[ prop ] ] ) as Array<string>;

			for ( let i = 0; i < matchTypes.length; i++ ) {
				const comp = this.getComponent( matchTypes[ i ] );
				if ( !comp ) {
					continue;
				}
				componentValue.push( comp );

				if ( settings.on_match_multiple === 'first_match' ) {
					break;
				}

			}
			if ( [ 'address', 'street', 'city', 'postal_code' ].indexOf( prop ) >= 0 ) {
				this[ prop ] = componentValue;
			} else {
				this[ prop ] = componentValue.shift();
			}
		}

	}

	getComponent( type: string ): AddressComponent {
		const len: number = this.components.length;
		let i = 0;
		for ( i; i < len; i++ ) {
			if ( this.components[ i ].types.indexOf( type ) >= 0 ) {
				return this.components[ i ];
			}
		}
		return null;
	}

}


export class PlaceLocation {

	public position: { lat?: number, lng?: number; } = {};

	public formatted_address: string;
	public place_name: string;
	public place_text: string;
	public place_id: string;

	public city: string;
	public locality: { slug: string, name: string; };
	public country: { slug: string, name: string; };
	public address: string;
	public postal_code: string;
	public utc_offset: number;

	public get valid(): boolean {
		const valid = this.place_id !== null && this.place_id !== '';
		if ( valid ) {
			return true;
		}
		return this.address && this.address.length > 0;
	}

	constructor( vars?) {
		this.place_id = null;
		if ( vars ) {
			if ( isString( vars.country ) ) {
				vars.country = { slug: vars.country };
			}
			for ( const prop in vars ) {
				this[ prop ] = vars[ prop ];
			}
		}


	}

	public updateFromPlaceResult( result: google.maps.places.PlaceResult, settings: IGooglePlacesConfig ): void {

		const components = new AddressComponentsMatcher( result.address_components, settings );

		this.place_text = result.formatted_address;
		this.place_name = result.types.includes( 'establishment' ) ? result.name : '';
		this.place_id = result.place_id;
		this.position = result.geometry.location as any;
		this.utc_offset = result[ 'utc_offset_minutes' ];

		let addr = [];
		if ( components.address ) {
			addr = addr.concat( components.address.map( c => c.long_name ) );
		}
		if ( components.street ) {
			addr = addr.concat( components.street.map( c => c.long_name ) );
		}
		this.address = addr.join( ' ' );


		this.city = components.city ? components.city.map( c => c.long_name ).filter( ( v, i, a ) => a.indexOf( v ) === i ).join( ' ' ) : '';
		this.postal_code = components.postal_code ? components.postal_code.map( c => c.long_name ).join( ' ' ) : '';
		this.locality = components.locality ? { slug: components.locality.short_name, name: components.locality.long_name } : null;
		this.country = components.country ? { slug: components.country.short_name, name: components.country.long_name } : null;
	}

	public clone(): PlaceLocation {

		return new PlaceLocation( Object.assign( {}, this ) );
	}

}
