import { Input, Output, EventEmitter, Directive } from '@angular/core';

export interface IPlayer {
	src: string;
	options: { any };
	duration: number;
	currentTime: number;
	volume: number;
	paused: boolean;

	videoProgress: EventEmitter<any>;
	videoPlay: EventEmitter<any>;
	videoPause: EventEmitter<any>;
	videoFinish: EventEmitter<any>;
	videoReady: EventEmitter<any>;
	videoError: EventEmitter<any>;

	play( currentTime?: number ): void;
	pause(): void;
	setSource( url: string ): void;
	destroy(): void;
	resize(): void;
	seekTo( seconds: number ): void;
	requestFullScreen(): void;

}

@Directive( {
	selector: 'playerBase'
} )
export class PlayerBase implements IPlayer {
	public set src( val: string ) { this._src = val; }
	public get src(): string { return this._src; }

	public get duration(): number { return 0; };
	public get currentTime(): number { return 0; }
	public get paused(): boolean { return true; }
	public get volume(): number { return 0; }

	@Input( 'options' ) public options: any = {};
	public _src: string;

	@Input( 'playing' ) public playing: boolean;

	@Output() videoProgress: EventEmitter<any> = new EventEmitter();
	@Output() videoPlay: EventEmitter<any> = new EventEmitter();
	@Output() videoPause: EventEmitter<any> = new EventEmitter();
	@Output() videoFinish: EventEmitter<any> = new EventEmitter();
	@Output() videoReady: EventEmitter<any> = new EventEmitter();
	@Output() videoError: EventEmitter<any> = new EventEmitter();

	public setSource( url: string ): void { this._src = url; }

	public requestFullScreen(): void { }

	public seekTo( seconds: number ): void { }

	public play(): void { }

	public pause(): void { }

	public destroy(): void { }

	public resize(): void { }


}
