import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BuilderMaterialModule } from '@builder/common/ng-material';

import { PasswordEditorModule } from '@builder/users/password-editor/editor/password-editor.module';
import { LocalitySelectModule } from '@builder/common/forms/locality-select/locality-select.module';
import { UserRoleSelectModule } from '@builder/common/forms/user-role-select';
import { TeamRoleSelectModule } from '@builder/common/forms/team-role-select/team-role-select.module';
import { ClickToUnlockModule } from '@builder/common/directives/click-to-unlock';

import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { GoToTextModule } from '@builder/common/text';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { SignonComponent } from './signon.component';

import { RecoverPasswordComponent } from './recoverpwd/recoverPwd.component';
import { VerifyUserComponent } from './verify/verify.component';

import { VerificationResolver } from './verify/verify.resolver';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { CanActivateSignon } from './signon.guard';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


/**
 * Routes
 */
const routes: Routes = [
	{
		path: 'user',
		component: SignonComponent,
		children: [
			{
				path: '',
				redirectTo: 'signon',
				pathMatch: 'full'
			},
			{
				path: 'signon', component: LoginComponent, canActivate: [ CanActivateSignon ], runGuardsAndResolvers:'always'
			},
			{
				path: 'signup', component: SignupComponent, canActivate: [ CanActivateSignon ]
			},
			{
				path: 'verify/:code', component: VerifyUserComponent, data: {}, resolve: {
					verification: VerificationResolver
				}
			},
			{
				path: 'recover-password', component: RecoverPasswordComponent, data: {}
			}
		]
	},

];

/**
* Signon Module
*/
@NgModule( {
	imports: [
		RouterModule.forChild( routes ),
		FormsModule,
		CommonModule,
		ReactiveFormsModule,
		BuilderMaterialModule,
		AlphaLocaleModule,
		BuilderIconModule,
		GoToTextModule,
		LocalitySelectModule,
		UserRoleSelectModule,
		TeamRoleSelectModule,
		ClickToUnlockModule,
		PasswordEditorModule,
        BrowserAnimationsModule
	],
	declarations: [
		SignonComponent,
		RecoverPasswordComponent,
		VerifyUserComponent,
		LoginComponent,
		SignupComponent
	],
	providers: [
		VerificationResolver,
		CanActivateSignon
	],
	bootstrap: [ SignonComponent ]

} )
export class SignonModule { }
