<div id="contentHeader">

	<div class="content-wrapper has-back-link">

		<ab-icon *ngIf="userSurvey"
			class="back-link"
			name="arrow-left"
			size="24"
			[routerLink]="['../']"></ab-icon>

		<ab-icon *ngIf="!userSurvey"
			class="back-link"
			name="arrow-left"
			size="24"
			[playlist]="playlist"
			[lesson]="lesson"
			training-back-link></ab-icon>

		<h1 id="pageTitle"
			[innerHTML]="lesson.title"></h1>

	</div>

</div>

<div id="mediaContainer"
	 *ngIf="lesson.video_url"
	 class="content-wrapper">

	<video-player #videoPlayer
				  *ngIf="lesson.type == 'video'"
				  [autoPlay]="canAutoPlayVideo"
				  [src]="lesson.video_url"
				  (videoComplete)="onWatchVideo($event)"
				  (videoFinished)="onVideoFinished($event)"></video-player>

	<div id="videoCompleteOverlay"
		 *ngIf="videoIsFinished">

		<ng-container *ngIf="playlist && playlist.nextIncompleteLesson">
			<div i18n="common.words|Playlist - Up Next@@playlistUpNext">Up Next</div>

			<lesson-card [lesson]="playlist.nextLesson || playlist.nextIncompleteLesson"
						 [routerLink]="['../', (playlist.nextLesson || playlist.nextIncompleteLesson).slug]"></lesson-card>
		</ng-container>

		<div id="replayButton">
			<ab-icon color="primary"
					 name="reload"
					 (click)="videoPlayer.play(0);videoIsFinished = false"></ab-icon>
			<div class="replay-text"
				 i18n="common.words|Replay Video@@commonPhraseReplayVideo">Replay Video</div>
		</div>


	</div>

</div>

<div id="lessonContent"
	 class="content-wrapper">

	<div id="lessonContentText"
		 [innerHTML]="lesson.content.rendered">
	</div>

	<div *ngIf="playlist && playlist.nextIncompleteLesson && lesson.type === 'article'"
		 id="lessonAfterContent">

		<p i18n="common.words|Playlist - Up Next@@playlistUpNext"
		   class="up-next">Up Next</p>

		<lesson-card [lesson]="playlist.nextLesson || playlist.nextIncompleteLesson"
					 [routerLink]="['../', (playlist.nextLesson || playlist.nextIncompleteLesson).slug]"></lesson-card>

	</div>

	<div *ngIf="playlist && ! playlist.nextIncompleteLesson && ( videoIsFinished || lesson.type === 'article' ) && ! moduleComplete"
		 id="lessonAfterContent">

		<p i18n="learning.label|Last Step of Module Warning@@lastStepOfModuleWarning">Great! This is the last step of the module.</p>

		<!-- @todo remove the condition once the completed modal is added for the userSurvey condition -->
		<a *ngIf="!userSurvey"
		   mat-flat-button
		   color="primary"
		   i18n="learing.button|Complete Module Button Label@@completeModuleButtonLabel"
		   (click)="lesson.type === 'article' ? this.completeArticleLesson(lesson) : moduleComplete = true">Complete Module</a>

	</div>

</div>

<ng-container *ngIf="playlist">
	<div id="playlistNavBarContainer">

		<div id="playlistNavBarWrapper">

			<playlist-nav-bar id="playlistNavBar"
							  [routerBase]="userSurvey?.meta?.alpha ?  '/alphas/' + userSurvey.meta.alpha.id + '/survey/recommendations' : '/training'"
							  [playlist]="playlist"></playlist-nav-bar>

		</div>

	</div>

	<!--
		The modal overlay is only available when we are not viewing in the context of a user survey. 
		The playlists that are shown in this modal should be those that are other recommended playlists for the user survey
		This functionality/template has not been completed yet so we're not going to show the modal for now when in the context of a user survey
		@todo move the *ngIf="userSurvey" condition inside the modal-overlay and provide a template that will render the other playlists in the userSurvey object
	-->
	<ng-container *ngIf="!userSurvey">
		<modal-overlay [open]="moduleComplete"
					   [options]="{
					  width: '90vw',
					  maxWidth:600,
					  maxHeight:'80vh',
					  panelClass: 'module-complete'
				   }">

			<div id="moduleComplete">

				<h1 i18n="learning.title|Module Completed Title@@moduleCompletedTitle">Module Completed!</h1>
				<h6 i18n="learning.label|Why Not Start Another Module Label@@startAnotherModule">Why not start another module:</h6>

				<related-playlists [playlist]="playlist"
								   [limit]="2"></related-playlists>

				<p>
					<a [routerLink]="['/training']"
					   class="active">
						<goto><span i18n="training|Learning Centre Title@@learningCenterTitle">Learning Centre</span></goto>
					</a>
				</p>
			</div>

		</modal-overlay>

	</ng-container>

</ng-container>


<section id="bottomContent"
		 *ngIf="!playlist">

	<div id="bottomContentContainer"
		 class="content-wrapper">

		<div id="relatedContent"
			 *ngIf="lesson.related.length">

			<h6>
				<related><span i18n="common.words|Common Words - Content@@commonWordContent">Content</span></related>
			</h6>

			<div id="relatedContentItems">
				<lesson-card *ngFor="let lesson of lesson.related"
							 [routerLink]="['/training', 'article', lesson.slug]"
							 [lesson]="lesson"></lesson-card>
			</div>

		</div>

		<div id="relatedCategories"
			 *ngIf="lesson.categories.length">

			<h6>
				<related><span i18n="common.words|Categories@@commonWordsCategories">Categories</span></related>
			</h6>

			<div id="relatedCategoriesItems">

				<ab-icon class="text-icon-link"
						 [routerLink]="['/training', 'category', category.slug]"
						 name="search"
						 *ngFor="let category of lesson.categories"><span>{{category.name}}</span></ab-icon>

			</div>

		</div>


	</div>

</section>
