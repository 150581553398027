import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { TrackingModule } from "@builder/tracking/tracking.module";
import { SearchFormModule, PopularSearchTermsModule } from "@builder/search";
import { BuilderIconModule } from "../icons/ab-icon.module";
import { AlphaLocaleModule } from "../lang/locale.module";

import { SearchPanelComponent } from "./search-panel.component";

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		AlphaLocaleModule,
		BuilderMaterialModule,
		BuilderIconModule,
		AlphaLocaleModule,
		SearchFormModule,
		PopularSearchTermsModule,
		TrackingModule
	],
    declarations: [SearchPanelComponent],
    exports: [SearchPanelComponent],
    entryComponents: [],
})
export class SearchPanelModule {}
