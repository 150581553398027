import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuilderMaterialModule } from '@builder/common/ng-material';
import { BuilderIconModule } from '../icons/ab-icon.module';

import { ModalOverlayComponent, OverlayDialogComponent } from './modal.component';

@NgModule( {
	imports: [
		CommonModule,
		BuilderMaterialModule,
		BuilderIconModule
	],
	declarations: [
		ModalOverlayComponent,
		OverlayDialogComponent
	],
	exports: [
		ModalOverlayComponent
	],
	entryComponents: [
		OverlayDialogComponent
	]
} )
export class ModalOverlayModule {

}
