import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { TrackingService, TrackingEventName } from '@builder/tracking';
import { CurrentUser } from '@builder/users';

@Component( {
	selector: 'search-form',
	templateUrl: 'search-form.component.html',
	styleUrls: [ 'search-form.component.less' ]
} )
export class SearchFormComponent {

	@Input( 'searchTerm' ) public searchTerm: string;
	@Input( 'navExtras' ) public navExtras: NavigationExtras = {};

	@ViewChild('searchInput') searchInput: ElementRef;

	constructor(
		private router: Router,
		private trackingService: TrackingService,
		private user: CurrentUser
	) { }

	public onFormSubmit( event: Event ): void {

		event.preventDefault();

		this.searchInput.nativeElement.blur();

		this.trackingService.trigger( 'search-library' as TrackingEventName, { search: this.searchTerm } );
		sessionStorage.setItem("savedSearchQuery-"+this.user.id, this.searchTerm);

		this.router.navigate( [ '/search' ], Object.assign( this.navExtras, { queryParams: { q: this.searchTerm } } ) );
	}

	public focus(): void {
		this.searchInput.nativeElement.focus();
	}
}
