import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AlphaLocaleModule } from '@builder/common/lang/locale.module';

import { TeamRoleSelectComponent } from './team-role-select.component';

@NgModule( {
	imports: [
		CommonModule,
		FormsModule,
		AlphaLocaleModule
	],
	declarations: [
		TeamRoleSelectComponent
	],
	exports: [
		TeamRoleSelectComponent
	]
} )
export class TeamRoleSelectModule {

}
