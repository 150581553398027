import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter the list of product options to only show those from Resources using them
 */
@Pipe( {
	name: 'filterInUseProducts'
} )
export class FilterInUseProductsPipe implements PipeTransform {

	transform( options, products ) {

		const prepared = [
			{ viewValue: $localize`:common.words|All@@commonWordAll:All` },
			...options
				.filter( ( product: any ) => products.find( p => p.products.find( rp => rp.id === product.id && rp.blog_id === product.blog_id ) ) !== undefined )
				.map( ( product: any ) => {
					return { viewValue: product.title, value: [ { id: product.id, blog_id: product.blog_id } ] };
				} )
		];
		return prepared;
	}
}


/**
 * Filter the list of category options to only show those from Lessons using them
 */
@Pipe( {
	name: 'filterInUseCategories'
} )
export class FilterInUseCategoriesPipe implements PipeTransform {

	transform( options, posts ) {


		const filtered = options.filter( opt => {
			if ( !opt.value ) return true;
			return posts.filter( p => p.categories.includes( opt.value ) ).length > 0;
		} );
		return filtered;
	}
}

/**
 * Pipe to filter down posts in the result view
 */
@Pipe( {
	name: 'postsFilter',
	pure: false
} )
export class PostsFilterPipe implements PipeTransform {

	private lastKey: string;
	private items: Array<any>;

	transform( items: Array<any>, filterParams ) {


		const key = JSON.stringify( filterParams ) + items.length;
		if ( this.lastKey && key === this.lastKey ) {
			return this.items;
		}

		this.lastKey = key;

		const filtered = items.filter( post => {

			/**
			 * Filter posts by product if required
			 */
			if ( filterParams.products ) {

				const pHash = filterParams.products.map( p => p.id + '_' + p.blog_id );

				let foundOne = false;

				( post as any ).products.forEach( p => {

					if ( pHash.indexOf( p.id + '_' + p.blog_id ) >= 0 ) {
						foundOne = true;
					}
				} );
				if ( !foundOne ) {
					return false;
				}

			}

			/**
			 * Filter posts by category if required
			 */
			if ( filterParams.category && post.categories && !post.categories.includes( filterParams.category ) ) {
				return false;
			}

			/**
			 * Filter posts by themes if required
			 */
			if ( filterParams.themes && post.categories && !post.categories.includes( filterParams.themes ) ) {
				return false;
			}

			/**
			 * Filter posts by type if required
			 */
			if ( filterParams.type ) {

				if ( post.type !== filterParams.type ) { 
					return false;
				}

			}

			return true;
		} );

		if ( filterParams.limit ) {
			this.items = filtered.slice( 0, filterParams.limit );
		} else {
			this.items = filtered;
		}

		return this.items;

	}
}



/**
 * Pipe to filter down posts in the result view
 */
@Pipe( {
	name: 'postsFilterUpdated',
	pure: true
} )
export class PostsFilterUpdatedPipe implements PipeTransform {

	private items: Array<any>;

	transform( items: Array<any>, filterParams ) {

		const filtered = items.filter( post => {

			if (filterParams === null || JSON.stringify(filterParams) === '{}') return true

			/**
			 * Filter posts by [themes, category, or type]
			 */
			const themes = Object.entries(filterParams.themes)
				.filter(val => val[1])
				.map(val => val[0]);

			if ( 
				(filterParams.themes.all || themes.some(theme => post.themes.includes(theme))) &&
				(filterParams.category && filterParams.category[post.category]) &&
				(filterParams.type && filterParams.type[post.type] && 
				(filterParams.searchWithin == "all" || !post.error?.productAccess)) &&
				(!filterParams.products?.length || !post.products || filterParams?.products?.some(id => post?.products?.some(post => post.id === id)))
			) {
				return true;
			}

			return false;
		} );


		if ( filterParams?.limit ) {
			this.items = filtered.slice( 0, filterParams.limit );
		} else {
			this.items = filtered;
		}

		return { filteredItems: this.items, count: this.items.length };

	}
}