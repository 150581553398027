import { Component, ElementRef } from '@angular/core';
import { default as Player } from '@vimeo/player';
import { PlayerBase, IPlayer } from '@builder/common/media/video/player/player.base';


const get_vimeo_id = ( src: string ): number => {
	const vidId = src.match( /https:\/\/vimeo.com\/([\d]+)\/?/ );
	return vidId ? parseInt( vidId[ 1 ], 10 ) : null;
};

@Component( {
	selector: 'vimeo-player',
	template: ``,
	styles: [ `:host {display:block;}` ]
} )
export class VimeoPlayerComponent extends PlayerBase implements IPlayer {

	private player: Player;

	public element: HTMLIFrameElement;

	public videoId: number;

	constructor( private el: ElementRef ) {
		super();
	}

	public play( startTime: number = -1 ): void {
		if ( startTime >= 0 ) {
			this.player.setCurrentTime( startTime ).then( seconds => {

				this.player.getPaused().then( paused => {
					if ( paused ) {
						this.player.play();
					}
				} );
			} );
		} else {
			this.player.play();
		}
	}
	public pause(): void {
		this.player.pause();
	}

	/**
	 * Vimeo videos don't throw any errors if there is a 403. The only way to check if a video is going to display is by using the oembed.json and checking a status code in it
	 * However, this is a lot of overhead for something that only really happens in dev environments. On the live site, videos will have been whitelisted
	 * for the needed domains. Dev domains aren't always included so sometimes you'll get videos not showing up on dev. *If* a video doesn't load on the live
	 * it is probably because of the domain issues. It rarely happens so rather than introduce this overhead, we'll just let er fly.
	 *
	 */
	private testAuth( url ) {

		return new Promise( ( resolve, reject ) => {

			const doProbe = false;
			if ( !doProbe ) {
				resolve( null );
				return;
			}

			const ourl = 'https://vimeo.com/api/oembed.json?url='.concat( encodeURIComponent( url ), '&domain=' ).concat( window.location.hostname );
			const xhr = new XMLHttpRequest();
			xhr.open( 'OPTIONS', ourl, true );

			xhr.onload = () => {
				if ( xhr.status === 404 ) {
					reject( new Error( '\u201C'.concat( url, '\u201D was not found.' ) ) );
					return;
				}

				if ( xhr.status === 403 ) {
					reject( new Error( '\u201C'.concat( url, '\u201D is not embeddable.' ) ) );
					return;
				}
				try {
					const json = JSON.parse( xhr.responseText ); // Check api response for 403 on oembed

					if ( json.domain_status_code === 403 ) {

						reject( new Error( '\u201C'.concat( url, '\u201D is not embeddable.' ) ) );
						return;
					}

					resolve( json );
				} catch ( error ) {
					reject( error );
				}
			};
			xhr.send();
		} );

	}

	private createIFrame(): void {
		this.element = document.createElement( 'iframe' );

		this.element.allowFullscreen = !this.options.disableFullScreen;
		this.element.setAttribute( 'allow', 'autoplay' );
		this.element.style.visibility = 'hidden';
		this.el.nativeElement.appendChild( this.element );
	}
	private createPlayer(): void {

		this.createIFrame();

		this.element.src = 'https://player.vimeo.com/video/' + this.videoId;

		this.player = new Player( this.element, {
			id: this.videoId,
			maxwidth: 1280,
			width: 726,
			autoplay: this.options.autoPlay
		} );

		this.player.ready().then( _ => {

			this.videoReady.emit();

		} ).catch( err => {
			this.element.style.visibility = 'visible';
			this.videoError.emit();
		} );

		this.player.on( 'error', ( err ) => {
			console.error( err );
		} );

		this.player.on( 'loaded', () => {

			this.element.style.visibility = 'visible';

			if ( this.options.autoPlay ) {

				setTimeout( () => {

					this.player.play();

				}, 500 );
			}
		} );

		this.player.on( 'play', () => {

			this.videoPlay.emit();
		} );

		this.player.on( 'pause', ( event ) => {
			this.videoPause.emit();
		} );

		this.player.on( 'timeupdate', ( progress ) => {
			this.videoProgress.emit( { progress, playlist: this } );

		} );
		this.player.on( 'bufferstart', () => {

		} );
		this.player.on( 'bufferend', () => {

		} );

		this.player.on( 'ended', () => {
			this.videoFinish.emit();
		} );

	}

	public setSource( url: string ): void {

		this.videoId = get_vimeo_id( url );

		url = 'https://player.vimeo.com/video/' + this.videoId;

		this.testAuth( url ).then( ok => {

			if ( this.player ) {
				this.player.destroy().then( () => this.createPlayer() );
			} else {
				this.createPlayer();
			}



		} ).catch( err => {
			this.videoReady.emit();
		} );

		super.setSource( url );

	}

	public destroy(): void {
		this.player.destroy();
	}

	/**
     * Fit the element
     */
	public resize(): void {
		this.el.nativeElement.style.height = this.el.nativeElement.clientWidth * ( 540 / 960 ) + 'px';
		if ( this.element ) {
			this.element.style.width = '100%';
			this.element.style.height = this.el.nativeElement.style.height;
		}
	}

}
