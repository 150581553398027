import { Injectable } from '@angular/core';

import { AppTheme } from '@builder/common/util/themeSupports';

import { MatomoSettings } from './matomo-settings';
import { MatomoInterface } from './api/api.interface';
import { MatomoTagManager } from './api/tag-manager';
import { MatomoClassicAnalytics } from './api/classic-analytics';

/**
 * MatomoService will attach the client JS library and interface with the '_paq' global variable
 */
@Injectable( {
	providedIn: 'root'
} )
export class MatomoService {

	private api:MatomoInterface;

	constructor(
		protected settings: MatomoSettings
	) {
		this.initialize();
	}

	/**
	 * Initialize tracker object with our settings
	 */
	private initialize(): void {

		if ( this.settings.type === 'tag_manager' ) {
			this.api = new MatomoTagManager();
		} else {
			this.api = new MatomoClassicAnalytics();
		}

		this.api.setOptions( this.settings );
		this.api.initialize();
	}

	public trackEvent( category: string, action: string = '', name: string = '', value: any = null, customDimension: any = null ): void {
		this.api.trackEvent( category, action, name, value, customDimension );	
	}
	
	public trackPageView( url: string, title: string = null ): void {
		this.api.trackPageView(url, title);
	}

	public trackForm( formElement: HTMLElement ): void {
		this.api.trackForm( formElement);
	}

	public trackFormSubmit( formElement: HTMLElement ): void {
		this.api.trackFormSubmit( formElement);
	}

	public trackFormConversion( formElement: HTMLElement ): void {
		this.api.trackFormConversion( formElement);
	}
}



export const matomoSettingsProviderFactory = ( appTheme: AppTheme ) => {
	const obj = appTheme.value( 'tracking/matomo' );
	return new MatomoSettings( obj );
};

export let matomoSettingsProvider = {
	provide: MatomoSettings,
	useFactory: matomoSettingsProviderFactory,
	deps: [ AppTheme ]

};
