<div id="signonWrapper">
	<ab-icon *ngIf="canClose"
			 id="backButton"
			 name="arrow-right"
			 rotation="180"
			 (click)="backOrClose()">
		<span i18n="alpha.text|Back link@@backlink">Back</span></ab-icon>
	<div id="outletContainer"
		 class="content-wide-wrapper">
		<router-outlet></router-outlet>
	</div>
</div>