import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ObStore, ObjectStore } from '../mixins/object-store.mixin';
import { Product } from '../models/product';
import { UserCache } from './cache.service';

@Injectable( {
	providedIn: 'root'
} )
export class ProductService extends ObStore( ObjectStore ) {

	private _endpoint = '/wp-json/wp/v2/product';

	constructor(
		private http: HttpClient,
		cache: UserCache
	) {
		super( cache, 'product', Product );
	}

	public getProduct( id:number, blog_id:number, params ): Observable<Product> {

		return this.getItem( id, params, ( p => this.http.get( this._endpoint + '/' + blog_id + '/' + id, { params: p } ) ) );
	}

	public getProductByName( name: string, params: any = {} ): Observable<Product> {

		return this.getItem( name, params, ( p => this.http.get( this._endpoint + '/slug/' + name, { params: p } ) ), 'slug' );
	}

	public getProductTitleByLang( id:number, blog_id:number, lang:string): Observable<Record<string, any>> {
		return this.http.get( this._endpoint + '/' + blog_id + '/' + id, { params: { _fields: 'title', rtml_language: lang } } )
	}

	public getQuestions( params: any = {} ): any {
		return this.http.get( this._endpoint + '/questions', { params } ).pipe(

		);
	}

	public getRecommendedProducts( questions ) {

		questions._fields = 'id,blog_id,title,slug,excerpt,duration,promotionalVideo,image,questions,language';
		return this.http.get( this._endpoint + '/recommend', { params: questions } ).pipe(
			map( ( response: { others: Array<any>, recommended: any; } ) => {

				response.others = response.others.map( o => new Product( o ) );
				response.recommended = response.recommended ? new Product( response.recommended ) : null;
				return response;
			} )
		);


	}

	public getMaterials( product ) {
		return this.http.get( this._endpoint + '/' + product.blog_id + '/' + product.id, { params: { _fields: 'materials,materialTextContent', rtml_language: product.language } } ).pipe(
			tap( result => {
				product.materialTextContent = ( result as any ).materialTextContent;
			} )
		);
	}


	public list( params: any = { context: 'list' } ): Observable<Array<Product>> {

		return this.http.get<Product[]>( this._endpoint, { params } ).pipe(
			map( ( products: Array<any> ) => {

				const productObjects = [];
				products.forEach( data => {

					const product: Product = this.updateStore( data.id, data );

					productObjects.push( product );
				} );
				return productObjects;
			} ) );
	}

	public getValidProducts(): Observable<Product[]> {
		return this.list({ _fields: 'title,id,blog_id,duration' })
			.pipe(
				map(products => products.filter(p => p.duration.sessionCount > 0)),
			)
	}

}
