import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from './locale';

@Pipe( {
	name: 'languageInfo',
	pure: false
} )
export class LanguageInfoPipe implements PipeTransform {

	private value: string;

	private property: string;
	private lang_code: string;

	constructor( private locale: Locale ) { }

	transform( lang_code: string, property: string = 'label' ): string {

		if ( this.property !== property || this.lang_code !== lang_code ) {
			this.value = null;
			this.lang_code = lang_code;
			this.property = property;


			const matched = this.locale.languages.find( lang => {
				return lang.code === lang_code;
			} );
			if ( matched ) {
				this.value = matched[ property ];
			} else {
				this.value = '';
			}

		}
		return this.value;
	}
}
