import { Component } from '@angular/core';
import { filter } from 'rxjs/operators';

import { MatomoService, matomoSettingsProvider } from './matomo.service';
import { TrackingService } from '@builder/tracking';
import { PageView, CreatedAlpha, UpdatedAlpha, FormTracking, FormConversion, FormSubmit, PublishedAlpha, UnpublishedAlpha, DownloadVideo, ProductTourStarted, ProductTourCompleted  } from '../tracking-events';
import { CustomTitleStrategyService } from './../../common/custom-title/custom-title-strategy.service';

/**
 * This class handles subscribing to app events and passing those to the matomo service class
 */
@Component( {
	selector: 'matomo',
	template: '',
	providers: [ matomoSettingsProvider, MatomoService, CustomTitleStrategyService ]
} )
export class MatomoComponent {

	constructor(
		private service: MatomoService,
		private trackingService: TrackingService,
		private titleService: CustomTitleStrategyService,
	) {

		this.subscribeToPageView();
		this.subscribeToCourseCreate();
		this.subscribeToCoursePublish();
		this.subscribeToCourseUnpublish();
		this.subscribeToUpdatedCourse();
		this.subscribeToFormConversion();
		this.subscribeToFormSubmit();
		this.subscribeToFormTracking();
		this.subscribeToDownloadVideo();
		this.subscribeToProductTourStarted();
		this.subscribeToProductTourCompleted();


	}

	private subscribeToCourseCreate():void{

		this.trackingService.events.pipe(

			filter( e => e instanceof CreatedAlpha )

		).subscribe(

			event => {
				this.service.trackEvent( 'course-create' );
			}
		)
	}

	private subscribeToCoursePublish(): void {

		this.trackingService.events.pipe(

			filter( e => e instanceof PublishedAlpha )
	
		).subscribe(

			event => {
				this.service.trackEvent( 'course-publish' );
			}
		)
	}
	
	private subscribeToCourseUnpublish(): void {

		this.trackingService.events.pipe(

			filter( e => e instanceof UnpublishedAlpha )

		).subscribe(

			event => {
				this.service.trackEvent( 'course-unpublish' );
			}
		)
	}

	private subscribeToUpdatedCourse(): void {

		this.trackingService.events.pipe(

			filter( e => e instanceof UpdatedAlpha )
		
		).subscribe(

			event => {
				this.service.trackEvent( 'course-update' );
			}
		)
	}

	private subscribeToDownloadVideo(): void {

		this.trackingService.events.pipe(

			filter(e => e instanceof DownloadVideo)

		).subscribe(

			event => {
					this.service.trackEvent('download-video', '', '', null, { dimension1: event.data.resolution, dimension2: event.data.episodeName });
			}
		)
	}

	private subscribeToProductTourStarted(): void {

		this.trackingService.events.pipe(

			filter(e => e instanceof ProductTourStarted)

		).subscribe(

			event => {
				this.service.trackEvent('product-tour-started');
			}
		)
	}

	private subscribeToProductTourCompleted(): void {

		this.trackingService.events.pipe(

			filter(e => e instanceof ProductTourCompleted)

		).subscribe(

			event => {
				this.service.trackEvent('product-tour-completed');
			}
		)
	}

	private subscribeToFormTracking(): void {

		this.trackingService.events.pipe(

			filter( e => e instanceof FormTracking )

		).subscribe(

			event => {
				this.service.trackForm( event.data.formElement );
			}
		)
	}

	private subscribeToFormConversion(): void{

		this.trackingService.events.pipe(

			filter( e => e instanceof FormConversion )

		).subscribe(

			event => {
				this.service.trackFormConversion( event.data.formElement );
			}
		)
	}

	private subscribeToFormSubmit (): void{

		this.trackingService.events.pipe(

			filter( e => e instanceof FormSubmit )

		).subscribe(

			event => {
				this.service.trackFormSubmit( event.data.formElement );
			}
		)
	}

	private subscribeToPageView(): void {
		/**
		 * Subscribe to PageView events
		 */
		this.trackingService.events.pipe(

			filter( e => e instanceof PageView && e.data.url !== e.data.previous.path )

		).subscribe(

			event => {

				const title = this.titleService.getTitle();

				// track page view with service
				this.service.trackPageView( event.data.url, title );
			}
		);
	}
}
