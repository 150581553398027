import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BuilderMaterialModule } from '@builder/common/ng-material';
import { BuilderIconModule } from '../icons/ab-icon.module';
import { UserHeaderComponent } from './user-header.component';
import { AlphaLocaleModule } from "../lang/locale.module";
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRippleModule } from '@angular/material/core';
import { AccountMenuModule } from "./account-menu/account-menu.module";
import { LanguageModule } from './language/language.module';
import { WhatsNewModule } from '@builder/common/user-header/whats-new/whats-new.module';
import { WhatsNewDialogModule } from './whats-new/whats-new-modal/whats-new-modal.module';

@NgModule( {
    declarations: [
        UserHeaderComponent,
    ],
    exports: [UserHeaderComponent],
    entryComponents: [],
    imports: [
        CommonModule,
        RouterModule,
        BuilderMaterialModule,
        BuilderIconModule,
        AlphaLocaleModule,
        FormsModule,
        MatButtonModule, 
        MatMenuModule, 
        MatIconModule,
        BrowserAnimationsModule,
        MatRippleModule,
        AccountMenuModule,
        LanguageModule,
        WhatsNewModule,
        WhatsNewDialogModule 
    ]
} )
export class UserHeaderModule { }
