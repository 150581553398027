import { Component, Input, TemplateRef, Directive, ContentChild, SimpleChanges, ViewChild, HostListener, Output, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { MatMenu, MatMenuTrigger, MatMenuItem } from '@angular/material/menu';


@Directive( {
	selector: '[builder-option-template]'
} )
export class BuilderSelectOptionDirective { public template: TemplateRef<any>; }

/**
 * <builder-select>
 */
@Component( {
	selector: 'builder-select',
	templateUrl: 'builder-select.component.html',
	styleUrls: [ 'builder-select.component.less' ]
} )
export class BuilderSelectComponent implements OnChanges {

	public selectedItem;

	@Input() labelProperty = 'viewValue';
	@Input() options: Array<any>;
	@Input() value: any;

	@Output() changeOption: EventEmitter<any> = new EventEmitter();

	@ViewChild( MatMenu, { static: true } ) optionsMenu: MatMenu;
	@ViewChild( MatMenuTrigger, { static: true } ) menuTrigger: MatMenuTrigger;

	@ContentChild( BuilderSelectOptionDirective, { static: true, read: TemplateRef } ) optionTemplate: TemplateRef<any>;

	@ViewChildren( MatMenuItem ) menuItems: QueryList<MatMenuItem>;

	@HostListener( 'click' ) onClick(): void {
		this.menuTrigger.openMenu();
	}

	onOpen( e ) {
		let index = 0;
		if ( this.selectedItem ) {
			index = this.options.indexOf( this.options.find( opt => opt.viewValue === this.selectedItem.viewValue ) );
		}
		const mi = this.menuItems.toArray();
		if ( index >= 0 && mi[ index ] ) {
			setTimeout( () => {
				mi[ index ].focus();
			}, 0 );
		}

	}

	public selectOption( option ): void {

		this.changeOption.emit( option );

	}

	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes.value && changes.value.currentValue ) {
			this.selectedItem = this.options.find( opt => opt.viewValue === changes.value.currentValue.viewValue );
		}
		if ( changes.options && !this.selectedItem ) {
			this.selectedItem = changes.options.currentValue[ 0 ];
			this.changeOption.emit( this.selectedItem );
		}
	}

}
