import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { prepareDictionary, setLocale } from '@builder/i18n-loader';
import { Observable } from 'rxjs';
import { initLocaleForApp } from './locale-initializer';

/**
 * Injectable data
 */
export const LOCALE_DATA = new InjectionToken<string>( 'LOCALE_DATA' );

/**
 * Language option
 */
export class ILanguageOption {
	label: string;
	english_name: string;
	written_name: string;
	code: string;
	current: boolean;
	public: boolean;
	default: boolean;
	selected: boolean;
	dateLocale: string;
	numberingSystem: string;
	ng_locale: string;
}

/**
 * Locale is the public class that contains information about localization
 */
@Injectable( {
	providedIn: 'root'
} )
export class Locale {

	public code: string;
	public dateLocale: string;
	public numberingSystem: string;
	public ngLocale: string;
	public isRTL: boolean;
	public trainingCenter: { enable: boolean, locales: Array<any>; };
	public languages: Array<ILanguageOption>;
	public idpMappings: { [ key: string ]: string; };

	private translations;

	constructor( @Inject( LOCALE_DATA ) params, private http: HttpClient ) {
		this.code = params.locale;
		this.dateLocale = params.dateLocale;
		this.numberingSystem = params.numberingSystem;
		this.ngLocale = params.ngLocale;
		this.isRTL = params.isRTL;
		this.idpMappings = params.idpMappings || {};

		this.trainingCenter = params.training || { enable: false };

		this.languages = params.languages || [];

		this.parseLocaleData( params );
	}

	/**
	 * Parse the locale response
	 */
	private parseLocaleData( params ): void {

		// prepare translations into consumable dictionary object
		this.translations = prepareDictionary( params.data );

		// pass them to Angular to load into it's $localize framework
		loadTranslations( this.translations );

		// if there are font overrides for the language handle them
		if ( params.font ) {
			this.addFontOverride( params.font );
		}

		this.setDocumentAttributes();
	}

	/**
	 * Some fonts may specify specific fonts to use and different selectors or styles for them
	 */
	private addFontOverride( font ): void {
		this.createFontLink( font );
		this.createFontStyle( font );
	}

	/**
	 * Set the lang and dir attributes on the html element
	 */
	private setDocumentAttributes() {
		document.documentElement.setAttribute( 'lang', this.code );
		document.documentElement.setAttribute( 'dir', this.isRTL ? 'rtl' : 'ltr' );
	}

	/**
	 * Create <style> for our custom language font
	 */
	private createFontStyle( font ) {
		const styleLinkId = 'fontOverrideStyle';
		let styleLink: HTMLStyleElement = document.getElementById( styleLinkId ) as HTMLStyleElement;
		if ( styleLink ) {
			styleLink.remove();
		}
		styleLink = document.createElement( 'style' ) as HTMLStyleElement;
		styleLink.id = styleLinkId;

		let styleDeclaration = '';
		if ( font.selectors ) {
			styleDeclaration = font.selectors.join( ',' ) + `{ font-family: ${ font.name }, sans-serif !important; }`;
		} else {
			styleDeclaration = `* { font-family: ${ font.name }, sans-serif !important; }`;
		}

		if ( font.styles ) {
			for ( let selector in font.styles ) {
				styleDeclaration += selector + '{' + font.styles[ selector ] + '}';
			}
		}

		styleLink.innerHTML = styleDeclaration;

		document.body.appendChild( styleLink );
	}

	/**
	 * Create the <link> to load the custom language font
	 */
	private createFontLink( font ) {
		const styleLinkId = 'fontOverrideLink';
		let styleLink: HTMLLinkElement = document.getElementById( styleLinkId ) as HTMLLinkElement;
		if ( styleLink ) {
			styleLink.remove();
		}
		styleLink = document.createElement( 'link' ) as HTMLLinkElement;
		styleLink.id = styleLinkId;
		styleLink.rel = 'stylesheet';
		styleLink.href = font.url;
		document.body.appendChild( styleLink );
	}

	/**
	 * Return a translation by it's ID
	 */
	public translate( id, defaultValue = null ) {

		return this.translations[ id ] ?? defaultValue;
	}

	/**
	 * Set the locale, fetching the language data and re-parsing if needed
	 */
	public set( code: string ) {
		return new Observable( ( observer ) => {
			const lang = this.getLanguage( code );

			if ( lang ) {

				this.code = lang.code;
				this.dateLocale = lang.dateLocale;
				this.numberingSystem = lang.numberingSystem;
				this.ngLocale = lang.ng_locale;

				initLocaleForApp( { ngLocale: lang.ng_locale } )().then( () => {

					this.http.get( `/wp-json/wp/v2/localeData/${ lang.code }` ).subscribe(
						( response: any ) => {
							this.parseLocaleData( response );
							setLocale( response.locale );
							observer.next( null );
							observer.complete();
						}, error => {
							observer.next( null );
						}
					);

				} );

			} else {
				observer.next( null );
				observer.complete();
			}
		} );

	}

	public getLanguage( code: string ): ILanguageOption {
		return this.languages.find( l => l.code.toLowerCase() === code.toLowerCase() );
	}

	public isLanguagePublic( code: string ): boolean {
		const lang = this.languages.find( l => l.code.toLowerCase() === code.toLowerCase() );
		return lang ? lang.public : false;
	}

}
