import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageComponent } from './language.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { AlphaLocaleModule } from '@builder/common/lang/locale.module';
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module';
import { BuilderMaterialModule } from '@builder/common/ng-material';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LanguageComponent
  ],
  imports: [
    RouterModule,
    BuilderMaterialModule,
    BuilderIconModule,
    AlphaLocaleModule,
    FormsModule,
    CommonModule,
    MatButtonModule, 
    MatMenuModule, 
    MatIconModule,
    MatRippleModule,
  ],
  exports: [
    LanguageComponent
  ]

})
export class LanguageModule { }
