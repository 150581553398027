<div *ngIf="messageOnlyDisplay">
	<ab-icon name="logo"
			color="red"
			size="37"></ab-icon>
</div>

<div *ngIf="!messageOnlyDisplay" class="login-header">
	<ab-icon name="logo"
			color="red"
			size="37"></ab-icon>
	<h3>Login</h3>
	<div id="menuLanguages">
		<div (click)="showLanguageMenu = ! showLanguageMenu"
			class="link"
			id="menuLanguagesText">{{ locale.code | languageInfo }}<ab-icon [class.toggle]="showLanguageMenu"
					name="chevron-down"></ab-icon>
		</div>
		<div id="menuLanguagesOptions"
			@expandDown
			*ngIf="showLanguageMenu">
			<div class="menu-language-option link"
				*ngFor="let language of languages"
				[language-link]="language.code"
				[title]="language.code">
				{{language.native_name}}
			</div>
		</div>
	</div>
</div>

<p *ngIf="errorMessage && messageOnlyDisplay"
   class="form-row">{{errorMessage}}</p>

<ng-container *ngIf="!messageOnlyDisplay">

	<form (submit)="onFormSubmit()"
		  [formGroup]="form">
		<div class="form-row">
			<label class="field-label text-bold"
				   for="emailField"
				   >Email</label>
			<input id="emailField"
				   type="text"
				   pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
				   formControlName="user_email" />
		</div>

		<div>
			<div class="form-row">
				<label class="field-label text-bold"
					   for="passwordField"
					   (click-to-unlock)="showExtraLoginOptions = true">Password</label>
				<input id="passwordField"
					   #passwordField
					   formControlName="password"
					   type="password" />
			</div>
		</div>

		<div id="extraLoginOptions"
			 *ngIf="showExtraLoginOptions"
			 [formGroup]="signonOptions">

			<h6>Extra Login Settings</h6>
			<!--
			<mat-checkbox formControlName="skipSalesForce"
						  *ngIf="supportsSalesForce">No SalesForce</mat-checkbox>
			-->
			<mat-checkbox formControlName="isAdminQA">Admin QA</mat-checkbox>
		</div>

		<p *ngIf="errorMessage"
		   class="error-msg form-row">{{errorMessage}}</p>

		<div id="submitRow">

			<button mat-flat-button
					class="login-btn"
					color="primary"
					type="submit"
					[disabled]="loading || !form.valid"
					>Login</button>


			<div id="rememberMeContainer">
				<mat-checkbox formControlName="rememberMe"
							  class="round">
					<span>Remember me</span>
				</mat-checkbox>
			</div>
		</div>

		<p class="user-link-container">
			<span class="text-bold">Can't remember your password?</span>
			<a [routerLink]="['../', 'recover-password']"
			   class="active">
				Recover it here
			</a>

		</p>

		<p id="no-account"
		   class="user-link-container">
			<span class="text-bold">Don't have an account?</span>
			<a [routerLink]="['../', 'signup']"
			   class="active"
			   >Sign Up</a>

		</p>

	</form>

</ng-container>

<mat-progress-bar *ngIf="loading"
				  color="accent"
				  mode="indeterminate"></mat-progress-bar>