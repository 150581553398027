<div class="header-left">
	<ab-icon class="header-logo" name="logo" color="red" size="40" [routerLink]="['/']"
		attr.data-test="account-icon-alpha"></ab-icon>
</div>
<div class="header-right">
	<whats-new *ngIf="productTourOverviewIsEnabled"></whats-new>
	<language></language>
	<account-menu></account-menu>

	<div class="logout-btn">
		<a i18n="common.labels|Logout@@commonLabelsLogout"
			(click)="doLogout()"
			id="logout"
			attr.data-test="auth-button-signout">Log out</a>
	</div>
</div>