import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CurrentUser } from '@builder/users';
import { Resource } from '@plugin/common/models/resource';
import { AppTheme } from '@builder/common/util/themeSupports';
import { AppInjector } from '@builder/app.injector';
import { Product } from '../../../../mu-plugins/alphabuilder/assets/ng/models/product';


@Injectable( {
	providedIn: 'root'
} )
export class ResourceService {

	private url = '/wp-json/wp/v2/resource';
	public resourceMap: Map<string, Resource>;
	private productInfo;   
	private productIdTitleMap

	constructor(
		private http: HttpClient,
		private user: CurrentUser,
		private appTheme: AppTheme
	) {
		this.resourceMap = new Map();
		this.productInfo = this.appTheme.value( 'preview_resources' ).products;

        this.productIdTitleMap = this.productInfo.reduce((a,b) => {
            return { 
                ...a,
                [b.id]: b.title
            }
        }, {})
	}

	/**
	 * Map resources within buckets into Resource classes and add them to our map
	 */
	public mapResources( resources ) {
		return resources.map( resource => {

			if ( resource.buckets ) {

				resource.buckets.forEach( bucket => {
					bucket.items = bucket.items.map( r => {
						const res = new Resource( r );
						this.resourceMap.set( res.blog_id + '-' + res.id, res );
						return res;
					} );
				} );

				return resource;
			} else {
				const res = new Resource( resource );
				this.resourceMap.set( res.blog_id + '-' + res.id, res );
				return res;
			}
		} );
	}

	/**
	 * Get resources
	 * @param params
	 */
	public getResources( params: any = {} ): Observable<Resource[]> {
		return this.http.get<any[]>( this.url, {
			params
		} ).pipe(
			map( resources => this.mapResources( resources ) ),
			tap( resources => {
				resources.forEach( resource => {
					if ( resource.buckets ) {
						resource.buckets.forEach( b => b.items.forEach( r => {
							this.assignResourceErrors(r);
							this.assignResourceProduct(r);
						}) );
					} else {
						this.assignResourceErrors(resource);
						this.assignResourceProduct(resource);
					}
				} );
			} )
		);
	}

	public getMappedResource( id: number, blogId: number = 0 ): Resource {
		const found = this.resourceMap.get( blogId + '-' + id );
		return found;
	}

	public updateResourceViewCount( id: number, blog_id: number ): Observable<any> {
		return this.http.patch( '/wp-json/wp/v2/blog/' + blog_id + '/post/' + id + '/viewcount', {} );
	}

	public updateResourceDownloadCount( id: number, blog_id: number ): Observable<any> {
		return this.http.patch( '/wp-json/wp/v2/blog/' + blog_id + '/post/' + id + '/downloadcount', {} );
	}


	public assignResourceErrors( resource: Resource ): void {

		const errors: any = {};

		/**
		 * If the user is not currently running a Product in use by a resource, mark it with an error which will prompt the user
		 */
		if ( resource.products && resource.products.length ) {
			const userHasResourceProduct = !this.user.alphaStats ? false : resource.products.find( rp => this.user.alphaStats.activeProducts.find( ap => ap.id === rp.id && ap.blog_id === rp.blog_id ) );

			if (
				!userHasResourceProduct  // they aren't running the product
				&& ( resource.categories.includes( 'talks' ) || resource.categories.includes( 'team-training' ) ) // and it's either a talk or team-training
			) {

				const product = this.productInfo.find( p => p.id === resource.products[ 0 ].id && p.blog_id === resource.products[ 0 ].blog_id );

				errors.productAccess = {
					title: product ? product.title : 'Missing Product Name',
					slug: product ? product.slug : null
				};
			}
		}

		if ( Object.keys( errors ).length ) {
			resource.error = errors;
		}
	}

	public assignAccessResourceErrors( resource: Resource, userActiveProducts: Product[] ): void {
		resource.error = null;

		const errors: any = {};

		/**
		 * If the user is not currently running a Product in use by a resource, mark it with an error which will prompt the user
		 */
		if ( resource.products && resource.products.length ) {
			const userHasResourceProduct = resource.products.some(rp => userActiveProducts.some(ap => ap.id === rp.id && ap.blog_id === rp.blog_id))

			if (
				!userHasResourceProduct  // they aren't running the product
				&& ( resource.categories.includes( 'talks' ) || resource.categories.includes( 'team-training' ) ) // and it's either a talk or team-training
			) {

				const product = this.productInfo.find( p => p.id === resource.products[ 0 ].id && p.blog_id === resource.products[ 0 ].blog_id );

				errors.productAccess = {
					title: product ? product.title : 'Missing Product Name',
					slug: product ? product.slug : null
				};
			}
		}

		if ( Object.keys( errors ).length ) {
			resource.error = errors;
		}
	}

	public assignResourceProduct( resource: Resource ): void {

		if ( resource.products && resource.products.length ) {
            resource.products = resource.products.map(obj => {
                return {
                    ...obj,
                    title: this.productIdTitleMap[obj.id]
                }
            })
        }
	}

	downloadDocument(url: string, title: string) {
		const a = document.createElement('a');
		a.href = document.location.origin + new URL(url).pathname
		a.download = title;
		a.target = '_blank';
		a.click();
	  }

	  onDownload(resource: Resource): void {
        const { id, blog_id, title, attachedDocument: { url } } = resource;
        this.updateResourceDownloadCount( id, blog_id ).subscribe();
        this.downloadDocument(url, title);
    }
}

/**
 * @decorator FetchResource
 *
 * Tap into the RestPathMatch decorator, taking it's blog_id, id, and returning a Resource object
 */
export function FetchResource(): MethodDecorator {

	return function( componentInstance: any, propertyKey: string, descriptor: PropertyDescriptor ) {

		const resourceService = AppInjector.get( ResourceService );

		const nextValue = descriptor.value;
		descriptor.value = function( ...args ) {

			const ci = this;
			const id = parseInt( args[ 1 ], 10 );
			const blog_id = parseInt( args[ 0 ], 10 );
			const resource = resourceService.getMappedResource( id, blog_id );
			nextValue && nextValue.apply( ci, [ resource ] );

		};

		return descriptor;
	};
}
