import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { expandDown } from '@builder/common/animations';

import { Playlist } from '../playlist-class';

@Component( {
	selector: 'playlist-nav-bar',
	templateUrl: 'nav-bar.component.html',
	styleUrls: [ 'nav-bar.component.less', 'lesson-list.less' ],
	animations: [
		expandDown( 'expand' )
	]
} )
export class PlaylistNavBarComponent {

	@Input() playlist: Playlist;

	@Input() routerBase: string = '/training';

	public isExpanded: boolean;

	constructor(
		private router: Router
	) { }

	public goTo( lesson ): void {
		this.router.navigate( [ this.routerBase, this.playlist.slug, lesson.slug ] );
	}

	public goNext(): void {

		if ( !this.playlist.nextLesson ) {
			return;
		}

		this.router.navigate( [ this.routerBase, this.playlist.slug, this.playlist.nextLesson.slug ] );
	}

	public goPrevious(): void {
		if ( !this.playlist.previousLesson ) {
			return;
		}
		this.router.navigate( [ this.routerBase, this.playlist.slug, this.playlist.previousLesson.slug ] );
	}
}
