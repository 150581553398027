import { NgModule } from '@angular/core';
import { PageLoadingComponent } from './page-loading.component';

@NgModule( {
	imports: [

	],
	declarations: [
		PageLoadingComponent
	],
	exports: [
		PageLoadingComponent
	]
} )
export class PageLoadingModule { }
