import { Injectable } from '@angular/core';

@Injectable( {
	providedIn: 'root'
} )
export class UserCache {

	private _cache: {} = {};

	private _object_cache: any = {};

	public addObject( object, group = 'default' ) {

		if ( !this._object_cache[ group ] ) {
			this._object_cache[ group ] = [];
		}

		const current = this.getObject( object.id, group );
		if ( !current ) {
			this._object_cache[ group ].push( object );
		} else {
			this._object_cache[ group ].splice( this._object_cache[ group ].indexOf( current ), 1, object );
		}
	}

	public getObject( value: any, group: string = 'default', property: string = 'id' ): any {

		const items: Array<any> = this._object_cache[ group ] || [];

		for ( let i = 0; i < items.length; i++ ) {
			if ( items[ i ][ property ] === value ) {
				return items[ i ];
			}
		}
		return null;

	}


	public set( key, value, group?) {
		if ( group && !this._cache[ group ] ) {
			this._cache[ group ] = {};
		}
		if ( group ) {
			this._cache[ group ][ key ] = value;
		} else {
			this._cache[ key ] = value;
		}
	}
	public get( key, group?) {
		if ( group ) {
			return this._cache[ group ] ? this._cache[ group ][ key ] : null;
		}
		return this._cache[ key ];
	}

	public clearObjectGroup( group ) {

		delete this._object_cache[group];
		delete this._object_cache[group + 'Response'];
	}

	public clear() {
		this._cache = {};
		this._object_cache = {};
	}
	public delete( key ) {
		delete this._cache[ key ];
	}
	public deleteGroup( group ) {
		delete this._cache[ group ];
	}
}
