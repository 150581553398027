import { NgModule } from '@angular/core';

import { AlphaLocaleModule } from '../../lang/locale.module';
import { GoToTextComponent } from './goto.component';


@NgModule( {
	imports: [
		AlphaLocaleModule
	],
	declarations: [ GoToTextComponent ],
	exports: [ GoToTextComponent ]
} )
export class GoToTextModule { }
