import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AuthHttp } from '../http/restHttp';
import { CurrentUser } from './user';
import { isString } from '../common/util';

/**
 * UserPreferencesData can be data passed in from the WP env
 */
export class UserPreferencesData {

	constructor( data: any ) {

		for ( const prop in data ) {
			this[ prop ] = data[ prop ];
		}
	}

	public get( key: string ): any {
		const value: any = this[ key ] ? this[ key ] : null;
		return value;
	}

	public set( key: string, value: any ): boolean {
		let changed = false;
		if ( !this[ key ] ) {
			changed = true;

		} else {
			changed = JSON.stringify( this[ key ] ) !== JSON.stringify( value );
		}
		this[ key ] = value;
		return changed;
	}

	public setAll( obj: any ) : void {
		for ( let prop in obj ) {
			this [ prop ] = obj[ prop ];
		}
	}

}

/**
 * UserPreferences can be injected into classes to provide access to UserPreferencesData
 */
@Injectable()
export class UserPreferences {

	private _endpoint = '/wp-json/wp/v2/user';

	constructor(
		private userPreferencesData: UserPreferencesData,
		private currentUser: CurrentUser,
		private _authHttp: AuthHttp
	) {

	}

	public initFromUser( user:CurrentUser ) : void {
		this.userPreferencesData.setAll( user.preferences );
	}

	public set( key: string|any, value: any = null ): Observable<any> {

		let changed = false;
		let body;
		if ( typeof key === 'string' ) {
			changed = this.userPreferencesData.set( key, value );
			body = JSON.stringify( { key, value } );
		} else {
			for ( let prop in key ) {
				if ( this.userPreferencesData.set( prop, key[prop] ) ) {
					changed = true;
				}
			}
			body = JSON.stringify( { preferences: key } );
		}

		this.currentUser.updatePreferences( this.userPreferencesData );

		return new Observable( ( observer: Observer<any> ) => {

			if ( !changed ) {
				observer.complete();
				return null;
			}

			return this._authHttp.post( this._endpoint + '/preferences', body )
				.subscribe(
					response => {
						observer.next( response );
					},
					( errorMessage: any ) => {
						if ( isString( errorMessage ) ) {
							errorMessage = { message: errorMessage };
						} else if ( errorMessage.json ) {
							errorMessage = errorMessage.json();
						}
						observer.error( errorMessage );

					},
					() => {
						observer.complete();
					}
				);
		} );

	}

	public get( key: string, defaultValue?: any ): any {
		return this.userPreferencesData.get( key ) || defaultValue;
	}

}

export const userPreferencesProviderFactory = ( authHttp: AuthHttp, currentUser: CurrentUser ): UserPreferences => {
	return new UserPreferences(
		new UserPreferencesData( currentUser.preferences || {} ),
		currentUser,
		authHttp
	);
};

export const userPreferencesProvider = {
	provide: UserPreferences,
	useFactory: userPreferencesProviderFactory,
	deps: [ AuthHttp, CurrentUser ]
};
