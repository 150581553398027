import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BuilderMaterialModule } from "@builder/common/ng-material";
import { TrackingModule } from "@builder/tracking/tracking.module";
import { SearchFormModule, PopularSearchTermsModule } from "@builder/search";
import { BuilderIconModule } from "../icons/ab-icon.module";
import { AlphaLocaleModule } from "../lang/locale.module";
import { MobileMenuComponent } from './mobile-menu.component';

@NgModule({
  declarations: [
    MobileMenuComponent
  ],
  imports: [
    CommonModule,
		RouterModule,
		AlphaLocaleModule,
		BuilderMaterialModule,
		BuilderIconModule,
		AlphaLocaleModule,
		SearchFormModule,
		PopularSearchTermsModule,
		TrackingModule
  ],
  exports: [
    MobileMenuComponent
  ]
})
export class MobileMenuModule { }
