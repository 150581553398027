/**
 * This is used to dynamically load the available locale from angular
 * the 'ngLocale' property is set during compilation of mo files. We check the locale from poeditor against what's available in ng
 * the comment below is re-written by poeditor-api.js
 */

import { registerLocaleData } from '@angular/common';

export const initLocaleForApp = ( locale ) => {

	return () => {
		return new Promise( ( resolve, reject ) => {

			const localeId = locale.ngLocale;

			import(
				/* webpackInclude: /(af|sq|am|ar|ar-EG|hy|en|as|az|bn|bg|my|ca|zh-Hans|zh-Hant-HK|zh-Hant|cs|da|nl|en-AU|en-CA|en-NZ|en-GB|en-ZA|et|fi|nl-BE|fr|fr-BE|fr-CA|fr-CH|de|de-CH|el|gu|he|hi|hu|is|id|it|ja|kn|km|ko|ku|lo|lv|lt|ms|ml|mni|mr|mn|ne|no|or|pa|fa|pl|pt|ro|ru|sr|si|sk|sl|es|es-419|es-MX|sw|sv|fil|ta|te|th|tr|uk|ur|vi|zu).mjs$/ */
				`@/../../../../../../../node_modules/@angular/common/locales/${ localeId }.mjs`
			).then( module => {
				registerLocaleData( module.default );
				resolve( null );
			} ).catch( err => {
				console.error( err );
				resolve( null );
			} );
		} );
	};
};
