import { Injectable } from '@angular/core';

import { AppTheme } from '@builder/common/util/themeSupports';
import { User } from '@builder/users/user';

import { IntercomSettings } from './intercom-settings';

declare const fbq: any;

/**
 * IntercomService will attach the client JS library
 */
@Injectable()
export class IntercomService {

	private isBooted: boolean;

	constructor(
		protected settings: IntercomSettings
	) {
		this.initialize();
	}


	/**
	 * Initialize tracker object with our settings
	 */
	private initialize(): void {

		// intercome object
		if ( !window[ 'Intercom' ] ) {
			window[ 'Intercom' ] = () => { };
		}

		this.isBooted = false;		

		this.loadDeps();
	}

	/**
	 * Create and attach script element for IntercomService client js
	 */
	private loadDeps(): void {

		(function(){var w=window, d=document, i = function(){ i['c'](arguments) }; i['q']=[]; i['c']=function(args){ i['q'].push(args) }; w['Intercom'] = i;})();

		const s = document.createElement( 'script' );
		s.async = true;
		s.src = `https://widget.intercom.io/widget/${this.settings.intercom_id}`;

		document.body.appendChild( s );
		
	}

	protected intercom( method, params?) {
		window[ 'Intercom' ]( method, params );
	}

	public update( params?): void {
		if ( !this.isBooted ) {
			return;
		}
		this.intercom( 'update', params );
	}

	public shutdown(): void {
		this.intercom( 'shutdown' );
	}

	public boot( user: User ): void {

		const time = new Date();

		// default to user name
		let userName = user.name;

		// if the name is equal to the email, and the first or last name does not contain an @
		if ( userName === user.email && ( user.first_name.indexOf( '@' ) === -1 || user.last_name.indexOf( '@' ) === -1 ) ) {

			// join first/last name when they don't contain an @
			const name = Array.from( [ ...new Set( [ user.first_name, user.last_name ] ) ] ).filter( n => n.indexOf( '@' ) === -1 ).join( ' ' );

			// if the resulting string has a length it contains a name we can use
			if ( name.length ) {
				userName = name;
			}
		}

		const metaData = {
			'app_id': this.settings.intercom_id,
			'user_id': user.id,
			'email': user.email,
			'name': userName,
			'Birthday': user.birthday,
			'Phone': user.mobileNumber ? user.mobileNumber : '',
			'Salesforce Link': user.accountId && this.settings.salesforce_url ? this.settings.salesforce_url + '/' + user.accountId : '',
			'Role': user.role_at_church
		};

		if ( user.alphaStats ) {
			this.mergeAlphaStats( user.alphaStats, metaData );
		}

		if ( user.organizationId ) {
			/**
			 * @todo Intercom tracking seems pretty specific to AB Canada implementation
			 */
			metaData[ 'company' ] = {
				'id': user.organizationId,
				'name': user.organization.name,
				'Salesforce Link': user.organizationId && this.settings.salesforce_url ? this.settings.salesforce_url + '/' + user.organizationId : '',
				'Street Address': user.organization.address,
				'City': user.organization.city,
				'Province': user.organization.locality,
				'Denomination': user.organization.denomination,
				'Phone Number': user.organization.phoneNumber,
				'Regional Top 50': user.organization.meta.regionalTop50,
				'Specialty Top 50': user.organization.meta.specialtyTop50,
				'created_at': time
			};
		}

		this.isBooted = true;
		this.intercom( 'boot', metaData );
	}

	/**
	 * Merge user's alphaStats object with labels to send to intercom
	 */
	private mergeAlphaStats( alphaStats, metaData ) {

		// fields to map from stats object to intercom fields
		const mappings = {
			numberActive: 'Number of Active Alphas',
			nextStartDate: 'Next Alpha Start Date',
			nextEndDate: 'Next Alpha End Date',
			recentStartDate: 'Recent Alpha Start Date',
			recentEndDate: 'Recent Alpha End Date'

		};

		for ( const prop in alphaStats ) {
			if ( !mappings[ prop ] ) {
				continue;
			}
			const keyName = mappings[ prop ];
			const keyValue = alphaStats[ prop ];

			// add the key => value pair to the intercom meta data
			metaData[ keyName ] = keyValue;
		}

	}

	public trackEvent( method, name, params?) {
		window[ 'Intercom' ]( method, name, params );
	}

}



export const intercomSettingsProviderFactory = ( appTheme: AppTheme ) => {
	const obj = appTheme.value( 'tracking/intercom' );
	return new IntercomSettings( obj );
};

export let intercomSettingsProvider = {
	provide: IntercomSettings,
	useFactory: intercomSettingsProviderFactory,
	deps: [ AppTheme ]

};
