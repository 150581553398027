import { Component, Input, ContentChild, TemplateRef, SimpleChanges, OnInit, OnChanges,Output, EventEmitter } from '@angular/core';

import { CardTypeTemplateDirective, IResultSet } from '../search-results.component';
import { PostsFilterUpdatedPipe } from '../search-results.pipes';
import { Resource } from '../../../../../../mu-plugins/alphabuilder/assets/ng/models/resource';

/**
 *
 */
@Component( {
	selector: 'search-results-set-updated',
	templateUrl: 'result-set-updated.component.html',
	styleUrls: ['./result-set-updated.component.less']
} )
export class SearchResultsSetUpdatedComponent implements OnInit, OnChanges {

	@ContentChild( CardTypeTemplateDirective, { static: true, read: TemplateRef } ) cardTypeTemplate: TemplateRef<any>;

	@Input() resultSet: Resource[];
	@Input() filters: any;

	@Output() updateCountEvent = new EventEmitter<{ current: number; total: number; }>();

	public loading: boolean;
	public filterParams: any = {};
	public remainingPosts: Resource[] = [];
	public visiblePosts: Resource[] = [];

	public pageSize: number = 10;
	public totalPosts: number = 0;

	constructor(
		private postsFilterUpdatedPipe: PostsFilterUpdatedPipe
	) {}

	public ngOnInit(): void {
		this.filterPosts(this.resultSet, this.filterParams);
	}

	private filterPosts(posts, filters) {
		const { filteredItems, count } = this.postsFilterUpdatedPipe.transform(posts, filters);
		this.remainingPosts = [...filteredItems];
		this.visiblePosts = [];
		this.totalPosts = count;
		this.loadPosts();
	}

	public loadPosts() {
		if (this.remainingPosts.length < 19) {
			this.visiblePosts.push(...this.remainingPosts.splice(0, this.remainingPosts.length))
		} else {
			this.visiblePosts.push(...this.remainingPosts.splice(0, 10))
		}
		setTimeout(() => {
			this.updateCountEvent.emit({
				current: this.visiblePosts.length,
				total: this.totalPosts
			})
        });
	}

	public ngOnChanges( changes: SimpleChanges ): void {

		if ( changes.resultSet ) {
			const newResultSet = changes.resultSet.currentValue;
			this.filterPosts(newResultSet, this.filterParams);
		}


		if ( changes.filters ) {
			const newFilters = changes.filters as any;
			this.filterParams = newFilters.currentValue;
			this.filterPosts(this.resultSet, this.filterParams);
		}
	}
}
