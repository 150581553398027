import { NgModule } from '@angular/core';
import { MixPanelComponent } from './mix-panel.component';

@NgModule( {
	declarations: [
		MixPanelComponent
	],
	exports: [
		MixPanelComponent
	]
} )
export class MixPanelModule { }
