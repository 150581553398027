import { NgModule } from '@angular/core';
import { GeotargetlyComponent } from './geotargetly.component';

@NgModule( {
	declarations: [
		GeotargetlyComponent
	],
	exports: [
		GeotargetlyComponent
	]
} )
export class GeotargetlyModule { }
