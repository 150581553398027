import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { Locale } from '@builder/common/lang/locale';

import { VideoService } from '@builder/common/media/video';
import { VideoData } from '@plugin/common/models/video-data';
import { TrackingService } from '@builder/tracking';
import { TrackingEventName } from "@builder/tracking/tracking-events";
interface IDownload {
	name: string;
	width: number;
	height: number;
	link: string;
	expires: string;
	fps: number;
	md5: string;
	size: number;
	type: string;
	quality: string;
	rendition: string
}

@Component( {
	selector: 'resource-download-options',
	templateUrl: './resource-download-options.component.html',
	styleUrls: [ './resource-download-options.component.less' ]
} )
export class ResourceDownloadOptionsComponent implements OnInit {

	@Input() video: VideoData;
	@Input() mediaTitle: string;

	// next event
	@Output() next: EventEmitter<any> = new EventEmitter();

	@Input() downloadLink: string;
	@Output() downloadLinkChange = new EventEmitter<string>();

	// close event
	@Output() closing: EventEmitter<any> = new EventEmitter();

	public selectedVideoOption: any = null;

	// current set of file download options
	public downloadOptions: any;

	// currently selected video download option
	public currentDownload: IDownload;

	// set if there's only 1 audio language available
	public audioLanguage: string;

	// only subtitle language
	public subtitleLanguage: string;

	// if we are requesting an expired download and it no longer exists
	public notFoundError = false;

	// are we loading download links?
	public loading = false;

	public videoOptions: any[];

	public showOptions: boolean;
	public showingCopy: boolean;

	constructor( 
		private locale:Locale, 
		private _videosService: VideoService, 
		private cd: ChangeDetectorRef, 
		private trackingService: TrackingService
		) { }

	ngOnInit(): void {

		// language object of the root video object
		const videoLanguage = this.locale.languages.find( lang => {
			return lang.code === this.video.language;
		} );

		let videoOptions: Array<any> = [];

		/**
		 * Standard audio treatment
		 */
		if ( videoLanguage ) {

			this.audioLanguage = this.locale.translate( `languageLabel_${ videoLanguage.code }`, videoLanguage.english_name );

			videoOptions = this.video.languages.

				filter( langVideo => {

					// must be in video language or have subtitles in video language
					return langVideo.language === videoLanguage.code || langVideo.subtitles.find( v => v.language === videoLanguage.code );
				} ).
				map( langVideo => {
					if ( langVideo.language !== videoLanguage.code ) {
						// find a subtitle in video language to use as result
						const foundSubtitle = langVideo.subtitles.find( v => v.language === videoLanguage.code );
						if ( foundSubtitle ) {
							return { language: langVideo.language, subtitles: [ foundSubtitle ], video: null };
						}
					} else {
						return { language: langVideo.language, subtitles: langVideo.subtitles, video: langVideo };
					}
					return null;
				} );

			this.selectedVideoOption = videoOptions.find( opt => opt.language === videoLanguage.code );

		}

		let optCount = 0;
		videoOptions.forEach( o => {
			if ( o.video ) {
				optCount++;
			}
			optCount += o.subtitles.length;
		} );
		this.showOptions = optCount > 1;

		this.videoOptions = videoOptions.sort( ( a, b ) => {
			return a.language === videoLanguage.code ? -1 : 1;
		} );

		if ( !this.selectedVideoOption ) {
			this.downloadOptions = this.video.meta.download;
			this.selectBestDownloadOption( this.video );
		} else {
			this.downloadOptions = this.selectedVideoOption.video.meta.download;
			this.selectBestDownloadOption( this.selectedVideoOption.video );
		}


	}

	public _getSubtitleLanguage( langOption ) {
		return this.locale.translate( 'writtenLanguageLabel_' + this._transformLanguageCode( langOption.language ), langOption.language );
	}

	public _getAudioLanguage( langOption ) {
		return this.locale.translate( 'languageLabel_' + this._transformLanguageCode( langOption.language ), langOption.language );
	}

	public _transformLanguageCode( code: string ): string {
		// TW and CN should be in uppercase
		return code.toLowerCase().replace( '_', '-' ).replace(/(cn|tw)/, match => match.toUpperCase());
	}

	/**
	 * Get the download link, if the link has expired we may need to refetch it
	 * We do not download the resource, instead we pass the download link to its parent component, and let it do the download logic
	 */
	public download(): void {

		const expired: boolean = new Date().getTime() > new Date( this.currentDownload.expires ).getTime();

		if ( expired ) {

			const selVideo = this.selectedVideoOption.video;

			this.loading = true;

			// fetch the renewed download links
			this._videosService.fetchDownloadLink( selVideo.ID ).subscribe( downloads => {

				this.loading = false;

				// set the links on the video object
				selVideo.setDownloads( downloads );

				this.downloadOptions = selVideo.meta.download;

				// reset the current download
				this.currentDownload = selVideo.meta.download.find( d => d.width === this.currentDownload.width && d.height === this.currentDownload.height && d.quality === this.currentDownload.quality );

				if ( !this.currentDownload ) {
					// the refreshed data doesn't include the file requested
					this.notFoundError = true;
				} else {
					this.trackingService.trigger( TrackingEventName.DownloadVideo, { resolution: this.currentDownload.rendition, episodeName: this.mediaTitle } );
					this.downloadLink = this.currentDownload.link;
					this.downloadLinkChange.emit( this.downloadLink );
					this.next.emit();
				}

				this.cd.detectChanges();
			} );
		} else {
			this.trackingService.trigger( TrackingEventName.DownloadVideo, { resolution: this.currentDownload.rendition, episodeName: this.mediaTitle } );
			this.downloadLink = this.currentDownload.link;
			this.downloadLinkChange.emit( this.downloadLink );
			this.next.emit();
		}


	}

	/**
	 * Select a subtitle video
	 */
	public changeVideoDownloadOption( videoOption ): void {

		this.selectedVideoOption = videoOption;
		this.notFoundError = false;

		this.downloadOptions = videoOption.video.meta.download;
		this.selectBestDownloadOption( videoOption.video );

	}

	/**
	 * Radio button select
	 */
	public selectDownload( downloadOption ): void {
		this.currentDownload = downloadOption;
		this.notFoundError = false;
	}

	/**
	 * Choose a default download option if available
	 */
	private selectBestDownloadOption( video ) {

		if ( video.meta.download.length === 1 ) {
			this.currentDownload = video.meta.download[ 0 ];
			return;
		}
		// find the best matching download from the subtitle video
		const exactMatch = this.currentDownload ? video.meta.download.find( d => d.width === this.currentDownload.width && d.height === this.currentDownload.height && d.quality === this.currentDownload.quality ) : null;
		if ( exactMatch ) {
			this.currentDownload = exactMatch;
		} else {
			this.currentDownload = null;
		}
	}

}
