import { Component, Input } from '@angular/core';
import { IPlayer } from '@builder/common/media/video/player/player.base';

@Component( {
	selector: 'ab-video-controls',
	template: `
	<div class='play-pause-toggle' (click)="player.paused ? player.play() : player.pause()">
		<ab-icon *ngIf='player.paused' name='play'></ab-icon>
		<ab-icon *ngIf='!player.paused' name='pause'></ab-icon>
	</div>
	<div class='nplayer-control-bar'>
		<div class='nplayer-progress-bar'>
			<div class='nplayer-time-info' [style.left]="playPct + '%'">
				<div class='nplayer-time-info-box'>
					<span class='nplayer-time-info-current'>{{ ( player.currentTime || 0 ) | playTime:{format:"timer"} }}</span>
					<span class='nplayer-time-info-divider'> / </span>
					<span class='nplayer-time-info-total'>{{ ( player.duration || 0 ) | playTime:{format:"timer"} }}</span>
				</div>
			</div>
			<div class='nplayer-progress-bar-track' (click)="onBarClick($event)">
				<div class='nplayer-progress-bar-fill' [style.width]="playPct + '%'">

				</div>
			</div>
		</div>
		<div class='nplayer-volume'>
			<ab-icon class="control-icon mute-toggle" [name]="muted ? 'unmute' : 'mute'" (click)="muteToggle()"></ab-icon>
			<ng-container *ngIf="volumeStepper">
				<div class='volume-step' (click)="setVolume(.15)" [class.fill]="player.volume > 0"></div>
				<div class='volume-step' (click)="setVolume(.40)" [class.fill]="player.volume > .2"></div>
				<div class='volume-step' (click)="setVolume(.60)" [class.fill]="player.volume > .4"></div>
				<div class='volume-step' (click)="setVolume(.80)" [class.fill]="player.volume > .6"></div>
				<div class='volume-step' (click)="setVolume(1)" [class.fill]="player.volume > .8"></div>
			</ng-container>
		</div>
		<ab-icon class="control-icon" name="fullscreen" *ngIf="allowFullScreen" (click)="player.requestFullScreen()"></ab-icon>
	</div>`,
	styleUrls: [ 'controls.less' ]
} )
export class ABVideoControlsComponent {

	@Input( 'player' ) player: IPlayer;

	@Input( 'volumeStepper' ) volumeStepper = false;

	@Input( 'allowFullScreen' ) allowFullScreen = false;

	protected mutedVolume: number;

	public muted = false;

	public get playPct(): number {
		return ( this.player.currentTime / this.player.duration ) * 100;
	}

	public muteToggle(): void {
		if ( this.player.volume > 0 ) {
			this.mutedVolume = this.player.volume;
			this.player.volume = 0;
		} else {
			this.player.volume = this.mutedVolume || .5;
		}
		this.muted = !this.muted;
	}

	public setVolume( vol: number ): void {
		this.player.volume = vol;
		this.muted = false;
	}

	public onBarClick( e: MouseEvent ): void {
		const el = e.target as HTMLElement;
		const pct = e.offsetX / el.clientWidth;
		const seekTo = this.player.duration * pct;
		this.player.seekTo( seekTo );
	}
}
