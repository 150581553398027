<card>
	<div class="close-button" *ngIf="isMobile">
		<ab-icon name="close" color="gray"></ab-icon>
	</div>
	<div class="container">
		<div class="icon">
			<img src="wp-content/themes/alpha/images/icons/recommended_icon.svg">
		</div>
		<div class="content">
			<h1 i18n="alpha.surveys|Recommended Content Header@@recommendedContentHeader">
				Recommended Content For {{friendlyName// i18n: (ph="name") }}
			</h1>
			<div class="banner_content">
				<span i18n="alpha.surveys|Curated Recommendations Message@@currentRecommendationsMessage">We've curated this list for you based on your latest survey results from &quot;{{survey.alpha.title// i18n: (ph="alphaName") }}&quot;</span>
			</div>
		</div>
		<div class="action">
			<a i18n="button.labels|View Recommendations Button@@buttonViewRecommendations"
			   mat-flat-button
			   color="primary"
			   [routerLink]="['/alphas', survey.alpha.id, 'survey', 'recommendations']">View Recommendations</a>
		</div>
	</div>
</card>
