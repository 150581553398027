import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { switchMap } from 'rxjs/operators';

import { FeaturesService } from '@builder/common/features/features.service';
import { CurrentUser } from '@builder/users';
import { UserService } from '../users/service';
import { SignonErrorComponent } from './signon-error.component';
import { AuthHttp } from '@builder/http';
import { UserPreferences } from '@builder/users/preferences';
import { FEATURE_CORA, FEATURE_SIMPLE_COURSE_CREATE } from '@builder/common/features/feature-flag';
import { SESSION_STORAGE_KEY_PRODUCT_SLUG } from '@builder/alphas/create/create-alpha.resolver';

/**
 * If the user is trying to visit signup page and they're logged in, direct to dashboard
 */
@Injectable()
export class CanActivateSignon implements CanActivate {
	public simpleCourseCreateEnabled: boolean = false;
	public coraEnabled: boolean = false;
	public useUpdatedSignInFlow: boolean = false;

	constructor(
		private router: Router,
		private currentUser: CurrentUser,
		private userService: UserService,
		private dialog: MatDialog,
		private features: FeaturesService,
		private authHttp: AuthHttp,
		private userPrefs: UserPreferences,
		private feature: FeaturesService
	) {
		this.simpleCourseCreateEnabled = this.feature.isOn( FEATURE_SIMPLE_COURSE_CREATE )		
		this.coraEnabled = this.feature.isOn( FEATURE_CORA )
		this.useUpdatedSignInFlow = this.simpleCourseCreateEnabled || this.coraEnabled
	}

	async canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		
		if ( this.currentUser.loggedIn() ) {
			if (this.useUpdatedSignInFlow && !this.currentUser.userHasActiveAlphas()) {
				return this.router.navigate( [ '/preview-resources' ] )
			}
			return this.router.navigate( [ '/dashboard'  ] );
		}

		if ( this.features.isOn( 'sso_enabled' ) ) {
			return this.loginRedirect( route );
		}

		return true;
	}

	private async loginRedirect( route: ActivatedRouteSnapshot ): Promise<boolean | UrlTree> {

		const rememberMe = false;

		// no ?code parameter, initial phase of login redirect
		if ( !route.queryParams.code ) {

			try {

				// this should redirect away from the site on success
				await this.userService.ssoLogin();

			} catch ( error ) {

				this.showError( { code: 'idp_error', message: $localize`:errors|IDP Connection Error@@errorIDPConnection:An error occured when connecting to the Authentication service. The issue has been logged and we are working to resolve this as quickly as possible.` } );
			}

			return false;
		}
		// code param present, finish the login process

		let user;

		// an invalid code would cause this to throw an error, catch it and redirect back to signon which should restart the login process
		try {
			user = await this.userService.ssoFinishLogin();
		} catch ( err ) {
			// will redirect back to login to try to fix things
			this.userService.logout().subscribe();
			return false;
		}

		// login in to myAlpha
		try {
			return await this.userService.externalLogin( user, rememberMe ).pipe(
				switchMap( result => {
					// after login, set the requested product, pass along url params from state to reg page
					if ( user.state?.productContext ) {
						sessionStorage.setItem( SESSION_STORAGE_KEY_PRODUCT_SLUG, user.state.productContext.product );
						return this.router.navigate( ['/alphas/new'], { state: { productContext : user.state.productContext } } );
					}

					if ( this.currentUser.loggedIn() ) {
						if (this.useUpdatedSignInFlow && !this.currentUser.userHasActiveAlphas()) {
							return this.router.navigate( [ '/preview-resources' ] )
						}
					}
					return this.router.navigate( [ '/dashboard'  ] );
				} )
			).toPromise();

		} catch ( error ) {
			// will redirect back to login to try to fix things
			this.userService.ssoSilentLogout().subscribe();
			this.showError( error.error ?? error );
		}

		return false;
	}

	private showError( error ) {
		// service is not available, show an error message
		this.dialog.open( SignonErrorComponent, {
			disableClose: true,
			maxWidth: 640,
			data: { error }
		} );
	}

}
