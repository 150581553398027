import { Component, Input, ChangeDetectorRef, ContentChild, TemplateRef, SimpleChanges, OnInit, OnChanges,Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { Resource } from '@plugin/common/models/resource';
import { ResourceModalDialog, ResourceService } from '@builder/resources';

import { SearchService } from '@builder/search/search.service';
import { CardTypeTemplateDirective, IResultSet } from '../search-results.component';

/**
 *
 */
@Component( {
	selector: 'search-results-set',
	templateUrl: 'result-set.component.html'
} )
export class SearchResultsSetComponent implements OnInit, OnChanges {

	@ContentChild( CardTypeTemplateDirective, { static: true, read: TemplateRef } ) cardTypeTemplate: TemplateRef<any>;

	@Input() resultSet: IResultSet;

	@Input() limit: number;

	@Input() filters: any;

	@Input() disableAction = false;

	public loading: boolean;

	public filterParams: any = {};
	public totalCount = 0;
	@Output() filteredCount : EventEmitter<any> = new EventEmitter();

	constructor(
		private service: SearchService,
		private cd: ChangeDetectorRef,
		private mediaModal: ResourceModalDialog,
		private router: Router,
		private resourceService: ResourceService
	) { }

	public ngOnInit(): void {
		if ( !this.limit ) {
			this.onScrollDown( null );
		}
	}

	public ngOnChanges( changes: SimpleChanges ): void {
		this.totalCount += this.resultSet.total;
		this.cd.detectChanges()
		this.filteredCount.emit(this.totalCount)
		//this.totalCount = this.resultSet.posts.length;
		if ( changes.limit ) {
			this.filterParams.limit = changes.limit.currentValue;
		}

		if ( changes.filters ) {
			Object.assign( this.filterParams, changes.filters.currentValue );
		}
		
	}

	ngAfterContentChecked() {

		this.cd.detectChanges();
	
	  }

	public onScrollDown( event ): void {

		if ( this.resultSet.posts.length === this.resultSet.total ) {
			return;
		}

		delete this.resultSet.query.page;
		this.resultSet.query.offset = this.resultSet.posts.length;
		this.resultSet.query.per_page = 20;

		this.loading = true;

		this.service.search( this.resultSet.query ).subscribe( result => {

			const rSet: any = Object.values( result )[ 0 ];
			this.resultSet.query = rSet.query;
			this.resultSet.total = rSet.total;
			this.resultSet.posts = this.resultSet.posts.concat( rSet.posts );

			this.loading = false;

			this.cd.markForCheck();
		} );
	}

	/**
	 * Clicked the image portion, show a preview if available
	 */
	public onClickResult( resource: Resource ): void {

		const link = this.getRouterLink( resource );
		if ( link ) {

			this.router.navigate( link );

		} else if ( resource.canPreview || resource.error ) {

			this.mediaModal.open( resource );
		}

	}

	/**
	 * Clicked to download
	 */
	public onClickDownload( resource: Resource, event: MouseEvent ): void {

		if ( resource.error ) {
			return;
		}

		event.stopPropagation();

		if ( resource.type === 'video' ) {

			this.mediaModal.open( resource, { skipPreview: true } );

		} else if ( resource.type === 'document' && resource.attachedDocument ) {

			this.resourceService.onDownload(resource)

		}
	}

	private getRouterLink( resource ): Array<string> {
		if ( resource.is_playlist ) {
			return [ '/training', resource.slug ];
		}
		if ( resource.is_lesson ) {
			return [ '/training', 'article', resource.slug ];
		}
		return null;
	}
}
