import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { forkJoin } from 'rxjs';
import { showError } from './app.error';

import { AppModule } from './app.module';
import { environment } from './environments/environment';
import { preloadTranslations } from './i18n-loader';

if ( environment.production ) {
	enableProdMode();
}


/**
 * The only way to reliably load data before DI Services are instantiated is to make an XHR request before bootstrapping the application.
 * We do that here in order to preload any data that's critical to bootstrapping. ( Translations )
 */
forkJoin( [
	preloadTranslations()
] ).subscribe( {
	next : providers => platformBrowserDynamic( providers ).bootstrapModule( AppModule ),
	error: error => showError( error )
} );
