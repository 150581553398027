import { NgModule } from '@angular/core';
import { GoogleAnalyticsComponent } from './ga.component';

@NgModule( {
	declarations: [
		GoogleAnalyticsComponent
	],
	exports: [
		GoogleAnalyticsComponent
	]
} )
export class GoogleAnalyticsModule { }
