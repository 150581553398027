import { Component, Input, ElementRef, ViewChild, SimpleChanges, OnChanges, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';

import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { ABVideo } from '../videoService';
import { VideoPlayerComponent } from '../player/videoPlayer';


@Component( {
	selector: 'video-playlist',
	templateUrl: 'videoPlaylist.html',
	styleUrls: [ 'videoPlaylist.less' ]
} )
export class VideoPlaylistComponent implements OnChanges, OnDestroy, AfterViewInit {

	@Input() watermark: boolean;
	@Input() disableFullScreen: boolean;

	private _currentVideo: ABVideo;
	public set currentVideo( val: ABVideo ) {
		if ( this._currentVideo ) {
			this._currentVideo.playing = false;
			this._currentVideo.paused = false;
		}
		this.progressData = null;
		this._currentVideo = val;
	}
	public get currentVideo(): ABVideo {
		return this._currentVideo;
	}
	public progressData: { seconds: number } = { seconds: 0 };

	public playedOne = false;
	public errorMessage: string = null;

	@Input() videos: Array<ABVideo>;

	@ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent;
	@ViewChild('listElement') listElement: ElementRef;

	private subscription: Subscription;
	private sizer: Subscription;

	static get events(): Observable<any> { return VideoPlaylistComponent._events; }
	private static _events: Subject<any> = new Subject();
	public static triggerEvent( e ) { VideoPlaylistComponent._events.next( e ); }

	@Output() changeVideo: EventEmitter<any> = new EventEmitter();

	constructor(
		public elementRef: ElementRef
	) {
		this.progressData = { seconds: 0 };

		// ** listen to all video playlists for play events to stop other playing players */
		this.subscription = VideoPlaylistComponent.events.subscribe( e => {
			if ( e.player !== this ) {
				this.pause();
			}
		} );
	}

	ngOnDestroy(): void {

		this.subscription.unsubscribe();
		this.sizer.unsubscribe();
	}

	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes.videos ) {
			this.loadVideo( changes.videos.currentValue[ 0 ] );
		}
	}

	ngAfterViewInit(): void {

		this.sizer = fromEvent( window, 'resize' ).pipe(
			throttleTime( 100 )
		).subscribe( e => this.sizeListElement() );

		this.sizeListElement();
	}

	public sizeListElement(): void {
		const h = this.videoPlayer.$element.clientHeight;
		this.listElement.nativeElement.style.height = h + 'px';
	}

	public pause(): void {
		if ( this._currentVideo ) {
			// this._currentVideo.paused = true;
			// this._currentVideo.playing = false;
			this.videoPlayer.pause();
		}
	}

	videoPlaying( videoPlayer: VideoPlayerComponent ) {

		this.currentVideo.playing = true;
		this.currentVideo.paused = false;
		VideoPlaylistComponent.triggerEvent( { action: 'play', player: this } );

	}
	videoPaused( videoPlayer: VideoPlayerComponent ) {

		this.currentVideo.playing = false;
		this.currentVideo.paused = true;
	}
	videoProgress( data ) {
		this.progressData = data.progress;
		VideoPlaylistComponent.triggerEvent( { action: 'progress', player: this, video: this.currentVideo, progress: data.progress } );
	}

	videoFinished( data ) {
		VideoPlaylistComponent.triggerEvent( { action: 'finish', player: this, video: this.currentVideo } );
	}

	getVideo( src ) {
		const match = this.videos.find( v => v.url === src );
		return match;
	}

	loadVideo( video: ABVideo ): void {
		if ( this.currentVideo === video ) {
			return;
		}
		this.changeVideo.emit( video );
		this.currentVideo = video;
	}

	/**
     *
     */
	playVideo( video: ABVideo ) {

		if ( this.currentVideo === video ) {
			video.playing = !video.playing;
			if ( video.playing ) {
				this.videoPlayer.play();
			} else {
				this.videoPlayer.pause();

			}
		} else {

			this.progressData = null;
			if ( this.currentVideo ) {
				this.currentVideo.playing = false;
				this.currentVideo.paused = false;
				this.videoPlayer.pause();
			}
			this.playedOne = true;
			video.playing = true;
			this.currentVideo = video;

		}

	}

}
